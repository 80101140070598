<template>
  <div class='pt-4' style='word-break: keep-all;'>
    <div class='flex flex-col lg:flex-row lg:justify-between gap-y-4 lg:gap-y-0 gap-x-0 lg:gap-x-8 mb-6 lg:mb-8'>
      <div class='w-full flex flex-col justify-start gap-y-6'>
        <div>
          <div class='flex flex-row justify-start items-center gap-x-4'>
            <h1 class='text-xl lg:text-3xl font-bold' v-html='highlightedText(session.title)'></h1>
            <div v-if='showHeartButton && hasValidToken'
              class='rounded-md border border-transparent h-6 w-6 flex flex-row items-center hover:bg-gray-200 hover:border-gray-500'
              @click.stop='toggleHearted'>
              <component :is='whichHeartIcon' class='h-4'/>
            </div>
          </div>
          <div class='flex flex-col lg:flex-row justify-start items-start lg:items-end gap-y-2 lg:gap-x-2'>
            <h2 v-if='session.subtitle'
              class='font-medium lg:text-xl text-gray-800'
              v-html='highlightedText(session.subtitle)'></h2>
            <div v-if='session.description && !isGbccEvent'
              class='uppercase text-gray-500 mb-2 lg:mb-0 cursor-pointer hover:bg-gray-100 py-0.5 pl-1 pr-2 flex flex-row justify-start items-center border rounded'
              @click='toggleShowDetails'>
              <component :is='whichDetailsCarot' class='h-4 w-4 inline-block' />
              <span class='text-sm font-medium'>
                {{ toggleDetailsText }}
              </span>
            </div>
          </div>
          <h3 v-if='session.description && (showDetails || isGbccEvent)'
            class='whitespace-pre-line text-base'>
            {{ session.description }}
          </h3>
        </div>
        <div class='flex flex-col lg:flex-row gap-x-12'>
          <div class='w-full lg:w-52 flex-shrink-0'>
            <div class='flex flex-row items-center gap-x-2 lg:block'>
              <div class='text-base lg:text-lg font-semibold uppercase'>
                {{ sessionScheduledStartDate }}
              </div>
              <div class='text-base'>
                {{ sessionScheduledStartTime }} - {{ sessionScheduledEndTime }}
              </div>
            </div>
            <div class='text-base uppercase mb-2'>
              {{ sessionLocationString }}
            </div>
          </div>
          <session-details-organizers
            v-for='(organizersByRole, role) in organizersGroupedByRole'
            :key='`${session.id}-organizer-role-${role}`'
            :organizers-by-role='organizersByRole'
            :role='role'
            :session-id='session.id' />
        </div>
      </div>
    </div>
    <div class='flex flex-row justify-start items-center gap-x-2 mb-2'>
      <button v-if='showTopicDetailsToggleButton'
        class='rounded-md py-2 px-8 uppercase font-semibold text-sm w-auto border whitespace-nowrap'
        @click='toggleShowingTopics'>
        {{showTopicsButtonText}}
      </button>
      <button v-if='showGotoQnaButton && hasValidToken'
        class='rounded-md py-2 px-12 uppercase text-white font-semibold text-sm w-full lg:w-auto'
        :style='eventMainThemeColorBg'
        @click='goToQnA'>
        go to q&a
      </button>
    </div>
    <div class='border-l border-r border-b' :class='sessionTopicDetailsStyle'>
      <session-details-by-topic 
        v-for='(subSession, index) in sessionSpeakersByTopic'
        :key='`subSession-${index}`'
        class='border-t'
        :subSession='subSession'
        :highlight-string='highlightString' />
    </div>
  </div>
</template>

<script>
import { HeartIcon as HeartOutline } from '@vue-hero-icons/outline'
import { HeartIcon as HeartSolid } from '@vue-hero-icons/solid'
import { CaretDownIcon, CaretRightIcon } from 'vue-tabler-icons'
import { mapState, mapGetters, mapActions } from 'vuex'
import SessionDetailsOrganizers from '@/components/sessions/SessionDetailsOrganizers.vue'
import SessionDetailsByTopic    from '@/components/sessions/SessionDetailsByTopic.vue'
import DateTimeStringHelpers    from '@/utils/date-time-string-helpers'
import StringHelpers            from '@/utils/string-helpers'
import groupBy                  from 'lodash/groupBy' 

export default {
  name: 'SessionDetailsFull',
  components: {
    SessionDetailsOrganizers,
    SessionDetailsByTopic,
    HeartOutline,
    HeartSolid,
    CaretDownIcon,
    CaretRightIcon,
  },
  props: {
    'session': {
      type: Object,
      required: true,
    },
    highlightString: {
      type: String,
      default: '',
    },
    repeatedComponent: {
      type: Boolean,
      default: false,
    },
    showTopicDetailsToggleButton: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      showTopics: !this.showTopicDetailsToggleButton,
      showDetails: false,
    }
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventProgramsScheduleConfigurations',
      'eventMainThemeColorBg',
      'dateLocaleString',
      'showingEventId',
    ]),
    isGbccEvent () {
      return this.showingEventId === 39
    },
    sessionScheduledStartDate () {
      return DateTimeStringHelpers.toDateWithDay(this.session.scheduledStartTime, this.dateLocaleString)
    },
    sessionScheduledStartTime () {
      return DateTimeStringHelpers.toTime(this.session.scheduledStartTime)
    },
    sessionScheduledEndTime () {
      return DateTimeStringHelpers.toTime(this.session.scheduledEndTime)
    },
    sessionLocationString () {
      return this.session.locations.map(location => location.split('||')[0]).join(', ')
    },
    sessionSpeakersByTopic () {
      return this.showTopics ? this.session.listOfSpeakers : []
    },
    sessionTopicDetailsStyle () {
      return this.repeatedComponent ? 'border-b' : 'border-b'
    },
    showTopicsButtonText () {
      return this.showTopics ? 'Hide Topics' : 'Show Topics'
    },
    organizersGroupedByRole () {
      return (this.hasSessionConfigurationsOrganizers) ? groupBy(this.session.configurations.organizers, 'role') : []
    },
    hasSessionConfigurationsOrganizers () {
      return this.session.configurations.organizers && this.session.configurations.organizers.length > 0
    },
    showHeartButton () {
      return this.eventProgramsScheduleConfigurations.showHeartButton || false
    },
    showGotoQnaButton () {
      return this.session.configurations && this.session.configurations.showSessionDetailsQnaButton
    },
    heartedStatusIconStyle () {
      return (this.session.me && this.session.me.myLikeStatus) ? `color: ${this.sessionSlotBorderColor}` : 'color: #AAA'
    },
    whichHeartIcon () {
      return (this.session.me && this.session.me.myLikeStatus) ? 'heart-solid' : 'heart-outline'
    },
    whichDetailsCarot () {
      return (this.showDetails) ? 'caret-down-icon' : 'caret-right-icon'
    },
    toggleDetailsText () {
      return (this.showDetails) ? 'hide details' : 'show details'
    },
  },
  methods: {
    ...mapActions('sessions', [
      'postLikeSession',
    ]),
    toggleShowingTopics () {
      this.showTopics = !this.showTopics
    },
    goToQnA () {
      this.$modal.hide('details-modal')
      this.$router.push({ name: 'Qna', query: { session_id: this.session.id }})
    },
    toggleHearted () {
      this.postLikeSession(this.session.id).then(resp => {
        if (resp.likedStatus == 'active') { // just turn on locally. Will get right data from api
          this.session.me.myLikeStatus = true
        } else {
          this.session.me.myLikeStatus = false
        }
      })
    },
    toggleShowDetails () {
      this.showDetails = !this.showDetails
    },
    highlightedText (text) {
      if (this.highlightString) {
        return StringHelpers.highlightString(text, this.highlightString)
      } else {
        return text
      }
    },
  }
}
</script>

