import Vue                       from 'vue'
import Vuex                      from 'vuex'
import AuthorizationsApi         from '@/api/v1/users/authorizations'
import RegistrationApi           from '@/api/v1/users/registrations'
import ProfileApi                from '@/api/v1/users/profiles'
import MembershipApi             from '@/api/v1/users/memberships'
import UserDataHelpers           from '@/utils/user-data-helpers.js'
import { getField, updateField } from 'vuex-map-fields'
import cloneDeep                 from 'lodash/cloneDeep'

Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

const state = () => ({
  hasValidToken: false,
  isAlreadyPaid: false,
  roles: [],
  signupParams:     UserDataHelpers.blankSignupParams(),
  profile:          UserDataHelpers.blankProfile(),
  membershipData:   UserDataHelpers.blankMembershipData(),
  registrationData: UserDataHelpers.blankRegistrationData(),
  terms: [],
  registeringAttendees: [],
  registeredAttendees: [],
  editingAttendee: null,
  icolaWelcomeReceptionAttendeesCount: 0,
})

const getters = {
  isAdmin: state => {
    //gbcc_admin, kai_admin ...
    return state.roles.length > 0 && state.roles.find((role) => role.includes('admin'))
  },
  isConferenceSpeaker: state => {
    return state.roles.length > 0 && state.roles.find((role) => role.includes('speaker'))
  },
  icolaWelcomeReceptionAtCapacity: state => {
    return state.icolaWelcomeReceptionAttendeesCount >= 150
  },
  getField
}

const actions = {
  login ({commit, rootGetters, dispatch}, userParams) {
    return new Promise((resolve, reject) => {
      const loginParams = {
        event_id: rootGetters['events/showingEventId'],
        provider: 'event',
        user_params: {
          email: userParams.email,
          password: userParams.password
        }
      }

      if (userParams.singleSession) {
        loginParams['single_session'] = true
      }

      AuthorizationsApi.login(loginParams).then(resp => {
        localStorage.setItem('auth._token.webconcert', resp.data.access_token)
        localStorage.setItem('user_code', resp.data.user_id)
        localStorage.setItem('user_role', resp.data.user_role)
        commit('setHasValidToken', true)
        dispatch('setupRolesFromLocalStorage')
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  mobileAppLogin ({ commit, dispatch }, mobileAppLoginParams) {
    return new Promise((resolve) => {
      localStorage.setItem('auth._token.webconcert', mobileAppLoginParams.access_token)
      localStorage.setItem('user_code', mobileAppLoginParams.user_id)
      localStorage.setItem('user_role', mobileAppLoginParams.user_role)
      commit('setHasValidToken', true)
      dispatch('setupRolesFromLocalStorage')
      resolve()
    })
  },
  checkBlacklist ({rootGetters}, userParams) {
    return new Promise((resolve, reject) => {
      const loginParams = {
        event_id: rootGetters['events/showingEventId'],
        provider: 'event',
        user_params: {
          email: userParams.email,
          password: userParams.password
        }
      }
      AuthorizationsApi.checkBlacklist(loginParams).then(resp => {
        resolve(resp)
      }).catch((error) => reject(error))
    })
  },
  logout ({ dispatch }) {
    return new Promise((resolve) => {
      AuthorizationsApi.logout().then(() => {
        dispatch('clearLocalStorage')
      })
      resolve()
    })
  },
  setupRolesFromLocalStorage ({ commit }) {
    let localStorageRoles = localStorage.getItem('user_role')
    let roles = (localStorageRoles) ? localStorageRoles.split(',') : []
    commit('setRoles', roles)
  },
  checkTokenStatus ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      AuthorizationsApi.checkTokenStatus().then(resp => {
        if (resp.is_token_valid) {
          commit('setHasValidToken', true)
        } else {
          dispatch('clearLocalStorage')
        }
        resolve(resp)
      }).catch(error => {
        if (error.status === 401) {
          dispatch('clearLocalStorage')
          resolve()
        } else {
          console.error(error)
          reject(error)
        }
      })
    })
  },
  getKsolaLogin ({ rootGetters }, ksolaLoginParams) {
    return new Promise((resolve, reject) => {
      ksolaLoginParams['eventId'] = rootGetters['events/showingEventId']
      AuthorizationsApi.getKsolaLogin(snakecaseKeys(ksolaLoginParams, { deep: true })).then(resp => {
        resolve(camelcaseKeys(resp, {deep: true}))
      }).catch(error => {
        reject(error)
        console.error(error)
      })
    })
  },
  getEventLoginId ({ rootGetters }, getLoginIdParams ) {
    return new Promise(resolve => {
      getLoginIdParams['eventId'] = rootGetters['events/showingEventId']
      AuthorizationsApi.getEventLoginId(snakecaseKeys(getLoginIdParams, { deep: true })).then(resp => {
        resolve(camelcaseKeys(resp.users, {deep: true}))
      }).catch(error => {
        console.error(error)
      })
    })
  },
  updateEventLoginPassword ({ rootGetters }, updateLoginPasswordParams ) {
    return new Promise((resolve, reject) => {
      updateLoginPasswordParams['eventId'] = rootGetters['events/showingEventId']
      AuthorizationsApi.patchEventLoginPassword(snakecaseKeys(updateLoginPasswordParams, { deep: true })).then(resp => {
        resolve(camelcaseKeys(resp.user, {deep: true}))
      }).catch(error => {
        reject(error)
        console.error(error)
      })
    })
  },
  clearLocalStorage ({ commit }) {
    localStorage.removeItem('auth._token.webconcert')
    localStorage.removeItem('user_code')
    localStorage.removeItem('user_role')
    commit('setHasValidToken', false)
    commit('setIsAlreadyPaid', false)
  },
  signup ({commit, dispatch, rootGetters, state}) {
    let signupParams = {
      provider: 'event',
      event_id: rootGetters['events/showingEventId'],
      user_params: {
        email: state.signupParams.email,
        password: state.signupParams.password,
      },
    }
    return new Promise((resolve, reject) => {
      RegistrationApi.signup(signupParams).then(resp => {
        localStorage.setItem('auth._token.webconcert', resp.access_token)
        localStorage.setItem('user_code', resp.user_id)
        localStorage.setItem('user_role', resp.user_role)
        commit('setHasValidToken', true)
        dispatch('setupRolesFromLocalStorage')
        resolve(resp)
      }).catch((error) => {
        // user may exist. TODO: handle this case
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.data.error && error.response.data.message.includes('User already exist')) {
            // show some kind of message
          }
        }
        reject(error)
      })
    })
  },
  checkDuplicatedPhoneNumber ({ rootGetters }, phoneNumber) {
    return new Promise((resolve, reject) => {
      let params = {
        eventId: rootGetters['events/showingEventId'],
        phoneNumber: phoneNumber
      }
      AuthorizationsApi.checkDuplicatedPhoneNumber(snakecaseKeys(params, {deep: true})).then(resp => {
        resolve(resp.data)
      }).catch(error => reject(error))
    })
  },
  createProfile ({ state, commit }) {
    let profileParams = snakecaseKeys(cloneDeep(state.profile), {deep: true})
    return new Promise((resolve, reject) => {
      ProfileApi.postProfile(profileParams).then(resp => {
        commit('setProfile', camelcaseKeys(resp, {deep: true}))
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateMembershipData ({ state, rootGetters }) {
    let membershipParams = {
      membership_data: snakecaseKeys(cloneDeep(state.membershipData), { deep: true }),
      event_id:        rootGetters['events/showingEventId'],
    }
    return new Promise((resolve, reject) => {
      MembershipApi.updateMembership(membershipParams).then(resp => {
        resolve(resp)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getProfile ({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      ProfileApi.getProfile({'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setProfile', camelcaseKeys(resp, {deep: true}))
        resolve(camelcaseKeys(resp, {deep: true}))
      }).catch(error => {
        reject(error)
      })
    })
  },
  patchProfile ({ commit, rootGetters }, profileParams) {
    return new Promise((resolve, reject) => {
      ProfileApi.patchProfile({'profile': snakecaseKeys(cloneDeep(profileParams), {deep: true}),
                               'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setProfile', camelcaseKeys(resp, {deep: true}))
        resolve(camelcaseKeys(resp, {deep: true}))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getMembershipData ({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      ProfileApi.getMembershipData({'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setMembershipData', camelcaseKeys(resp.membership_data, {deep: true}))
        resolve(camelcaseKeys(resp.membership_data, {deep: true}))
      }).catch(error => {
        reject(error)
      })
    })
  },
  patchMembershipData ({ commit, rootGetters }, membershipDataParams) {
    return new Promise((resolve, reject) => {
      ProfileApi.patchMembershipData({'membership_data': snakecaseKeys(cloneDeep(membershipDataParams), {deep: true}),
                                      'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setMembershipData', camelcaseKeys(resp.membership_data, {deep: true}))
        resolve(camelcaseKeys(resp.membership_data, {deep: true}))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getRegistrationData ({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      ProfileApi.getRegistrationData({'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setRegistrationData', camelcaseKeys(resp.registration_data, {deep: true}))
        resolve(camelcaseKeys(resp.registration_data, {deep: true}))
      }).catch(error => {
        reject(error)
      })
    })
  },
  patchRegistrationData ({ commit, rootGetters }, registrationDataParams) {
    return new Promise((resolve, reject) => {
      ProfileApi.patchRegistrationData({'registration_data': snakecaseKeys(cloneDeep(registrationDataParams), {deep: true}),
                                        'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setRegistrationData', camelcaseKeys(resp.registration_data, {deep: true}))
        resolve(camelcaseKeys(resp.registration_data, {deep: true}))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getTerms ({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      ProfileApi.getTerms({'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setTerms', camelcaseKeys(resp.terms, {deep: true}))
        resolve(camelcaseKeys(resp.terms, {deep: true}))
      }).catch(error => {
        reject(error)
      })
    })
  },
  patchTerms ({ state, commit, rootGetters }) {
    let termsParams = snakecaseKeys(cloneDeep(state.terms), {deep: true})
    return new Promise((resolve, reject) => {
      ProfileApi.patchTerms({'terms': termsParams, 'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setTerms', camelcaseKeys(resp.terms, {deep: true}))
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateProfileAndSsoData ({ state, rootGetters, commit }) {
    let profileParams = snakecaseKeys(cloneDeep(state.profile), {deep: true})
    let membershipDataParams = snakecaseKeys(cloneDeep(state.membershipData), {deep: true})
    let registrationDataParams = snakecaseKeys(cloneDeep(state.registrationData), {deep: true})
    let termsParams = snakecaseKeys(cloneDeep(state.terms), {deep: true})

    let patchProfile = new Promise((resolve, reject) => {
      ProfileApi.patchProfile({'profile': profileParams, 'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setProfile', camelcaseKeys(resp, {deep: true}))
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
    
    let patchMembershipData =  new Promise((resolve, reject) => {
      ProfileApi.patchMembershipData({'membership_data': membershipDataParams, 'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setMembershipData', camelcaseKeys(resp.membership_data, {deep: true}))
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })

    let patchRegistrationData =  new Promise((resolve, reject) => {
      ProfileApi.patchRegistrationData({'registration_data': registrationDataParams, 'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setRegistrationData', camelcaseKeys(resp.registration_data, {deep: true}))
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })

    let patchTerms =  new Promise((resolve, reject) => {
      ProfileApi.patchTerms({'terms': termsParams, 'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setTerms', camelcaseKeys(resp.terms, {deep: true}))
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })

    return Promise.all([patchProfile, patchMembershipData, patchRegistrationData, patchTerms]).then(results => {console.log('results: ', results)})
  },
  getRegisteredByMe ({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      RegistrationApi.getRegisteredByMe({'event_id': rootGetters['events/showingEventId']}).then(resp => {
        commit('setRegisteredAttendees', camelcaseKeys(resp.registerd_by, {deep: true}).filter(attendee => attendee.registrationData.payment))
        commit('setRegisteringAttendees', camelcaseKeys(resp.registerd_by, {deep: true}).filter(attendee => !attendee.registrationData.payment))
        resolve(resp)
      }).catch(error => {
        reject(error)
      })
    })
  },
  addRegisteringAttendee ({ commit }) {
    commit('addAttendee', UserDataHelpers.newAttendee(9))
  },
  editAttendee ({ commit }, attendee) {
    commit('setEditingAttendee', attendee)
  },
  resetEditingAttendee ({ commit }) {
    commit('setEditingAttendee', null)
  },
  registerAttendee ({ state, rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      RegistrationApi.registerAttendee(rootGetters['events/showingEventId'], snakecaseKeys(state.editingAttendee, { deep: true})).then(resp => {
        commit('updateAttendeeUserId', camelcaseKeys(resp.registered_attendee, {deep: true}))
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  registerMyself ({ commit, dispatch, rootGetters, }, registrationParams) {
    return new Promise((resolve, reject) => {
      RegistrationApi.registerMyself(rootGetters['events/showingEventId'], snakecaseKeys(registrationParams, { deep: true})).then(resp => {
        localStorage.setItem('auth._token.webconcert', resp.access_token)
        localStorage.setItem('user_code', resp.user_id)
        localStorage.setItem('user_role', resp.user_role)
        commit('setHasValidToken', true)
        dispatch('setupRolesFromLocalStorage')
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  registrationDataBatchUpdate ({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.registrationDataBatchUpdate(rootGetters['events/showingEventId'], snakecaseKeys(params, { deep: true})).then(resp => {
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  registrationDataReset ({ commit,rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.registrationDataReset(rootGetters['events/showingEventId'], snakecaseKeys(params, { deep: true })).then(resp => {
        commit('removeAttendee', params.ssoIdentityId)
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getIsAlreadyRegistered ({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.getIsAlreadyRegistered(rootGetters['events/showingEventId'], snakecaseKeys(params, { deep: true })).then(resp => {
        let isAlreadyRegistered = camelcaseKeys(resp, {deep: true}).isAlreadyRegistered
        resolve(isAlreadyRegistered)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getAgeType (_, params) {
    return new Promise((resolve, reject) => {
      ProfileApi.getAgeType(snakecaseKeys(params, { deep: true })).then(resp => {
        let ageType = camelcaseKeys(resp, {deep: true}).ageType
        resolve(ageType)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getPaidRegistrationDetails ({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.getPaidRegistrationDetails(rootGetters['events/showingEventId'], snakecaseKeys(params, { deep: true })).then(resp => {
        let details = camelcaseKeys(resp, {deep: true}).details
        resolve(details)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getPaidRegistrationDetailsGroup ({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.getPaidRegistrationDetailsGroup(rootGetters['events/showingEventId'], snakecaseKeys(params, { deep: true })).then(resp => {
        let details = camelcaseKeys(resp, {deep: true})
        resolve(details)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getPaidRegistrationDetailsForMe ({ rootGetters }) {
    return new Promise((resolve, reject) => {
      RegistrationApi.getPaidRegistrationDetailsForMe(rootGetters['events/showingEventId']).then(resp => {
        let details = camelcaseKeys(resp, {deep: true}).details
        resolve(details)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getRegisteredByLicenseAndName ({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.getRegisteredByLicenseAndName(rootGetters['events/showingEventId'], snakecaseKeys(params, { deep: true })).then(resp => {
        let details = camelcaseKeys(resp, {deep: true})
        resolve(details)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getRegisteringAttendeesByOrderId ({ rootGetters, commit }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.getRegisteringAttendeesByOrderId(rootGetters['events/showingEventId'], snakecaseKeys(params, { deep: true })).then(resp => {
        commit('setRegisteringAttendees', camelcaseKeys(resp.registering_attendees, {deep: true}))
        resolve(camelcaseKeys(resp.registering_attendees, {deep: true}))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getMyPaidStatus ({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      let getMyPaidStatusParams = {
        eventId: rootGetters['events/showingEventId']
      }
      AuthorizationsApi.getMyPaidStatus(snakecaseKeys(getMyPaidStatusParams, { deep: true })).then(resp => {
        commit('setIsAlreadyPaid', camelcaseKeys(resp, {deep: true}).paidStatus)
        resolve(camelcaseKeys(resp, {deep: true}))
      }).catch(error => {
        reject(error)
        console.error(error)
      })
    })
  },
  getIcolaWelcomeReceptionAttendeesCount ({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      RegistrationApi.getIcolaWelcomeReceptionAttendeesCount(rootGetters['events/showingEventId']).then(resp => {
        commit('setIcolaWelcomeReceptionAttendeesCount', camelcaseKeys(resp, {deep: true}).count)
        resolve(camelcaseKeys(resp, {deep: true}))
      }).catch(error => {
        reject(error)
        console.error(error)
      })
    })
  },
  registerIcolaWeclomeReceptionByUserEmail ({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.registerIcolaWeclomeReceptionByUserEmail(rootGetters['events/showingEventId'], snakecaseKeys(params, {deep: true})).then(resp => {
        let camelcasedResp = camelcaseKeys(resp.order, { deep: true })
        resolve(camelcasedResp)
      }).catch(error => {
        reject(error)
      })
    })
  },
  patchRegistrationDataByUserEmail ({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.patchRegistrationDataByUserEmail(rootGetters['events/showingEventId'], snakecaseKeys(params, {deep: true})).then(resp => {
        resolve(camelcaseKeys(resp.registration_data, {deep: true}))
      }).catch(error => {
        reject(error)
      })
    })
  },
  getRegistrationDataByUserEmail ({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.getRegistrationDataByUserEmail(rootGetters['events/showingEventId'], params).then(resp => {
        resolve(camelcaseKeys(resp.registration_data, {deep: true}))
      }).catch(error => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setRoles (state, roles) {
    state.roles = roles
  },
  setHasValidToken (state, boolean) {
    state.hasValidToken = boolean
  },
  setIsAlreadyPaid (state, boolean) {
    state.isAlreadyPaid = boolean
  },
  setProfile (state, profile) {
    state.profile = profile
  },
  setMembershipData (state, membershipData) {
    Object.keys(membershipData).forEach(key => {
      state.membershipData[key] = membershipData[key]
    })
  },
  setEditingAttendee (state, attendee) {
    state.editingAttendee = attendee
  },
  addAttendee (state, attendee) {
    state.registeringAttendees.push(attendee)
  },
  updateAttendeeUserId (state, attendee) {
    let index = state.registeringAttendees.findIndex(registeringAttendee => registeringAttendee.email === attendee.email)
    if (index !== -1) {
      state.registeringAttendees[index].userId = attendee.id
    }
  },
  removeAttendeeAtIndex (state, index) {
    state.registeringAttendees.splice(index, 1)
  },
  removeAttendee (state, ssoIdentityId) {
    let index = state.registeringAttendees.findIndex(attendee => parseInt(attendee.ssoIdentityId) === ssoIdentityId)
    if (index !== -1) {
      state.registeringAttendees.splice(index, 1)
    }
  },
  setRegistrationData (state, registrationData) {
    Object.keys(registrationData).forEach(key => {
      state.registrationData[key] = registrationData[key]
    })
  },
  setRegisteredAttendees (state, registeredAttendees) {
    state.registeredAttendees = registeredAttendees
  },
  setRegisteringAttendees (state, registeringAttendees) {
    state.registeringAttendees = registeringAttendees
  },
  resetRegisteringAttendees (state) {
    state.registeringAttendees = []
  },
  setTerms (state, terms) {
    state.terms = terms
  },
  resetUserData (state) {
    state.profile          = UserDataHelpers.blankProfile()
    state.membershipData   = UserDataHelpers.blankMembershipData()
    state.registrationData = UserDataHelpers.blankRegistrationData()
    state.signupParams     = UserDataHelpers.blankSignupParams()
    state.terms            = []
  },
  setIcolaWelcomeReceptionAttendeesCount (state, count) {
    state.icolaWelcomeReceptionAttendeesCount = count
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
