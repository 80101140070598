import Vue                               from 'vue'
import VueRouter                         from 'vue-router'

const SingleComponentPage             = () => import('@/views/demo/SingleComponentPage.vue')

const Home                             = () => import('@/views/demo/Home.vue')
const Login                            = () => import('@/views/demo/Login.vue')
const MyPage                           = () => import('@/views/demo/MyPage.vue')
const Notices                          = () => import('@/views/demo/Notices.vue')
const ProgramDetails                   = () => import('@/views/demo/ProgramDetails.vue')
const Session                          = () => import('@/views/demo/Session.vue')
const Welcome                          = () => import('@/views/demo/Welcome.vue')
const CertificateDownload              = () => import('@/views/demo/CertificateDownload.vue')

const Programs                         = () => import('@/views/cmccme2023/Programs.vue')
const MyScore                          = () => import('@/views/cmccme2023/MyScore.vue')
const RegistrationGuideline            = () => import('@/views/cmccme2023/RegistrationGuideline.vue')
const ReceiptDownloadFile              = () => import('@/components/cmccme2023/ReceiptDownloadFile.vue')

const Speakers                         = () => import('@/views/koa2022/Speakers.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Programs'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    props: {
      showSponsorCarousel: true,
      showConferenceBanner: true,
      conferenceBannerHeight: 274,
    }
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      defaultToToday: true,
      needToBeLoggedIn: false,
      showSponsorCarousel: true,
      showConferenceBanner: true,
    }
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: ProgramDetails,
    props: {
      needToBeLoggedIn: false,
      showSponsorCarousel: true,
      showConferenceBanner: true,
    }
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers,
    showSponsorCarousel: true,
    showConferenceBanner: true,
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices,
    props: {
      showSponsorCarousel: true,
      showConferenceBanner: true,
    }
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage,
    props: {
      showSponsorCarousel: true,
      showConferenceBanner: true,
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: SingleComponentPage,
  },
  {
    path: '/registration-guideline',
    name: 'RegistrationGuideline',
    component: RegistrationGuideline,
    props: {
      showSponsorCarousel: true,
      showConferenceBanner: true,
    }
  },
  {
    path: '/receipt-download-file',
    name: 'ReceiptDownloadFile',
    component: ReceiptDownloadFile
  },
  {
    path: '/certificate-download',
    name: 'CertificateDownload',
    component: CertificateDownload,
  },
  {
    path: '/my-score',
    name: 'MyScore',
    component: MyScore,
    props: {
      showSponsorCarousel: true,
      showConferenceBanner: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
