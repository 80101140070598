import Vue                          from 'vue'
import VueRouter                    from 'vue-router'

const Home                  = () => import('@/views/demo/Home.vue')
const Login                 = () => import('@/views/demo/Login.vue')
const MyPage                = () => import('@/views/demo/MyPage.vue')
const Notices               = () => import('@/views/demo/Notices.vue')
const Programs              = () => import('@/views/demo/Programs.vue')
const Posters               = () => import('@/views/demo/Posters.vue')
const Poster                = () => import('@/views/demo/Poster.vue')
const PosterUpload          = () => import('@/views/demo/PosterUpload.vue')
const PosterUploadCompleted = () => import('@/views/demo/PosterUploadCompleted.vue')
const Qna                   = () => import('@/views/demo/Qna.vue')
const Sponsor               = () => import('@/views/demo/Sponsor.vue')
const Sponsors              = () => import('@/views/demo/Sponsors.vue')
const Session               = () => import('@/views/demo/Session.vue')
const Speakers              = () => import('@/views/demo/Speakers.vue')
const Vods                  = () => import('@/views/demo/Vods.vue')
const Vod                   = () => import('@/views/demo/Vod.vue')

const EnM                   = () => import('@/views/sicem2021/EnM.vue')
const Live                  = () => import('@/views/sicem2021/Live.vue')
const Welcome               = () => import('@/views/sicem2021/Welcome.vue')


Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Welcome' 
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      defaultToToday: false,
    }
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/posters',
    name: 'Posters',
    component: Posters
  },
  {
    path: '/poster',
    name: 'Poster',
    component: Poster
  },
  {
    path: '/vods',
    name: 'Vods',
    component: Vods
  },
  {
    path: '/vod',
    name: 'Vod',
    component: Vod
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: Sponsors
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: Sponsor
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/live',
    name: 'Live',
    component: Live
  },
  {
    path: '/poster-upload',
    name: 'PosterUpload',
    component: PosterUpload
  },
  {
    path: '/poster-upload-completed',
    name: 'PosterUploadCompleted',
    component: PosterUploadCompleted
  },
  {
    path: '/enm',
    name: 'EnM',
    component: EnM
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage
  },
  {
    path: '/qna',
    name: 'Qna',
    component: Qna
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
