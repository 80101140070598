import Vue from 'vue'
import Vuex from 'vuex'
import commentsApi from '@/api/v1/comments'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  instance: {
    type: '',
    id: 0
  },
  comments: [],
})

const getters = {
  mainComments: (state) => {
    return state.comments.filter(comment => !comment.parentCommentId)
  },
}

const actions = {
  getComments({ commit }, {instanceType: instanceType, instanceId: instanceId}) {
    return new Promise((resolve, reject) => {
      commentsApi.getComments(instanceType, instanceId)
        .then(resp => {
          let comments = camelcaseKeys(resp.comments, {deep: true})
          commit('setComments', comments)
          resolve(comments)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  postComment ({ state, commit }, commentParams) {
    return new Promise(resolve => {
      commentsApi.postComment(commentParams).then(resp => {
        if (state.instance.type === 'Poster') {
          commit('posters/updateSelectedPosterComment', camelcaseKeys(resp.comment, {deep: true}), { root: true })
        } else {
          commit('updateSingleComment', camelcaseKeys(resp.comment, {deep: true}))
        }
        resolve(resp)
      })
    })
  },
  deactivateComment ({ state, commit }, commentId) {
    return new Promise(resolve => {
      commentsApi.deactiveComment(commentId).then(resp => {
        if (state.instance.type === 'Poster') {
          commit('posters/removeSelectedPosterComment', camelcaseKeys(resp.comment, {deep: true}), { root: true })
        } else {
          commit('removeSingleComment', camelcaseKeys(resp.comment, {deep: true}))
        }
        resolve()
      })
    })
  },
  patchBodyComment ({ state, commit }, {commentId, commentParams}) {
    return new Promise(resolve => {
      commentsApi.patchComment(commentId, commentParams).then(resp => {
        if (state.instance.type === 'Poster') {
          commit('posters/updateSelectedPosterComment', camelcaseKeys(resp.comment, {deep: true}), { root: true })
        } else {
          commit('updateSingleComment', camelcaseKeys(resp.comment, {deep: true}))
        }
        resolve()
      })
    })
  },
  updateCommentInstance ({ commit }, instanceData) {
    commit('setCommentInstance', instanceData)
  },
  resetComments ({ commit }) {
    commit('setComments', [])
  },
}

const mutations = {
  setCommentInstance (state, {instanceType, instanceId}) {
    state.instance.type = instanceType
    state.instance.id = instanceId
  },
  setComments (state, comments) {
    state.comments = comments
  },
  updateSingleComment (state, updatedComment) {
    let index = state.comments.findIndex(comment => parseInt(comment.id) === updatedComment.id)
    if (index !== -1) {
      state.comments.splice(index, 1, updatedComment)
    } else {
      state.comments.push(updatedComment)
    }
  },
  removeSingleComment (state, removedComment) {
    let index = state.comments.findIndex(comment => parseInt(comment.id) === removedComment.id)
    if (index !== -1) {
      state.comments.splice(index, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
