import Vue        from 'vue'
import Vuex       from 'vuex'
import OrdersApi  from '@/api/v1/orders'
// import uniq      from 'lodash/uniq'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
})

const getters = {
}

const actions = {
  createOrder (_, orderParams) {
    return new Promise((resolve, reject) => {
      OrdersApi.postOrder(orderParams).then(resp => {
        let camelcasedResp = camelcaseKeys(resp.order, { deep: true })
        resolve(camelcasedResp)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
