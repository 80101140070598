import Vue                       from 'vue'
import Vuex                      from 'vuex'
import sessionsApi               from '@/api/v1/sessions'
import sessionsApiV2             from '@/api/v2/sessions'
import uniqBy                    from 'lodash/uniqBy'
import isEmpty                   from 'lodash/isEmpty'
import dayjs                     from 'dayjs'
import router                    from '@/router/index'
import { getField, updateField } from 'vuex-map-fields'
import cloneDeep                 from 'lodash/cloneDeep'

import { MessageBox }  from 'element-ui'

import utc      from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  sessions: [],
  sessionCategoryFilters: [],
  openSessionId: 0,
  selectedSession: {},
  selectedSessionAllSpeakers: [],
  liveSessions: [],
  currentSessionId: 0,
  redirectMessage: '',
  sessionDetailsList: [],
  sessionDetails: {
    locations: [],
    configurations: {},
  },
  isWatchingSession: false,
  // for sessions v2 api
  selectedSessionCurrentContentIndex: 0
})


const delayedNavigate = (currentSessionID, toSessionId) => {
  setTimeout(() => {
    if (parseInt(currentSessionID) === parseInt(toSessionId)) {
      router.go()
    } else {
      router.replace({name: 'Session', query: {session_id: toSessionId, autoplay: true}})
      location.reload(true)
    }
  }, 5000) // redirect in 5 seconds
}


const getters = {
  hasSessions (state) {
    return !isEmpty(state.sessions)
  },
  hasSelectedSession (state) {
    return !isEmpty(state.selectedSession)
  },
  hasLiveSessions (state) {
    return !isEmpty(state.liveSessions)
  },
  isLiveSession: (state, getters) => (session) => {
    return getters.hasSessions && session.sessionType === 'live_stream' && session.startTime && !session.endTime
  },
  hasSessionCategoryFilters: state => {
    return state.sessionCategoryFilters.length > 0
  },
  // configurations
  selectedSessionConfigAttendingMessage: (state, getters) => {
    return getters.hasSelectedSession ? state.selectedSession.configurations.attendingMessage : ''
  },
  selectedSessionConfigAttendingButtonText: (state, getters) => {
    return getters.hasSelectedSession ? state.selectedSession.configurations.attendingButtonText : ''
  },
  selectedSessionConfigThumbnailImageUrl: (state, getters) => {
    return getters.hasSelectedSession ? state.selectedSession.configurations.thumbnailImageUrl : ''
  },
  selectedSessionConfigAutoplay: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.autoplay !== 'undefined' ? state.selectedSession.configurations.autoplay : true
  },
  selectedSessionConfigForCredit: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.forCredit !== 'undefined' ? state.selectedSession.configurations.forCredit : true
  },
  selectedSessionConfigShowSessionExitButton: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.showSessionExitButton !== 'undefined' ? state.selectedSession.configurations.showSessionExitButton : false
  },
  selectedSessionConfigBlockViewingBeforeStartTime: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.blockViewingBeforeStartTime !== 'undefined' ? state.selectedSession.configurations.blockViewingBeforeStartTime : false
  },
  selectedSessionConfigBlockViewingBeforeStartTimeMessage: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.blockViewingBeforeStartTimeMessage !== 'undefined' ? state.selectedSession.configurations.blockViewingBeforeStartTimeMessage : 'You can watch this session after the session start time.'
  },
  selectedSessionConfigBlockViewingBeforeStartTimeButtonText: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.blockViewingBeforeStartTimeButtonText !== 'undefined' ? state.selectedSession.configurations.blockViewingBeforeStartTimeButtonText : 'OK'
  },
  selectedSessionConfigTroubleShootModalOpenButtonText: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.troubleshootModalOpenButtonText !== 'undefined' ? state.selectedSession.configurations.troubleshootModalOpenButtonText : 'Troubleshoot My Video'
  },
  selectedSessionConfigTroubleShootModalTitle: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.troubleshootModalTitle !== 'undefined' ? state.selectedSession.configurations.troubleshootModalTitle : 'Troubleshooting This Video'
  },
  selectedSessionConfigTroubleShootModalText: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.troubleshootModalText !== 'undefined' ? state.selectedSession.configurations.troubleshootModalText : 'If your video is not displaying properly, please try refreshing the page, or switch to the backup video source.'
  },
  selectedSessionConfigTroubleShootModalRefreshButtonText: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.troubleshootModalRefreshButtonText !== 'undefined' ? state.selectedSession.configurations.troubleshootModalRefreshButtonText : 'Refresh Page'
  },
  selectedSessionConfigTroubleShootModalUseBackupSourceButtonText: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.troubleshootModalUseBackupSourceButtonText !== 'undefined' ? state.selectedSession.configurations.troubleshootModalUseBackupSourceButtonText : 'Use Backup Source'
  },
  selectedSessionConfigVideoNotStartedModalText: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.videoNotStartedModalText !== 'undefined' ? state.selectedSession.configurations.videoNotStartedModalText : 'We are currently preparing for live streaming. Please check the start time.'
  },
  selectedSessionConfigDefaultPreviewVideoPlayer: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.defaultPreviewVideoPlayer !== 'undefined' ? state.selectedSession.configurations.defaultPreviewVideoPlayer : 'vimeo'
  },
  selectedSessionConfigDefaultLiveVideoPlayer: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.defaultLiveVideoPlayer !== 'undefined' ? state.selectedSession.configurations.defaultLiveVideoPlayer : 'videojs'
  },
  selectedSessionConfigDefaultVodVideoPlayer: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.defaultVodVideoPlayer !== 'undefined' ? state.selectedSession.configurations.defaultVodVideoPlayer : 'vimeo'
  },
  selectedSessionConfigSelectableLiveVideoPlayers: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.selectableLiveVideoPlayers !== 'undefined' ?  state.selectedSession.configurations.selectableLiveVideoPlayers : [{ "id": 1, "name": "videojs" }, { "id": 2, "name": "vimeo" }]
  },
  selectedSessionConfigOtherSessionPageContent: (state, getters) => {
    return getters.hasSelectedSession && typeof state.selectedSession.configurations.otherSessionPageContent !== 'undefined' ?  state.selectedSession.configurations.otherSessionPageContent : ''
  },
  // configurations
  selectedSessionId: (state, getters) => {
    return getters.hasSelectedSession ? state.selectedSession.id : 0
  },
  selectedSessionContent: (state, getters) => {
    return getters.hasSelectedSession ? state.selectedSession.content : {}
  },
  selectedSessionChairs: state => {
    return state.selectedSessionAllSpeakers.filter(speaker => speaker.speakerRole.toLowerCase() === 'chair')
  },
  selectedSessionModerators: state => {
    return state.selectedSessionAllSpeakers.filter(speaker => speaker.speakerRole.toLowerCase() === 'moderator')
  },
  selectedSessionSpeakers: state => {
    return state.selectedSessionAllSpeakers.filter(speaker => speaker.speakerRole.toLowerCase() === 'speaker')
  },
  selectedSessionPanelists: state => {
    return state.selectedSessionAllSpeakers.filter(speaker => speaker.speakerRole.toLowerCase() === 'panelist')
  },
  selectedSessionPanels: state => {
    return state.selectedSessionAllSpeakers.filter(speaker => speaker.speakerRole.toLowerCase() === 'panel')
  },
  selectedSessionPresenters: state => {
    return state.selectedSessionAllSpeakers.filter(speaker => speaker.speakerRole.toLowerCase() === 'presenter')
  },
  hasSelectedSessionContent (state, getters) {
    return !isEmpty(getters.selectedSessionContent)
  },
  hasManySessionRunningDays (state, getters) {
    return getters.hasSessions && state.sessions.length > 1
  },
  selectedSessionOtherSessions (state, getters) {
    return getters.hasSelectedSession ? state.selectedSession.otherSessions : []
  },
  sessionsDateCount (state, getters) {
    return (getters.hasSessions) ? state.sessions.length : 0
  },
  sessionDates (state, getters) {
    return (getters.hasSessions) ? getters.sessionsByDate.map(sessionDate => {return sessionDate.date }) : []
  },
  sessionsByDate (state, getters) {
    if (getters.hasSessions) {
      let result = state.sessions
        .filter(dailySessions => dailySessions.sessions.some(session => !session.configurations.hideSessionInProgramAtAGlance))
        .map(dailySessions => {
        let filteredSessions = (getters.hasSessionCategoryFilters) 
                             ? dailySessions.sessions.filter(session => state.sessionCategoryFilters.includes(session.category) && !session.configurations.hideSessionInProgramAtAGlance) 
                             : dailySessions.sessions.filter(session => !session.configurations.hideSessionInProgramAtAGlance) 

        let scheduleStartTime = new Date(Math.min.apply(null, filteredSessions.map(session => {
          return new Date(session.scheduledStartTime)
        })))
        let scheduleEndTime = new Date(Math.max.apply(null, filteredSessions.map(session => {
          return new Date(session.scheduledEndTime)
        })))
        let locations = uniqBy(filteredSessions.map(session => {
          return session.locations
        }).flat())

        let editedFilteredSessions = null
        if (locations.every(location => location.includes('||'))) {
          locations = locations.sort((a,b) => a.split('||')[1] - b.split('||')[1])
                               .map(location => location.split('||')[0])
          editedFilteredSessions = filteredSessions.forEach(session => {
            session.locations = session.locations.map(location => location.split('||')[0])
          })
        } else {
          locations = locations.sort()
        }

        let categories = filteredSessions.map(session => {
          return session.category
        })
        return {
          date: dailySessions.date,
          sessions: editedFilteredSessions || filteredSessions,
          scheduleStartTime: dayjs(scheduleStartTime).tz("Asia/Seoul").format('H00'),
          scheduleEndTime: dayjs(scheduleEndTime).tz("Asia/Seoul").format('Hmm'),
          locations: locations,
          categories: uniqBy(categories.flat().sort())
        }
      })
      return result.sort((a, b) => new Date(a.date) - new Date(b.date))
    } else {
      return []
    }
  },
  selectedSessionType (state, getters) {
    return getters.hasSelectedSession ? state.selectedSession.sessionType : ''
  },
  selectedSessionCarouselSponsors (state, getters) {
    return getters.hasSelectedSession ? state.selectedSession.carouselSponsors : []
  },
  // for sessions v2 api
  selectedSessionListOfContents: (state, getters) => {
    return getters.hasSelectedSession ? state.selectedSession.listOfContents : []
  },
  hasManySelectedSessionListOfContents: (state, getters) => {
    return getters.hasSelectedSession && state.selectedSession.listOfContents.length > 1
  },
  selectedSessionCurrentContent: (state, getters) => {
    return getters.hasSelectedSession ? state.selectedSession.listOfContents[state.selectedSessionCurrentContentIndex] : {}
  },
  hasSelectedSessionCurrentContent: (state, getters) => {
    return !isEmpty(getters.selectedSessionCurrentContent)
  },
  selectedSessionCurrentContentSourceUrl: (state, getters) => {
    return getters.hasSelectedSessionCurrentContent && getters.selectedSessionCurrentContent.data.sourceUrl ? getters.selectedSessionCurrentContent.data.sourceUrl : ''
  },
  getField
}


const actions = {
  getSessions ({commit, rootGetters}, type) {
    return new Promise(resolve => {
      sessionsApi.getSessions(rootGetters['events/showingEventId'], type).then(resp => {
        commit('setSessions', camelcaseKeys(resp.session_list, {deep: true}))
        resolve()
      })
    })
  },
  getCurrentSessionId ({commit, rootGetters}, sessionId) {
    return new Promise(resolve => {
      sessionsApi.getCurrentSessionId(sessionId, rootGetters['events/showingEventId']).then(resp => {
        commit('setCurrentSessionId', resp.session_id)
        resolve(resp.session_id)
      })
    })
  },
  getSessionsWithSpeakers ({commit, rootGetters}, type) {
    return new Promise(resolve => {
      sessionsApi.getSessionsWithSpeakers(rootGetters['events/showingEventId'], type).then(resp => {
        commit('setSessions', camelcaseKeys(resp.session_list, {deep: true}))
        resolve()
      })
    })
  },
  getSingleSession ({commit, rootGetters}, sessionId) {
    return new Promise(resolve => {
      sessionsApi.getSingleSession(sessionId, rootGetters['events/showingEventId']).then(resp => {
        commit('setSelectedSession', camelcaseKeys(resp.session, {deep: true}))
        resolve(camelcaseKeys(resp, {deep: true}))
      })
    })
  },
  searchSessions ({ rootGetters }, searchString) {
    return new Promise(resolve => {
      sessionsApi.searchByString(rootGetters['events/showingEventId'], searchString).then(resp => {
        resolve(camelcaseKeys(resp.session_list, {deep: true}))
      })
    })
  },
  getSessionSpeakers ({ commit, rootGetters }, sessionId) {
    return new Promise(resolve => {
      sessionsApi.getSessionSpeakers(sessionId, rootGetters['events/showingEventId']).then(resp => {
        commit('setSelectedSessionAllSpeakers', camelcaseKeys(resp.speakers, {deep: true}))
        resolve()
      })
    })
  },
  getSessionDetailsList ({ commit, rootGetters }, params) {
    console.log('parasm looks like', params)
    return new Promise(resolve => {
      sessionsApi.getSessionsDetails(rootGetters['events/showingEventId'], params).then(resp => {
        commit('setSessionDetailsList', camelcaseKeys(resp.session_list, {deep: true}))
        resolve()
      })
    })
  },
  getMyLikedSessions ({ rootGetters }) {
    return new Promise(resolve => {
      sessionsApi.getMyLikedSessions(rootGetters['events/showingEventId']).then(resp => {
        // commit('setSessionDetailsList', camelcaseKeys(resp.session_list, {deep: true}))
        resolve(camelcaseKeys(resp.session_list, {deep: true}))
      })
    })
  },
  getSingleSessionDetails ({ commit, rootGetters }, sessionId) {
    return new Promise(resolve => {
      sessionsApi.getSingleSessionDetails(rootGetters['events/showingEventId'], sessionId).then(resp => {
        commit('setSessionDetails', camelcaseKeys(resp.session, {deep: true}))
        resolve(camelcaseKeys(resp.session, {deep: true}))
      })
    })
  },
  resetCategoryFilters ({ commit }) {
    commit('setSessionCategoryFilters', [])
  },
  postLikeSession ({rootGetters, commit}, sessionId) {
    return new Promise(resolve => {
      sessionsApi.postLikeSession(rootGetters['events/showingEventId'], sessionId).then(resp => {
        commit('toggleSessionLikeStatus', camelcaseKeys(resp.user_session, {deep: true}).sessionId)
        resolve(camelcaseKeys(resp.user_session, {deep: true}))
      })
    })
  },
  getLiveSessions ({commit, rootGetters}) {
    return new Promise(resolve => {
      sessionsApi.getLiveSessions(rootGetters['events/showingEventId']).then(resp => {
        commit('setLiveSessions', camelcaseKeys(resp.live, {deep: true}))
        resolve()
      })
    })
  },
  subscribeToGoto ({commit, dispatch}, sessionId) {
    let channel = `session:${sessionId};goto`
    dispatch('realtimeSubscriptions/subscribe',
      {
        channel: channel,
        event: 'gotoSession',
        callback: (message) => {
          let randomOffset = Math.floor(Math.random() * 4) * 5000 // 0, 5, 10, 15 secs
          setTimeout(() => {
            commit('setRedirectMessage', message.data.message)
            delayedNavigate(parseInt(sessionId), parseInt(message.data.to_session_id))
          }, randomOffset)
        }
      },
      {root: true})
  },
  unsubscribeFromGoto ({commit, dispatch, rootGetters}) {
    commit('clearRedirectMessage')
    let subs = rootGetters['realtimeSubscriptions/currentSubscriptions'].filter(sub => sub.channel.includes(';goto'))
    subs.forEach(sub => dispatch('realtimeSubscriptions/unsubscribe', {channel: sub.channel}, {root: true}))
  },
  startWatchSession ({ commit }) {
    commit('setIsWatchingSession', true)
  },
  stopWatchSession ({ commit }) {
    commit('setIsWatchingSession', false)
  },
  // for sessions v2 api
  getSingleSessionV2 ({commit, rootGetters}, sessionId) {
    return new Promise(resolve => {
      sessionsApiV2.getSingleSession(sessionId, rootGetters['events/showingEventId']).then(resp => {
        commit('setSelectedSession', camelcaseKeys(resp.session, {deep: true}))
        resolve(camelcaseKeys(resp.session, {deep: true}))
      })
    })
  },
  getSingleSessionDetailsNoLoginRequired ({commit, rootGetters}, sessionId) {
    return new Promise(resolve => {
      sessionsApiV2.getSingleSessionNoLoginRequired(sessionId, rootGetters['events/showingEventId']).then(resp => {
        commit('setSelectedSession', camelcaseKeys(resp.session, {deep: true}))
        resolve()
      })
    })
  },
  setSessionScore ({ rootGetters }, {sessionId: sessionId, score: score}) {
    return new Promise(resolve => {
      sessionsApi.patchSessionScore(sessionId, rootGetters['events/showingEventId'], score).then(resp => {
        resolve(resp)
      })
    })  
  },
  goToCurrentSession ({ dispatch }, sessionId) {
    dispatch('getCurrentSessionId', sessionId).then(currentSessionIdFromApi => {
      if (currentSessionIdFromApi) {
        let query = cloneDeep(router.history.current.query)
        query['session_id'] = sessionId
        if (sessionId === currentSessionIdFromApi) {          
          router.push({name: 'Session', query: query}).catch(() => {})
        } else { // when the clicked session is not the current session for the room
          MessageBox.confirm('This is not the current session for this room. Would you like to see the current session?', {
            confirmButtonText: 'Go to Current',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(() => {
            router.push({name: 'Session', query: query}).catch(() => {})
          }).catch(() => { 
            // do nothing
          })
        }
      } else {
        MessageBox.alert('There are no sessions in progress', {
          confirmButtonText: 'Done',
          type: 'warning'
        })
      }
    })
  },
}


const mutations = {
  clearRedirectMessage (state) {
    state.redirectMessage = ''
  },
  setRedirectMessage (state, message) {
    state.redirectMessage = message
  },
  setSessions (state, fromApi) {
    state.sessions = fromApi
  },
  setSelectedSession (state, selectedSession) {
    state.selectedSession = selectedSession
  },
  setSelectedSessionAllSpeakers (state, speakers) {
    state.selectedSessionAllSpeakers = speakers
  },
  setSessionCategoryFilters (state, categories) {
    state.sessionCategoryFilters = categories
  },
  setSessionDetailsList (state, sessionDetailsList) {
    state.sessionDetailsList = sessionDetailsList
  },
  setSessionDetails (state, sessionDetails) {
    state.sessionDetails = sessionDetails
  },
  toggleSessionLikeStatus (state, sessionId) {
    let sessionList = state.sessions.map(sessionsByDate => sessionsByDate.sessions).flat()
    let likeToggledSession = sessionList.find(session => session.id == sessionId)
    if (likeToggledSession) {
      likeToggledSession.me.myLikeStatus = !likeToggledSession.me.myLikeStatus
    }
  },
  setLiveSessions (state, fromApi) {
    state.liveSessions = fromApi
  },
  setCurrentSessionId (state, fromApi) {
    state.currentSessionId = fromApi
  },
  setIsWatchingSession (state, isWatchingSession) {
    state.isWatchingSession = isWatchingSession
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
