import Vue                          from 'vue'
import VueRouter                    from 'vue-router'

const SingleComponentPage          = () => import('@/views/demo/SingleComponentPage.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/demo/Home.vue'),
    props: {
      redirectPage: 'Main'
    }
  },
  {
    path: '/main',
    name: 'Main',
    component: SingleComponentPage,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: SingleComponentPage
  },
  {
    path: '/registration-aavbm',
    name: 'RegistrationAavbm',
    component: () => import('@/views/icola2023/RegistrationAavbm.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/views/icola2023/Signup.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/demo/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/demo/Logout.vue')
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: () => import('@/views/icola2023/MyPage.vue')
  },
  // icola2022
  {
    path: '/welcome-message',
    name: 'WelcomeMessage',
    component: SingleComponentPage
  },
  {
    path: '/organization',
    name: 'Organization',
    component: SingleComponentPage
  },
  {
    path: '/venue',
    name: 'Venue',
    component: SingleComponentPage
  },
  {
    path: '/accommodation',
    name: 'Accommodation',
    component: SingleComponentPage
  },
  {
    path: '/notices',
    name: 'Notices',
    component: () => import('@/views/demo/Notices.vue')
  },
  // Program
  {
    path: '/programs',
    name: 'Programs',
    component: () => import('@/views/demo/Programs.vue'),
    props: {
      defaultToToday: true,
    }
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: SingleComponentPage,
  },
  {
    path: '/program-details-for-category',
    name: 'ProgramDetailsForSingleCategory',
    component: () => import('@/views/icola2023/ProgramDetailsForSingleCategory.vue'),
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: () => import('@/views/koa2022/Speakers.vue'),
  },
  // call for abstracts
  {
    path: '/abstract-submission',
    name: 'AbstractSubmission',
    component: () => import('@/views/common/AbstractSubmission.vue')
  },
  {
    path: '/abstract-submissions',
    name: 'AbstractSubmissions',
    component: () => import('@/views/common/AbstractSubmissions.vue')
  },
  {
    path: '/abstract-submission-guidelines',
    name: 'AbstractSubmissionGuidelines',
    component: SingleComponentPage
  },
  {
    path: '/presentation-guidelines-oral',
    name: 'PresentationGuidelinesOral',
    component: SingleComponentPage
  },
  {
    path: '/presentation-guidelines-moderated-poster-presentation',
    name: 'PresentationGuidelinesModeratedPosterPresentation',
    component: SingleComponentPage
  },
  {
    path: '/presentation-guidelines-poster-exhibition',
    name: 'PresentationGuidelinesPosterExhibition',
    component: SingleComponentPage
  },
  // registration
  {
    path: '/registration-guidelines-oversea',
    name: 'RegistrationGuidelinesOversea',
    component: SingleComponentPage
  },
  {
    path: '/registration-guidelines-domestic',
    name: 'RegistrationGuidelinesDomestic',
    component: SingleComponentPage
  },
  {
    path: '/icola-scoring',
    name: 'IcolaScoring',
    component: SingleComponentPage
  },
  // sponsorship
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: SingleComponentPage,
    props: {
      requireLogin: false,
    }
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: SingleComponentPage,
    props: {
      requireLogin: false,
    }
  },
  {
    path: '/exhibition',
    name: 'Exhibition',
    component: SingleComponentPage
  },
  // general information
  {
    path: '/covid19',
    name: 'Covid19',
    component: SingleComponentPage
  },
  {
    path: '/about-korea',
    name: 'AboutKorea',
    component: SingleComponentPage
  },
  {
    path: '/attractions-in-seoul',
    name: 'AttractionsInSeoul',
    component: SingleComponentPage
  },
  {
    path: '/transportation',
    name: 'Transportation',
    component: SingleComponentPage
  },
  {
    path: '/useful-information',
    name: 'UsefulInformation',
    component: SingleComponentPage
  },
  {
    path: '/social-event-registration-guidelines',
    name: 'SocialEventRegistrationGuidelines',
    component: SingleComponentPage
  },
  {
    path: '/visa',
    name: 'Visa',
    component: SingleComponentPage
  },
  {
    path: '/signup-split',
    name: 'SignupSplit',
    component: SingleComponentPage
  },
  {
    path: '/html-test-page',
    name: 'HtmlPageTest',
    component: () => import('@/views/demo/HtmlPageTest.vue')
  },
  {
    path: '/find-login-id',
    name: 'FindLoginId',
    component: () => import('@/views/icola2023/FindLoginId.vue')
  },
  {
    path: '/change-login-password',
    name: 'ChangeLoginPassword',
    component: () => import('@/views/icola2023/ChangeLoginPassword.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
