import Vue from 'vue'
import Vuex from 'vuex'
import * as Ably from 'ably'

Vue.use(Vuex)
const ablyClient = new Ably.Realtime({ key: process.env.VUE_APP_ABLY_SUBSCRIBE_KEY })

const state = () => ({
  currentSubscriptions: [],
})

const getters = {
  currentSubscriptions: (state) => {
    return state.currentSubscriptions
  }
}

const actions = {
  subscribe ({ commit }, {channel, event, callback}) {
    let ablyChannel = ablyClient.channels.get(channel)
    ablyChannel.subscribe(event, callback)
    commit('addToCurrentSubscriptions', {channel: channel, event: event})
  },
  unsubscribe ({ commit }, {channel}) {
    let ablyChannel = ablyClient.channels.get(channel)
    ablyChannel.unsubscribe()
    commit('removeFromCurrentSubscriptions', channel)
  },
}

const mutations = {
  addToCurrentSubscriptions (state, subscription) {
    let index = state.currentSubscriptions.findIndex(sub => sub.channel === subscription.channel && sub.event === subscription.event)
    if (index >= 0) {
      state.currentSubscriptions.splice(index, 1, subscription)
    } else {
      state.currentSubscriptions.push(subscription)
    }
  },
  removeFromCurrentSubscriptions (state, channel) {
    let index = state.currentSubscriptions.findIndex(sub => sub.channel === channel)
    if (index >= 0) {
      state.currentSubscriptions.splice(index, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
