import Vue from 'vue'
import App from './App.vue'
import currentRouter from './router/index'
import store from './store'


Vue.config.productionTip = false

/////////////////// Styles to make tailwind work
import './assets/tailwind.css'


import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)

//////////////// Icons
// import VueFeather from 'vue-feather'
// Vue.use(VueFeather)


//////////////// Just a Modal component
import VModal from 'vue-js-modal'
Vue.use(VModal)


//////////////// Use prebuilt components from Element-UI
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ko'
Vue.use(Element, {locale})


//////////////// Use vimeo player for VOD content
import VueVimeoPlayer from 'vue-vimeo-player'
Vue.use(VueVimeoPlayer)

////////////// Allows us to portal components to different locations
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

//////////////// Maps
// var Leaflet = require('@/assets/plugins/leaflet')
// Vue.use(Leaflet)

//////////////// Get korean address input
import VueDaumPostcode from 'vue-daum-postcode'
Vue.use(VueDaumPostcode)


//////////////// Lets us smoothly scroll to an anchor link on a route/page
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo, {
  duration: 600,
  easing: "ease-out",})


//////////////// Update meta tags for SEO
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

/////////////// Need to get app id for google analytics
import VueGtag from "vue-gtag"
const isDevelopment = process.env.NODE_ENV === 'development'
Vue.use(VueGtag, {
  config: {
    id: '290023266',
    enabled: !isDevelopment
  },
  includes: [
    { id: 'G-R7HE017PM2' }, //
  ],
}, currentRouter)


///////////////// Cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('1d')


new Vue({
  router: currentRouter,
  store,
  render: h => h(App)
}).$mount('#app')
