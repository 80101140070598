import Vue               from 'vue'
import Vuex              from 'vuex'
import sessionNoticesApi from '@/api/v1/session-notices'

Vue.use(Vuex)

const state = () => ({
  sessionNotices: [],
})

const getters = {
  activeNotices: state => {
    return state.sessionNotices.filter(notice => !notice.dismissed)
  },
}

const actions = {
  getSessionNotices ({commit, rootGetters}, sessionId) {
    return new Promise((resolve) => {
      let params = {session_id: sessionId, event_id: rootGetters['events/showingEventId']}
      sessionNoticesApi.getSessionNotices(params).then(resp => {
        commit('setSessionNotices', resp.session_notices)
        resolve()
      })
    })
  },
  subscribeToNotices ({commit, dispatch}, sessionId) {
    let channel = `session:${sessionId};notices`
    dispatch('realtimeSubscriptions/subscribe',
      {
        channel: channel, event: 'noticeCreated', callback: (message) => {
          commit('addUpdateCreatedNoticeToSessionNotices', message.data.session_notice)
        }
      },
      {root: true})
    dispatch('realtimeSubscriptions/subscribe',
      {
        channel: channel, event: 'noticeUpdated', callback: (message) => {
          commit('addUpdateCreatedNoticeToSessionNotices', message.data.session_notice)
        }
      },
      {root: true})
    dispatch('realtimeSubscriptions/subscribe',
      {
        channel: channel, event: 'noticeDeleted', callback: (message) => {
          commit('removeSessionNotice', message.data.session_notice)
        }
      },
      {root: true})
  },
  unsubscribeFromNotices ({dispatch, rootGetters}) {
    let subs = rootGetters['realtimeSubscriptions/currentSubscriptions'].filter(sub => sub.channel.includes(';notices'))
    subs.forEach(sub => dispatch('realtimeSubscriptions/unsubscribe', {channel: sub.channel}, {root: true}))
  },
}

const mutations = {
  addUpdateCreatedNoticeToSessionNotices (state, sessionNotice) {
    sessionNotice.dismissed = false
    let index = state.sessionNotices.findIndex(notice => notice.id === sessionNotice.id)
    if (index >= 0) {
      Vue.$cookies.remove(`webconcert_dismissednotice_${sessionNotice.id}`)
      state.sessionNotices.splice(index, 1, sessionNotice)
    } else {
      state.sessionNotices.push(sessionNotice)
    }
  },
  setSessionNotices (state, sessionNotices) {
    sessionNotices.forEach(notice => {
      notice.dismissed = Vue.$cookies.isKey(`webconcert_dismissednotice_${notice.id}`)
    })
    state.sessionNotices = sessionNotices
  },
  removeSessionNotice (state, sessionNotice) {
    let index = state.sessionNotices.findIndex(notice => notice.id === sessionNotice.id)
    if (index >= 0) {
      Vue.$cookies.remove(`webconcert_dismissednotice_${sessionNotice.id}`)
      state.sessionNotices.splice(index, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
