import base from '../base'

const updateMembership = (params) => {
  return base.patch('/users/me/membership_data', params).then(resp => resp.data)
}


export default {
  updateMembership: updateMembership,
}
