import Vue        from 'vue'
import Vuex       from 'vuex'
import sponsorApi from '@/api/v1/sponsors'
import isEmpty    from 'lodash/isEmpty'

Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  sponsorList: [],
  selectedSponsor: {},
  showSponsorContentDetail: false,
  selectedSponsorContentDetail: {
    title: '',
    description: '',
    format: ''
  },
  showSponsorQuiz: false
})

// getters
const getters = {
  hasSelectedSponsor: (state) => {
    return !isEmpty(state.selectedSponsor)
  },
  selectedSponsorMainContent: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.contentList[0] : {}
  },
  selectedSponsorOtherContents: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.contentList.slice(1) : []
  },
  hasSelectedSponsorOtherContents: (state, getters) => {
    return !isEmpty(getters.selectedSponsorOtherContents)
  },
  selectedSponsorStamped: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.me.stamped : false
  },
  selectedSponsorInfoRequested: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.me.requestedInfo : false
  },
  selectedSponsorQuizCompleted: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.me.quizCompleted : false
  },
  hasSelectedSponsorStampButton: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.hasStamp : false
  },
  hasSelectedSponsorRequestInfoButton: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.hasRequestInfo : false
  },
  selectedSponsorStampButtonText: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.stampButtonText : ''
  },
  selectedSponsorRequestInfoButtonText: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.requestInfoButtonText : ''
  },
  selectedSponsorStampButtonColor: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.stampButtonColor : ''
  },
  selectedSponsorRequestInfoButtonColor: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.requestInfoButtonColor : ''
  },
  selectedSponsorStampModalText: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.stampModalText : ''
  },
  selectedSponsorRequestInfoModalText: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.requestInfoModalText : ''
  },
  selectedSponsorStampModalConfirmButtonText: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.stampModalConfirmButtonText : ''
  },
  selectedSponsorRequestInfoModalConfirmButtonText: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.requestInfoModalConfirmButtonText : ''
  },
  selectedSponsorStampDoneText: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.stampDoneText : ''
  },
  selectedSponsorRequestInfoDoneText: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.requestInfoDoneText : ''
  },
  selectedSponsorWaitingTimeBeforeStamp: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.waitingTimeBeforeStamp : 0
  },
  selectedSponsorWaitingMessageBeforeStamp: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.waitingMessageBeforeStamp : ''
  },
  hasSelectedSponsorQuizButton: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.hasQuiz : false
  },
  selectedSponsorQuizQuestionText: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.quizQuestionText : ''
  },
  selectedSponsorQuizAnswers: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.quizAnswers : []
  },
  selectedSponsorProductInformationTitle: (state, getters) => {
    return getters.hasSelectedSponsor ? state.selectedSponsor.configurations.productInformationTitle : ''
  },
}

// actions
const actions = {
  getSponsors ({commit, rootGetters}, showingPage) {
    return new Promise(resolve => {
      let queryString = `?event_id=${rootGetters['events/showingEventId']}&showing_page=${showingPage}`
      sponsorApi.getSponsors(queryString).then(resp => {
        commit('setSponsors', camelcaseKeys(resp.sponsor_list, {deep: true}))
        resolve()
      })
    })
  },
  getStampedSponsors ({rootGetters}) {
    return new Promise(resolve => {
      sponsorApi.getStampedSponsors(rootGetters['events/showingEventId']).then(resp => {
        resolve(camelcaseKeys(resp.sponsor_list, {deep: true}))
      })
    })
  },
  getSingleSponsor ({commit, rootGetters}, sponsorId) {
    return new Promise(resolve => {
      sponsorApi.getSingleSponsor(sponsorId, rootGetters['events/showingEventId']).then(resp => {
        commit('setSelectedSponsor', camelcaseKeys(resp.sponsor, {deep: true}))
        resolve()
      })
    })
  },
  getSponsorsNoAuth ({commit, rootGetters}, showingPage) {
    return new Promise(resolve => {
      let queryString = `?event_id=${rootGetters['events/showingEventId']}&showing_page=${showingPage}`
      sponsorApi.getSponsorsNoAuth(queryString).then(resp => {
        commit('setSponsors', camelcaseKeys(resp.sponsor_list, {deep: true}))
        resolve()
      })
    })
  },
  getSingleSponsorNoAuth ({commit, rootGetters}, sponsorId) {
    return new Promise(resolve => {
      sponsorApi.getSingleSponsorNoAuth(sponsorId, rootGetters['events/showingEventId']).then(resp => {
        commit('setSelectedSponsor', camelcaseKeys(resp.sponsor, {deep: true}))
        resolve()
      })
    })
  },
  stampSponsor ({rootGetters}, sponsorId) {
    return new Promise(resolve => {
      sponsorApi.postBoothVisitor(sponsorId, 'stamp', '', rootGetters['events/showingEventId']).then(resp => {
        resolve(resp)
      })
    })
  },
  postBoothVisitor ({commit, rootGetters}, boothVisitorParams) {
    return new Promise(resolve => {
      sponsorApi.postBoothVisitor(boothVisitorParams.sponsorId, boothVisitorParams.actionType, boothVisitorParams.quizAnswer, rootGetters['events/showingEventId']).then(() => {
        if (boothVisitorParams.actionType == 'stamp') {
          commit('setUserBoothVisitorStampStatus')
        } else if (boothVisitorParams.actionType == 'request_info') {
          commit('setUserBoothVisitorRequestInfoStatus')
        } else if (boothVisitorParams.actionType == 'quiz') {
          commit('setUserBoothVisitorQuizCompleteStatus')
        }
      })
      resolve()
    })
  },
  openSponsorContentDetail ({ commit }) {
    commit('setShowSponsorContentDetail', true)
  },
  closeSponsorContentDetail ({ commit }) {
    commit('setShowSponsorContentDetail', false)
  },
  selecteSponsorContentDetail ({commit}, selectedSponsorContentDetail) {
    commit('setSelectedSponsorContentDetail', selectedSponsorContentDetail)
  },
  resetSelectedSponsorContentDetail ({commit}) {
    commit('setSelectedSponsorContentDetail', { title: '', description: '', format: '' })
  },
  openSponsorQuiz ({ commit }) {
    commit('setShowSponsorQuiz', true)
  },
  closeSponsorQuiz ({ commit }) {
    commit('setShowSponsorQuiz', false)
  },
}

// mutations
const mutations = {
  setSponsors (state, sponsorList) {
    state.sponsorList = sponsorList
  },
  setSelectedSponsor (state, selectedSponsor) {
    state.selectedSponsor = selectedSponsor
  },
  setUserBoothVisitorStampStatus (state) {
    state.selectedSponsor.me.stamped = true
  },
  setUserBoothVisitorRequestInfoStatus (state) {
    state.selectedSponsor.me.requestedInfo = true
  },
  setUserBoothVisitorQuizCompleteStatus (state) {
    state.selectedSponsor.me.quizCompleted = true
  },
  setShowSponsorContentDetail (state, showSponsorContentDetail) {
    state.showSponsorContentDetail = showSponsorContentDetail
  },
  setSelectedSponsorContentDetail (state, selectedSponsorContentDetail) {
    state.selectedSponsorContentDetail = selectedSponsorContentDetail
  },
  setShowSponsorQuiz (state, showSponsorQuiz) {
    state.showSponsorQuiz = showSponsorQuiz
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
