import base from './base'

const getNotices = (eventId) => {
  return base.get(`/notices`, {
    params: {
      event_id: eventId
    }
  }).then(resp => resp.data)
}

const getNoticesPreview = (eventId) => {
  return base.get(`/notices/preview`, {
    params: {
      event_id: eventId
    }
  }).then(resp => resp.data)
}

const createNotice = (noticeParams) => {
  return base.post(`/notices`, noticeParams)
    .then(resp => resp.data)
}

const updateNotice = (noticeParams, noticeId) => {
  return base.patch(`/notices/${noticeId}`, noticeParams)
    .then(resp => resp.data)
}

const deleteNotice = (noticeId) => {
  return base.delete(`/notices/${noticeId}`)
    .then(resp => resp.data)
}

export default {
  getNotices:   getNotices,
  getNoticesPreview: getNoticesPreview,
  createNotice: createNotice,
  updateNotice: updateNotice,
  deleteNotice: deleteNotice,
}
