import Vue                       from 'vue'
import Vuex                      from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import sessionQuestionsApi       from '@/api/v1/session-questions'
import isEmpty from 'lodash/isEmpty'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  sessionQuestions: [],
  questionTypeShownToUser: 'all', // 'all', 'mine'
  sortOrderForUser: 'createdAt' // 'likeCount'
})

const getters = {
  hasSessionQuestions: (state) => {
    return !isEmpty(state.sessionQuestions)
  },
  sessionQuestionsCount: (state) => {
    return state.sessionQuestions.length
  },
  mySessionQuestions: (state, getters) => {
    return getters.hasSessionQuestions ? state.sessionQuestions.filter(question => question.me.isMyQuestion) : []
  },
  mySessionQuestionsCount: (state, getters) => {
    return getters.mySessionQuestions.length
  },
  showingSessionQuestions: (state, getters) => {
    if (state.questionTypeShownToUser === 'all') {
      // all questions
      if (state.sortOrderForUser === 'createdAt') {
        return state.sessionQuestions.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      } else {
        return state.sessionQuestions.sort((a, b) => {
          if (a.likeCount === b.likeCount) {
            return new Date(a.createdAt) - new Date(b.createdAt)
          }
          return b.likeCount - a.likeCount
        })
      }
    } else {
      // my questions
      if (state.sortOrderForUser === 'createdAt') {
        return getters.mySessionQuestions.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      } else {
        return getters.mySessionQuestions.sort((a, b) => {
          if (a.likeCount === b.likeCount) {
            return new Date(a.createdAt) - new Date(b.createdAt)
          }
          return b.likeCount - a.likeCount
        })
      }
    }
  },
  showingSessionQuestionsCount: (state, getters) => {
    return getters.showingSessionQuestions.length
  },
  getField
}

const actions = {
  getSessionQuestions ({ commit, rootGetters }, sessionId) {
    return new Promise(resolve => {
      let params = {
        event_id: rootGetters['events/showingEventId'],
        session_id: sessionId
      }
      sessionQuestionsApi.getSessionQuestions(params).then(resp => {
        commit('setSessionQuestions', camelcaseKeys(resp.question_list, { deep: true }))
        resolve()
      })
    })
  },
  subscribeToLiveSessionQuestions ({ commit, dispatch }, sessionId) {
    let channel = `session:${sessionId};questions`
    dispatch('realtimeSubscriptions/subscribe',
      {channel: channel, event: 'questionCreated', callback: (message) => {
        let question = camelcaseKeys(message, {deep: true}).data.question
        commit('addCreatedQuestionToSessionQuestions', {from: 'ably', createdQuestion: question })
      }},
      { root: true })
    dispatch('realtimeSubscriptions/subscribe',
      {channel: channel, event: 'questionUpdated', callback: (message) => {
        let question = camelcaseKeys(message, {deep: true}).data.question
        commit('ablyUpdateEditedSessionQuestionText', {questionId: question.id, questionText: question.questionText})
      }},
      { root: true })
    dispatch('realtimeSubscriptions/subscribe',
      {channel: channel, event: 'questionDeleted', callback: (message) => {
        let question = camelcaseKeys(message, {deep: true}).data.question
        commit('removeDeletedSessionQuestion', question)
      }},
      { root: true })
    dispatch('realtimeSubscriptions/subscribe',
      {channel: channel, event: 'questionLikesUpdated', callback: (message) => {
        let question = camelcaseKeys(message, {deep: true}).data.question
        commit('setSessionQusesionLikeCount', {questionId: question.id, likeCount: question.likeCount})
      }},
      { root: true })
  },
  postSessionQuestion ({ commit }, questionParams) {
    return new Promise(resolve => {
      sessionQuestionsApi.postSessionQuestion(questionParams).then(resp => {
        commit('addCreatedQuestionToSessionQuestions', {from: 'api', createdQuestion: camelcaseKeys(resp.question, { deep: true })})
        resolve()
      })
    })
  },
  patchSessionQuestion ({ commit }, {questionId, questionParams}) {
    return new Promise(resolve => {
      sessionQuestionsApi.patchSessionQuestion(questionId, questionParams).then(resp => {
        commit('updateEditedSessionQuestion', camelcaseKeys(resp.question, { deep: true }))
        resolve()
      })
    })
  },
  postSessionQuestionLike ({ commit }, questionId) {
    return new Promise(resolve => {
      sessionQuestionsApi.postSessionQuestionLike(questionId).then(resp => {
        commit('setSessionQusesionLikedStatus', {questionId: questionId, likeStatus: camelcaseKeys(resp.notice, { deep: true })})
        resolve()
      })
    })
  },
  patchSessionQuestionStatusToUserDeleted ({ commit }, {questionId}) {
    return new Promise(resolve => {
      let questionStatusParam = {
        question: {
          question_status: 'user_deleted'
        }
      }
      sessionQuestionsApi.patchSessionQuestionStatus(questionId, questionStatusParam).then(resp => {
        commit('removeDeletedSessionQuestion', camelcaseKeys(resp.question, { deep: true }))
        resolve()
      })
    })
  },
  updateSessionQuestionTypeShownToUser ({ commit }, questionTypeShownToUser) {
    commit('setSessionQuestionTypeShownToUser', questionTypeShownToUser)
  },
  unsubscribeFromSessionQuestions ({ dispatch, rootGetters }) {
    let subs = rootGetters['realtimeSubscriptions/currentSubscriptions'].filter(sub => sub.channel.includes(';questions'))
    subs.forEach(sub => dispatch('realtimeSubscriptions/unsubscribe', {channel: sub.channel}, { root: true }))
  },
  updateQuestionToAsked ({ commit }, questionId) {
    return new Promise(resolve => {
      let questionStatusParam = {
        question: {
          question_status: 'asked'
        }
      }
      sessionQuestionsApi.patchSessionQuestionStatus(questionId, questionStatusParam).then(resp => {
        commit('updateEditedSessionQuestion', camelcaseKeys(resp.question, { deep: true }))
        resolve()
      })
    })
  },
  updateQuestionUndoAsked ({ commit }, questionId) {
    return new Promise(resolve => {
      let questionStatusParam = {
        question: {
          question_status: 'before_asking'
        }
      }
      sessionQuestionsApi.patchSessionQuestionStatus(questionId, questionStatusParam).then(resp => {
        commit('updateEditedSessionQuestion', camelcaseKeys(resp.question, { deep: true }))
        resolve()
      })
    })
  },
}

const mutations = {
  setSessionQuestions (state, sessionQuestions) {
    state.sessionQuestions = sessionQuestions
  },
  setSessionQuestionTypeShownToUser (state, questionTypeShownToUser) {
    state.questionTypeShownToUser = questionTypeShownToUser
  },
  addCreatedQuestionToSessionQuestions (state, {from, createdQuestion}) {
    // make sure to not add an existing question
    let index = state.sessionQuestions.findIndex(question => parseInt(question.id) === createdQuestion.id)
    if (index === -1) {
      //새로운 것
      if (from === 'ably') {
        createdQuestion.me.isMyQuestion = false
        state.sessionQuestions.push(createdQuestion)
      } else {
        state.sessionQuestions.push(createdQuestion)
      }
    } else {
      if (from === 'api') {
        state.sessionQuestions.splice(index, 1, createdQuestion)
      }
    }
  },
  removeDeletedSessionQuestion (state, editedQuestion) {
    let index = state.sessionQuestions.findIndex(question => parseInt(question.id) === editedQuestion.id)
    if (index !== -1) {
      state.sessionQuestions.splice(index, 1)
    }
  },
  ablyUpdateEditedSessionQuestionText (state, {questionId, questionText}) {
    let updatingQuestion = state.sessionQuestions.find(question => parseInt(question.id) === questionId)
    if (updatingQuestion) {
      updatingQuestion.questionText = questionText
    }
  },
  updateEditedSessionQuestion (state, editedQuestion) {
    let index = state.sessionQuestions.findIndex(question => parseInt(question.id) === editedQuestion.id)
    if (index !== -1) {
      state.sessionQuestions.splice(index, 1, editedQuestion)
    }
  },
  setSessionQusesionLikedStatus (state, {questionId, likeStatus}) {
    let updatingQuestion = state.sessionQuestions.find(question => parseInt(question.id) === questionId)
    if (likeStatus === 'liked') {
      updatingQuestion.me.liked = true
      // let the real time update
      // updatingQuestion.likeCount += 1
    } else {
      updatingQuestion.me.liked = false
      // let the real time update
      // updatingQuestion.likeCount -= 1
    }
  },
  setSessionQusesionLikeCount (state, {questionId, likeCount}) {
    let updatingQuestion = state.sessionQuestions.find(question => parseInt(question.id) === questionId)
    if (updatingQuestion) {
      updatingQuestion.likeCount = likeCount
    }
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
