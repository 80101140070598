<template>
  <transition name='fade'>
    <div v-if='showSidepanel'
      class='sidepanel fixed top-0 right-0 h-full bg-white shadow-2xl px-2 lg:px-8 pt-8 lg:pt-8 pb-2 lg:pb-16 text-left z-40 overflow-y-auto'
      :style='panelFullOrHalf'>
      <button
        ref='closeIcon'
        @click='closeActionBySidepanelContent'
        @keydown.esc='closeActionBySidepanelContent'
        class='hover:bg-gray-100 fixed z-20'
        style='top: 2rem; right: 2rem;' >
        <x-icon class='cursor-pointer '/>
      </button>
      <portal-target name='sidepanel-header'></portal-target>
      <slot />
      <component :is='sidepanelContentComponent' />
      <div
        class='fixed bottom-0 right-0 bg-pink-200'
        :style='panelFullOrHalf'>
        <portal-target name='sidepanel-footer'></portal-target>
      </div>
    </div>
  </transition>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import { XIcon } from '@vue-hero-icons/outline'
import SessionFilters        from '@/components/sessions/SessionFilters.vue'
import RegisterNewFormFields from '@/components/registration/RegisterNewFormFields.vue'
import EventBus              from '@/utils/EventBus'

export default {
  name: 'Sidepanel',
  components: {
    SessionFilters,
    RegisterNewFormFields,
    XIcon,
  },
  computed: {
    ...mapState([
      'showSidepanel',
      'sidepanelContentComponent',
    ]),
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    panelFullOrHalf () {
      return (this.isSidepanelFullWidth || this.isMobile) ? 'width:100%;' : 'resize: horizontal; width: 35%; min-width: 320px;'
    },
  },
  watch: {
    showSidepanel: {
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.closeIcon.focus()
          })
        } else {
          EventBus.$emit('sidepanel-closed')
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
    ]),
    closeActionBySidepanelContent () {
      if (this.sidepanelContentComponent === 'register-new-form-fields') {
        EventBus.$emit('check-registration-sidepanel-changes')
      } else {
        this.sidepanelClose()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all .2s;
}

div.fade-enter,
div.fade-leave-to {
  opacity: 0;
  right: -420px;
}
</style>

<style lang='scss'>
// Helper class for content in sidepanel to be flush  
@media (min-width: 1024px) {
  .sidepanel-horizontal-flush {
    margin-left: -4rem;
    margin-right: -4rem;
  }
}
</style>
