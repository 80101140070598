import Vue         from 'vue'
import Vuex        from 'vuex'
import noticesApi  from '@/api/v1/notices'
import isEmpty     from 'lodash/isEmpty'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  notices: [],
  noticesPreivew: [],
  noticeViewMode: true,
  selectedNotice: {}
})

const getters = {
  hasNotices: state => {
    return !isEmpty(state.notices)
  },
  hasSelectedNotice: state => {
    return !isEmpty(state.selectedNotice)
  },
  getField
}

const actions = {
  createNewNotice ({ commit, dispatch }) {
    dispatch('resetSelectedNotice')
    commit('setNoticeViewMode', false)
  },
  getNotices ({commit, rootGetters}) {
    return new Promise(resolve => {
      noticesApi.getNotices(rootGetters['events/showingEventId']).then(resp => {
        commit('setNotices', camelcaseKeys(resp.notices, { deep: true }))
        resolve()
      })
    })
  },
  getNoticesPreview ({commit, rootGetters}) {
    return new Promise(resolve => {
      noticesApi.getNoticesPreview(rootGetters['events/showingEventId']).then(resp => {
        commit('setNoticesPreview', camelcaseKeys(resp.notices, { deep: true }))
        resolve()
      })
    })
  },
  createNotice ({ state, commit, dispatch, rootGetters }) {
    return new Promise(resolve => {
      let params = {
        event_id: rootGetters['events/showingEventId'],
        title:    state.selectedNotice.title,
        contents: state.selectedNotice.contents,
      }
      noticesApi.createNotice(params).then(resp => {
        commit('appendNotice', camelcaseKeys(resp.notice, { deep: true }))
        dispatch('resetSelectedNotice')
        commit('setNoticeViewMode', true)
        resolve(resp.notice)
      })
    })
  },
  editNotice ({ state, commit, dispatch }) {
    return new Promise(resolve => {
      noticesApi.updateNotice(state.selectedNotice, state.selectedNotice.id).then(resp => {
        dispatch('resetSelectedNotice')
        commit('setNoticeViewMode', true)
        resolve(resp.notice)
      })
    })
  },
  deleteNotice ({ commit, dispatch }, noticeId) {
    return new Promise(resolve => {
      noticesApi.deleteNotice(noticeId).then(resp => {
        dispatch('resetSelectedNotice')
        commit('setNoticeViewMode', true)
        commit('removeNotice', noticeId)
        resolve(resp.notice)
      })
    })
  },
  startEditingNotice ({ commit }) {
    commit('setNoticeViewMode', false)
  },
  selectNotice ({ state, commit }, noticeId) {
    commit('setNoticeViewMode', true)
    let found = state.notices.find(notice => notice.id === noticeId)
    if (found) {
      commit('setSelectedNotice', found)
    }
    
  },
  resetSelectedNotice ({ commit }) {
    commit('setSelectedNotice', {})
  },
  defaultSelectNotice ({state, getters, dispatch}) {
    if (getters.hasNotices && !getters.hasSelectedNotice) {
      dispatch('selectNotice', state.notices[0].id) 
    }
    // else do nothing
  }
}

const mutations = {
  setNotices (state, newNotices) {
    state.notices = newNotices.sort((a, b) => a.orderIndex - b.orderIndex)
  },
  setNoticesPreview (state, noticesPreivew) {
    state.noticesPreivew = noticesPreivew.sort((a, b) => a.orderIndex - b.orderIndex)
  },
  setNoticeViewMode (state, boolean) {
    state.noticeViewMode = boolean
  },
  setSelectedNotice (state, notice) {
    state.selectedNotice = notice
  },
  appendNotice (state, createdNotice) {
    state.notices.unshift(createdNotice)
  },
  removeNotice (state, noticeId) {
    let index = state.notices.findIndex(notice => notice.id === noticeId)
    if (index > -1) {
      state.notices.splice(index, 1)
    }
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
