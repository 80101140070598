import { cloneDeep } from 'lodash'

class UserDataHelpers {

  static blankSignupParams () {
    return cloneDeep({
      email: '',
      password: '',
      passwordConfirmation: '',
    })
  }

  static blankProfile () {
    return cloneDeep({
      country: 'KR',
      firstName: '',
      lastName: '',
      title: '',
      organization: '',
      hospitalName: '',
      department: '',
      address: '',
      city: '',
      postalCode: '',
      phoneNumber: '',
      faxNumber:'',
      name: '',
      extraInfo: {
        disclaimer: false,
        iAmADoctor: false,
        medicalProfession: '',
      }
    })
  }

  static blankRegistrationData () {
    return cloneDeep({
    payment: false,
    depositor: "",
    need_score: "",
    paymentGateway: "",
    registrationFee: "",
    registrationType: "",
    marketingChannels: [],
    expectedDeposit_date: "",
    isExerciseProfession: "",
    participatingSessions: [],
    buyPrintedTextbook: "",
    })
  }

  static blankMembershipData () {
    return cloneDeep({
      isKossoascMember: '비회원'
    })
  }
  
  static newAttendee (eventId) {
    if (eventId === 9) {
      return cloneDeep({
        'email': '',
        'registrationProduct': null,
        'profile': {
          'name': '',
          'firstName': '',
          'lastName': '',
          'title': '',
          'country': '',
          'licenseNumber': '',
          'phoneNumber': '',
          'address': '',
          'addressDetail': '',
          'postalCode': '',
          'department': '',
          'hospitalName': '',
          'organization': '',
          'extraInfo': {
            'medicalProfession': '',
          }
        },
        'membershipData': {
          'isKoaMember': 'not_member',
          'isKoaMemberOver65': false,
          'ageType': '', // 
        },
        'registrationData': {
          'payment': false,
          'paidAt': '',
          'paymentGateway': '',
          'depositor': '', // bank transfer 일 때 required
          'expectedDepositDate': '', // bank transfer 일 때 required
          'registrationNumber': '',
          'registrationFee': '',
          'registrationType': '', // nurse, army doctor ... 
          'productId': 0,
          'registeredBy': 0, // registration 한 사람의 user_id
          'participationType': 'Online', // online or offline
          'participantType': '', // chair or speaker or participant
          'nametagPickupLocation': '', 
          'nametagAddress': '' // 명찰수령장소
        },
      })
    } else {
      return cloneDeep({})
    }
  }

  static selectableValues (eventId, field) {
    switch (field) {
      case 'titles':
        return UserDataHelpers.selectableValuesForTitles(eventId)
      case 'medicalProfessionals':
        return UserDataHelpers.selectableMedicalProfessionals(eventId)
      case 'participantTypes':
        return UserDataHelpers.selectableValuesForParticipantTypes(eventId)
      case 'participationTypes':
        return UserDataHelpers.selectableValuesForParticipationTypes(eventId)
      case 'departments':
        return UserDataHelpers.selectableValuesForDepartments(eventId)
      case 'nametagPickupLocation': 
        return UserDataHelpers.selectableValuesForNametagPickupLocations(eventId)
      default:
        return []
    }
  }

  static selectableValuesForTitles (eventId) {
    switch (eventId) {
      case 9:
      default:
        return ['Mr.', 'Ms.', 'Prof.', 'Dr.', 'R.N.']
    }
  }

  static selectableMedicalProfessionals (eventId) {
    switch (eventId) {
      case 9:
      default:
        return [
          {
            value: '전문의',
            fullDescription: 'Physician (전문의)'
          },
          {
            value: '전공의',
            fullDescription: 'Resident (전공의)'
          },
          {
            value: '개원의',
            fullDescription: 'Clinic Owner (개원의)'
          },
          {
            value: '간호사',
            fullDescription: 'Nurse (간호사)'
          },
          {
            value: '연구원',
            fullDescription: 'Reseracher (연구원)'
          },
        ]
    }
  }

  static selectableValuesForParticipantTypes (eventId) {
    switch (eventId) {
      case 9:
      default:
        return [
          {
            value: 'Participant',
            fullDescription: 'Participant'
          },
          {
            value: 'Moderator',
            fullDescription: 'Moderator'
          },
          {
            value: 'Speaker',
            fullDescription: 'Speaker'
          },
        ]
    }
  }

  static selectableValuesForParticipationTypes (eventId) {
    switch (eventId) {
      case 9:
      default:
        return [
          {
            value: 'Online',
            fullDescription: 'Online Only'
          },
        ]
    }
  }

  static selectableValuesForDepartments (eventId) {
    switch (eventId) {
      case 9:
      default:
        return [
          {
            value: 'Orthopaedic',
            fullDescription: 'Orthopaedic'
          },
        ]
    }
  }

  static selectableValuesForNametagPickupLocations (eventId) {
    switch (eventId) {
      case 9:
      default:
        return [
          {
            value: 'onsite',
            fullDescription: '현장수령'
          },
          {
            value: 'home',
            fullDescription: '우편수령'
          },
        ]
    }
  }
}

export default UserDataHelpers
