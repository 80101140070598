import base from './base'

const getAllProducts = () => base
  .get('/products').then(resp => resp.data)

const getProductsIcolaWelcomeReception = () => base
  .get('/products/icola_welcome_reception').then(resp => resp.data)

export default {
  getAllProducts: getAllProducts,
  getProductsIcolaWelcomeReception: getProductsIcolaWelcomeReception,
}
