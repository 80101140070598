import DefaultRoutes     from '@/router/DefaultRoutes'
import Kns2021Routes     from '@/router/Kns2021Routes'
import Sicem2021Routes   from '@/router/Sicem2021Routes'
import KossoascRoutes    from '@/router/KossoascRoutes'
import Gbcc2022Routes    from '@/router/Gbcc2022Routes'
import DrtunesRoutes     from '@/router/DrtunesRoutes'
import Koa1Routes        from '@/router/Koa1Routes'
import Koa2022Routes     from '@/router/Koa2022Routes'
import Kai2022Routes     from '@/router/Kai2022Routes'
import Icorl2022Routes   from '@/router/Icorl2022Routes'
import KhrsVirtualRoutes from '@/router/KhrsVirtualRoutes'
import AbsoluteRoutes    from '@/router/AbsoluteRoutes'
import Kda2022Routes     from '@/router/Kda2022Routes'
import KoaRoutes         from '@/router/KoaRoutes'
import KoaMmRoutes       from '@/router/KoaMmRoutes'
import KihaRoutes        from '@/router/KihaRoutes'
import Thns2022Routes    from '@/router/Thns2022Routes'
import Kns2022Routes     from '@/router/Kns2022Routes'
import Apsr2022Routes    from '@/router/Apsr2022Routes'
import CmccmeRoutes      from '@/router/CmccmeRoutes'
import Gbcc2023Routes    from '@/router/Gbcc2023Routes'
import Icola2023Routes   from '@/router/Icola2023Routes'
import Gbcc2024Routes    from '@/router/Gbcc2024Routes'
import Cmccme2023Routes  from '@/router/Cmccme2023Routes'

import SamsungFireLiveRoutes from '@/router/SamsungFireLiveRoutes'

import JeilpharmRoutes         from '@/router/JeilpharmRoutes' // 20221016. for jeilpharm221027
import Jeilpharm221101Routes   from '@/router/Jeilpharm221101Routes' // 20221016. for jeilpharm221101
import Jeilpharm230418Routes   from '@/router/Jeilpharm230418Routes'
import Jeilpharm230516Routes   from '@/router/Jeilpharm230516Routes'
import Jeilpharm230718Routes   from '@/router/Jeilpharm230718Routes'
import Jeilpharm230720Routes   from '@/router/Jeilpharm230720Routes'
import Jeilpharm230925Routes   from '@/router/Jeilpharm230925Routes'
import Jeilpharm231211Routes   from '@/router/Jeilpharm231211Routes'
import Jeilpharm231221Routes   from '@/router/Jeilpharm231221Routes'

const host = window.location.host.replace('www.','')
const parts = host.split('.')
let router

if (parts[0].includes('kns2021')) {
  router = Kns2021Routes
} else if (parts[0].includes('sicem2021')) {
  router = Sicem2021Routes
} else if (parts[0].includes('drtunes')) {
  router = DrtunesRoutes
} else if (parts[0].includes('kossoasc')) {
  router = KossoascRoutes
} else if (parts[0].includes('gbcc2022')) {
  router = Gbcc2022Routes
} else if (parts[0].includes('koa2022')) {
  router = Koa2022Routes
} else if (parts[0].includes('koa1')) { // since we check for koa2022, we can just check for koa
  router = Koa1Routes
} else if (parts[0].includes('kai-spring2022')) {
  router = Kai2022Routes
} else if (parts[0].includes('icorl2022')) {
  router = Icorl2022Routes
} else if (parts[0].includes('khrs-virtual')) {
  router = KhrsVirtualRoutes
} else if (parts[0].includes('absolute')) {
  router = AbsoluteRoutes
} else if (parts[0].includes('kda2022')) {
  router = Kda2022Routes
} else if (parts[0].includes('samsungfire-live')) {
  router = SamsungFireLiveRoutes
} else if (parts[0].includes('koa-mm')) {
  router = KoaMmRoutes
} else if (parts[0].includes('koa')) {
  router = KoaRoutes
} else if (parts[0].includes('kiha')) {
  router = KihaRoutes
} else if (parts[0].includes('jeilpharm231211')) {
  router = Jeilpharm231211Routes
} else if (parts[0].includes('jeilpharm231221')) {
  router = Jeilpharm231221Routes
} else if (parts[0].includes('jeilpharm230925')) {
  router = Jeilpharm230925Routes
} else if (parts[0].includes('jeilpharm230718')) {
  router = Jeilpharm230718Routes
} else if (parts[0].includes('jeilpharm230720')) {
  router = Jeilpharm230720Routes
} else if (parts[0].includes('jeilpharm230516')) {
  router = Jeilpharm230516Routes
} else if (parts[0].includes('jeilpharm230418')) {
  router = Jeilpharm230418Routes
} else if (parts[0].includes('jeilpharm221101')) {
  router = Jeilpharm221101Routes
} else if (parts[0].includes('jeilpharm')) {
  router = JeilpharmRoutes
} else if (parts[0].includes('thns2022')) {
  router = Thns2022Routes
} else if (parts[0].includes('kns2022')) {
  router = Kns2022Routes
} else if (parts[0].includes('apsr2022-ondemand')) {
  router = Apsr2022Routes
} else if (parts[0].includes('kai2022')) {
  router = Kai2022Routes
} else if (parts[0].includes('cmccme2023')) {
  router = Cmccme2023Routes
} else if (parts[0].includes('cmccme')) {
  router = CmccmeRoutes
} else if (parts[0].includes('gbcc-onsite')) {
  router = Gbcc2023Routes
} else if (parts[0].includes('icola2023')) {
  router = Icola2023Routes
} else if (parts[0].includes('onsite')) {
  router = Gbcc2024Routes
}else {
  router = DefaultRoutes
}

export default router
