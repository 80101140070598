<template>
  <div class='flex flex-row lg:justify-between gap-x-4'>
    <div class='font-medium uppercase text-gray-600'>
      {{role}}
    </div>
    <div class='flex flex-row lg:flex-col gap-y-2 gap-x-4'>
      <div v-for='(organizer, index) in organizersByRole'
           :key='`${sessionId}-organizer-${organizer.name}-${index}`'>
        <div class='font-medium'>{{organizer.name}}</div>
        <div class='text-xs whitespace-pre-line'>{{organizer.affiliation}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SessionDetailsOrganizers',
  props: [
    'organizersByRole',
    'role',
    'sessionId',
  ],
}
</script>

