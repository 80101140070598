import base from './base'

const getEventMeta = (eventId) => {
  return base.get(`/event_meta`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp.data)
}


export default {
  getEventMeta: getEventMeta,
}
