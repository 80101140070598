import Vue                       from 'vue'
import Vuex                      from 'vuex'
import iamportPaymentApi         from '@/api/v1/iamport-payments'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')

const state = () => ({

})

const getters = {
  getField
}

const actions = {
  requestIamportPaymentOnSuccess ({ commit }, orderParams) {
    return new Promise(resolve => {
      iamportPaymentApi.paymentSuccess(orderParams).then(resp => {
        commit('payments/setCurrentOrder', camelcaseKeys(resp.order, {deep: true}), {root: true})
        resolve()
      })
    })
  },
}

const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
