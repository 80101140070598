import Vue from 'vue'
import Vuex from 'vuex'
import contentsApi from '@/api/v1/contents'
import isEmpty     from 'lodash/isEmpty'

import { getField, updateField } from 'vuex-map-fields'
Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

const state = () => ({
  selectedContent: {},
  uploadFormData: new FormData(),
})

// getters
const getters = {
  hasSelectedContent (state) {
    return !isEmpty(state.selectedContent)
  },
  selectedContentSourceId (state, getters) {
    if (getters.hasSelectedContent) {
      let sourceUrlArray = state.selectedContent.data.sourceUrl.split('/')
      return sourceUrlArray[sourceUrlArray.length - 1]
    } else {
      return ''
    }
  },
  getField,
}
// actions
const actions = {
  getSingleContent ({ commit }, contentId) {
    return new Promise(resolve => {
      contentsApi.getSingleContent(contentId).then(resp => {
        commit('setSelectedContent', camelcaseKeys(resp.content, {deep: true}))
      })
      resolve()
    })
  },
  getLiveContentVideoUrl (_, contentSourceId) {
    return new Promise((resolve, reject) => {
      contentsApi.getLiveContentVideoUrl(contentSourceId).then(resp => {
        resolve(resp.streamable_clip.link)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getContentHlsUrl (_, contentId) {
    return new Promise((resolve, reject) => {
      contentsApi.getContentHlsUrl(contentId).then(resp => {
        let hlsUrl = camelcaseKeys(resp.hls_url, {deep: true})
        resolve(hlsUrl)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getSocialMediaStyleContents ({rootGetters}) {
    return new Promise((resolve, reject) => {
      contentsApi.getSocialMediaStyleContents(rootGetters['events/showingEventId']).then(resp => {
        let contents = camelcaseKeys(resp.content_list, {deep: true})
        resolve(contents)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateSocialMediaStyleContentLikes (_, params) {
    return new Promise((resolve, reject) => {
      contentsApi.updateSocialMediaStyleContentLikes(params.contentId, params.profileId, params.likeStatus).then(resp => {
        let content = camelcaseKeys(resp, {deep: true})
        resolve(content)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateSocialMediaStyleContentComments (_, params) {
    return new Promise((resolve, reject) => {
      contentsApi.updateSocialMediaStyleContentComments(params.contentId, params.comment).then(resp => {
        let content = camelcaseKeys(resp, {deep: true})
        resolve(content)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteSocailMediaStyleContent (_, contentId) {
    return new Promise((resolve, reject) => {
      contentsApi.deleteSocialMediaStyleContent(contentId).then(resp => {
        resolve(resp)
      }).catch(error => {
        reject(error)
      })
    })
  },
  uploadParticipantImageContent ({ state, dispatch, rootGetters }, contentParams) {
    return new Promise(resolve => {
      const snakeCasedContentParams = snakecaseKeys(contentParams, { deep: true })
      contentsApi.postSocialMediaStyleContent(rootGetters['events/showingEventId'], snakeCasedContentParams).then(response => {
        contentsApi.updateSocialMediaStyleContentImage(response.content.id, state.uploadFormData).then(imageUploadResponse => {
          let uploadedContent = camelcaseKeys(imageUploadResponse.content, { deep: true })
          dispatch('resetUploadFormData')
          resolve(uploadedContent)
        })
      })
    })
  },
  resetUploadFormData ({ commit }) {
    commit('setUploadFormData', new FormData())
  },
}

// mutations
const mutations = {
  setSelectedContent (state, fromApi) {
    state.selectedContent = fromApi
  },
  setUploadFormData (state, formData) {
    state.uploadFormData = formData
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
