import Vue                       from 'vue'
import Vuex                      from 'vuex'
import ZandiWebhooksApi            from '@/api/v1/zandi-webhooks'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const snakecaseKeys = require('snakecase-keys')

const state = () => ({

})

const getters = {
  getField
}

const actions = {
  sendZandiWebhookMessage (context, messageParams) {
    return new Promise(resolve => {
      ZandiWebhooksApi.sendMessage(snakecaseKeys(messageParams, {deep: true})).then(() => {
        resolve()
      })
    })
  },
}

const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
