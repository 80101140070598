import Vue                       from 'vue'
import Vuex                      from 'vuex'
import PaymentApi                from '@/api/v1/payments'
import isEmpty from 'lodash/isEmpty'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  currentOrder: {}
})

const getters = {
  hasCurrentOrder: (state) => {
    return !isEmpty(state.currentOrder)
  },
  isCurrentOrderDone: (state, getters) => {
    return getters.hasCurrentOrder && state.currentOrder.status === 'Done'
  },
  getField
}

const actions = {
  checkPaymentStatus ({ commit }, orderId) {
    return new Promise(resolve => {
      PaymentApi.paymentStatusCheck(orderId).then(resp => {
        commit('setCurrentOrder', camelcaseKeys(resp.order, {deep: true}))
        resolve()
      })
    })
  },
  resetCurrentOrder ({commit}) {
    commit('setCurrentOrder', {})
  }
}

const mutations = {
  setCurrentOrder (state, currentOrder) {
    state.currentOrder = currentOrder
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
