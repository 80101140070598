import base from '../base'

const onSiteSignup = (onSiteSignupParams) => {
  return base.post(`/users/on_site_signup`, onSiteSignupParams).then(resp => resp)
}

const signup = (params) => {
  return base.post('/users/signup', params).then(resp => resp.data)
}

const registerAttendee = (eventId, params) => {
  return base.post(`/users/register_attendee?event_id=${eventId}`, params).then(resp => resp.data)
}

const registerMyself = (eventId, params) => {
  return base.post(`/users/register_myself?event_id=${eventId}`, params).then(resp => resp.data)
}

const getRegisteredByMe = (params) => {
  return base.get('/users/me/registered_by', {'params': params}).then(resp => resp.data)
}

const getRegisteredByLicenseAndName = (eventId, params) => {
  return base.get(`/users/registered_by_license_number_and_name?event_id=${eventId}`, {'params': params}).then(resp => resp.data)
}

const registrationDataBatchUpdate = (eventId, params) => {
  return base.patch(`/users/registration_data_batch_update?event_id=${eventId}`, params).then(resp => resp.data)
}

const registrationDataReset = (eventId, params) => {
  return base.patch(`/users/registration_data_reset?event_id=${eventId}`, params).then(resp => resp.data)
}

const getIsAlreadyRegistered = (eventId, params) => {
  return base.get(`/users/is_already_registered?event_id=${eventId}`, {'params': params}).then(resp => resp.data)
}

const getPaidRegistrationDetails = (eventId, params) => {
  return base.get(`/users/get_paid_registration_details?event_id=${eventId}`, {'params': params}).then(resp => resp.data)
}

const getPaidRegistrationDetailsForMe = (eventId) => {
  return base.get(`/users/me/get_paid_registration_details?event_id=${eventId}`).then(resp => resp.data)
}

const getPaidRegistrationDetailsGroup = (eventId, params) => {
  return base.get(`/users/registered_by_registration_number?event_id=${eventId}`, {'params': params}).then(resp => resp.data)
}

const sendRegistrationEmails = (eventId, params) => {
  return base.post(`/users/send_registration_emails?event_id=${eventId}`, params).then(resp => resp.data)
}

const getRegisteringAttendeesByOrderId = (eventId, params) => {
  return base.get(`/users/registering_attendees_by_order_id?event_id=${eventId}`, {'params': params}).then(resp => resp.data)
}

const getIcolaWelcomeReceptionAttendeesCount = (eventId) => {
  return base.get(`/users/sso_sessions/icola_welcome_reception_attendees_count?event_id=${eventId}`).then(resp => resp.data)
}

const registerIcolaWeclomeReceptionByUserEmail = (eventId, params) => {
  return base.patch(`/users/register_icola_weclome_reception_by_user_email?event_id=${eventId}`, params).then(resp => resp.data)
}

const patchRegistrationDataByUserEmail = (eventId, params) => {
  return base.patch(`/users/registration_data_by_email?event_id=${eventId}`, params).then(resp => resp.data)
}

const getRegistrationDataByUserEmail = (eventId, params) => {
  return base.get(`/users/registration_data_by_email?event_id=${eventId}`,  {'params': params}).then(resp => resp.data)
}

const sendRegistrationEmailsByUserEmail = (eventId, params) => {
  return base.post(`/users/send_registration_emails_by_user_email?event_id=${eventId}`, params).then(resp => resp.data)
}

export default {
  onSiteSignup:                  onSiteSignup,
  signup:                        signup,
  registerAttendee:              registerAttendee,
  registerMyself:                registerMyself,
  getRegisteredByMe:             getRegisteredByMe,
  getRegisteredByLicenseAndName: getRegisteredByLicenseAndName,
  registrationDataBatchUpdate:   registrationDataBatchUpdate,
  registrationDataReset:         registrationDataReset,
  getIsAlreadyRegistered:        getIsAlreadyRegistered,
  getPaidRegistrationDetails:    getPaidRegistrationDetails,
  getPaidRegistrationDetailsForMe: getPaidRegistrationDetailsForMe,
  getPaidRegistrationDetailsGroup: getPaidRegistrationDetailsGroup,
  sendRegistrationEmails:        sendRegistrationEmails,
  getRegisteringAttendeesByOrderId: getRegisteringAttendeesByOrderId,
  getIcolaWelcomeReceptionAttendeesCount: getIcolaWelcomeReceptionAttendeesCount,
  registerIcolaWeclomeReceptionByUserEmail: registerIcolaWeclomeReceptionByUserEmail,
  patchRegistrationDataByUserEmail: patchRegistrationDataByUserEmail,
  getRegistrationDataByUserEmail: getRegistrationDataByUserEmail,
  sendRegistrationEmailsByUserEmail: sendRegistrationEmailsByUserEmail,
}
