import Vue                          from 'vue'
import VueRouter                    from 'vue-router'

const Home                         = () => import('@/views/demo/Home.vue')
const Main                         = () => import('@/views/demo/Main.vue')
const Programs                     = () => import('@/views/demo/Programs.vue')
const Session                      = () => import('@/views/demo/Session.vue')
const Posters                      = () => import('@/views/demo/Posters.vue')
const Poster                       = () => import('@/views/demo/Poster.vue')
const Vods                         = () => import('@/views/demo/Vods.vue')
const Vod                          = () => import('@/views/demo/Vod.vue')
const Sponsors                     = () => import('@/views/demo/Sponsors.vue')
const Sponsor                      = () => import('@/views/demo/Sponsor.vue')
const Speakers                     = () => import('@/views/demo/Speakers.vue')
const Live                         = () => import('@/views/demo/Live.vue')
const Login                        = () => import('@/views/demo/Login.vue')
const MyPage                       = () => import('@/views/demo/MyPage.vue')
const PosterUpload                 = () => import('@/views/demo/PosterUpload.vue')
const PosterUploadCompleted        = () => import('@/views/demo/PosterUploadCompleted.vue')
const Signup                       = () => import('@/views/demo/Signup.vue')
const Notices                      = () => import('@/views/demo/Notices.vue')
const Qna                          = () => import('@/views/demo/Qna.vue')
const ProgramDetails               = () => import('@/views/demo/ProgramDetails.vue')
const Welcome                      = () => import('@/views/demo/Welcome.vue')


const ExhibitionHall               = () => import('@/views/ksso2022/ExhibitionHall.vue')
const LobbyHall                    = () => import('@/views/ksso2022/LobbyHall.vue')

const TossPayment                  = () => import('@/components/toss-payment/TossPayment.vue')
const PaymentSuccess               = () => import('@/components/payments/PaymentSuccess.vue')
const PaymentFail                  = () => import('@/components/payments/PaymentFail.vue')
const PaymentFinished              = () => import('@/components/payments/PaymentFinished.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Main'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/live',
    name: 'Live',
    component: Live
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
  },
  {
    path: '/main',
    name: 'main',
    component: Main
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      defaultToToday: true,
    }
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/posters',
    name: 'Posters',
    component: Posters
  },
  {
    path: '/poster',
    name: 'Poster',
    component: Poster
  },
  {
    path: '/vods',
    name: 'Vods',
    component: Vods
  },
  {
    path: '/vod',
    name: 'Vod',
    component: Vod
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: Sponsors
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: Sponsor
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage,
  },
  {
    path: '/poster-upload',
    name: 'PosterUpload',
    component: PosterUpload
  },
  {
    path: '/poster-upload-completed',
    name: 'PosterUploadCompleted',
    component: PosterUploadCompleted
  },
  {
    path: '/qna',
    name: 'Qna',
    component: Qna
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices
  },
  {
    path: '/exhibition-hall',
    name: 'ExhibitionHall',
    component: ExhibitionHall
  },
  {
    path: '/lobby-hall',
    name: 'LobbyHall',
    component: LobbyHall
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: ProgramDetails
  },
  {
    path: '/toss-payment',
    name: 'TossPayment',
    component: TossPayment
  },
  {
    path: '/payments/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/payments/fail',
    name: 'PaymentFail',
    component: PaymentFail
  },
  {
    path: '/payments/finished',
    name: 'PaymentFinished',
    component: PaymentFinished
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
