import Vue                          from 'vue'
import VueRouter                    from 'vue-router'
const TossPayment           = () => import('@/components/toss-payment/TossPayment.vue')
const PaymentSuccess        = () => import('@/components/payments/PaymentSuccess.vue')
const PaymentFail           = () => import('@/components/payments/PaymentFail.vue')
const PaymentFinished       = () => import('@/components/payments/PaymentFinished.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/toss-payment',
    name: 'TossPayment',
    component: TossPayment
  },
  {
    path: '/payments/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/payments/fail',
    name: 'PaymentFail',
    component: PaymentFail
  },
  {
    path: '/payments/finished',
    name: 'PaymentFinished',
    component: PaymentFinished
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
