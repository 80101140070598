import Vue from 'vue'
import Vuex from 'vuex'
import sessionVotingApi from '@/api/v1/session-voting'
import { getField, updateField } from 'vuex-map-fields'
Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

const state = () => ({
  sessionVotingQuestions: [],
  selectedVotingQuestion: null,
})

const getters = {
  hasVotingQuestions: (state) => {
    return state.sessionVotingQuestions.length > 0
  },
  // hasSelectedSponsor: (state) => {
  //   return !isEmpty(state.selectedSponsor)
  // },
  getField,
}

const actions = {
  getSessionVotingQuestions ({ commit, rootGetters }) {
    return new Promise(resolve => {
      sessionVotingApi.getSessionVotingQuestions(rootGetters['events/showingEventId']).then(resp => {
        commit('setSessionVotingQuestions', camelcaseKeys(resp.list_of_session_votings.sort((a, b) => new Date(a.date) - new Date(b.date)), { deep: true }))
        resolve()
      })
    })
  },
  getSessionVotingQuestionData ({ commit, rootGetters }, questionId) {
    return new Promise(resolve => {
      sessionVotingApi.getSessionVotingQuestionData(rootGetters['events/showingEventId'], questionId).then(resp => {
        commit('setSelectedVotingQuestionData', camelcaseKeys(resp.session_voting, {deep: true}))
        resolve(resp)
      })
    })
  },
  getSessionVotingQuestionDataComponentStorage ({ rootGetters }, questionId) {
    return new Promise(resolve => {
      sessionVotingApi.getSessionVotingQuestionData(rootGetters['events/showingEventId'], questionId).then(resp => {
        resolve(camelcaseKeys(resp.session_voting, {deep: true}))
      })
    })
  },
  getSingleSessionVotingQuestions ({ rootGetters }, sessionId) {
    return new Promise(resolve => {
      sessionVotingApi.getSingleSessionVotingQuestions(rootGetters['events/showingEventId'], sessionId).then(resp => {
        resolve(camelcaseKeys(resp.voting_questions, {deep: true}))
      })
    })
  },
  postSessionVotingResult (_, sessionVotingResultParams) {
    return new Promise((resolve, reject)  => {
      sessionVotingApi.postSessionVotingResult(snakecaseKeys(sessionVotingResultParams))
        .then(resp => { resolve(resp)})
        .catch(err => { reject(err.response)})
    })
  },
}

const mutations = {
  setSessionVotingQuestions (state, sessionVotingQuestions) {
    state.sessionVotingQuestions = sessionVotingQuestions
  },
  setSelectedVotingQuestionData (state, selectedVotingQuestion) {
    state.selectedVotingQuestion = selectedVotingQuestion
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
