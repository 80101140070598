import Vue                   from 'vue'
import Vuex                  from 'vuex'
import abstracts             from './modules/abstracts'
import sessions              from './modules/sessions'
import contents              from './modules/contents'
import posters               from './modules/posters'
import vods                  from './modules/vods'
import speakers              from './modules/speakers'
import filters               from './modules/filters'
import sponsors              from './modules/sponsors'
import sessionNotices        from './modules/session-notices'
import sessionQuestions      from './modules/session-questions'
import sessionQuizzes        from './modules/session-quizzes'
import sessionVoting         from './modules/session-voting'
import events                from './modules/events'
import users                 from './modules/users'
import realtimeSubscriptions from './modules/realtime-subscriptions'
import watchedVideos         from './modules/watched-videos'
import myPages               from './modules/my-pages'
import notices               from './modules/notices'
import comments              from './modules/comments'
import payments              from './modules/payments'
import iamportPayments       from './modules/iamport-payments'
import tossPayments          from './modules/toss-payments'
import userActions           from './modules/user-actions'
import products              from './modules/products'
import orders                from './modules/orders'
import registrations         from './modules/registrations'
import nhnkcpPayments        from './modules/nhnkcp-payments'
import registrationFeeDiscountCoupons from './modules/registration-fee-discount-coupons'
import zandiWebhooks         from './modules/zandi-webhooks'

import cloneDeep from 'lodash/cloneDeep'

Vue.use(Vuex)

const vm = new Vue()

const state = () => ({
  showingFullpageLoadingIndicator: false,
  showWelcomeImage: true,
  showSidepanel: false,
  sidepanelContentComponent: '',
  isFullscreenVideo: false,
  detailsModal: {
    detailsComponent: 'div',
    detailsItemId: 0,
    maxWidthPixels: 560,
    height: 'auto',
    width: '100%',
    shiftX: 0.5,
    shifty: 0.5,
  }
})

const getters = {
  isMobile () {
    return window.innerWidth < 1024
  }
}

const actions = {
  hideFullpageLoadingIndicator ({commit}) {
    commit('setShowFullpageLoadingIndicator', false)
  },
  showFullpageLoadingIndicator ({commit}) {
    commit('setShowFullpageLoadingIndicator', true)
  },
  hideShowWelcomeImage ({ commit }) {
    commit('setShowWelcomeImage', false)
  },
  showWelcomeImage ({ commit }) {
    commit('setShowWelcomeImage', true)
  },
  resetSidepanelContentComponent ({ commit }) {
    commit('setSidepanelContentComponent', '')
  },
  sidepanelClose ({ commit, dispatch }) {
    dispatch('resetSidepanelContentComponent')
    commit('setShowSidepanel', false)
  },
  sidepanelOpen ({ commit, state }, params) {
    commit('setSidepanelContentComponent', params.componentName)
    if (!state.showSidepanel) {
      commit('setShowSidepanel', true)
    }
  },
  openProgramSessionDetailsModal ({ commit }, sessionId) {
    commit('setDetailsModal', cloneDeep({
      detailsComponent: 'program-session-details',
      detailsItemId:    sessionId,
      maxWidthPixels:   1000,
      height: 'auto',
      width: '100%',
    }))
    vm.$modal.show('details-modal')
  },
  openSpeakerDetailsModal ({ commit }, speakerId) {
    commit('setDetailsModal', cloneDeep({
      detailsComponent: 'speaker-details',
      detailsItemId:    speakerId,
      height:           720,
      width:            600,
    }))
    vm.$modal.show('details-modal')
  },
}

const mutations = {
  setShowFullpageLoadingIndicator (state, showBoolean) {
    state.showingFullpageLoadingIndicator = showBoolean
  },
  setShowWelcomeImage (state, showBoolean) {
    state.showWelcomeImage = showBoolean
  },
  setShowSidepanel (state, openSidepanel) {
    state.showSidepanel = openSidepanel
  },
  setSidepanelContentComponent (state, componentName) {
    state.sidepanelContentComponent = componentName
  },
  setIsFullscreenVideo (state, boolean) {
    state.isFullscreenVideo = boolean
  },
  setDetailsModal (state, modalDetails) {
    state.detailsModal = modalDetails
  },
}

export default new Vuex.Store({
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    abstracts,
    sessions,
    contents,
    posters,
    vods,
    speakers,
    filters,
    sponsors,
    sessionNotices,
    sessionQuestions,
    sessionQuizzes,
    sessionVoting,
    events,
    users,
    watchedVideos,
    realtimeSubscriptions,
    myPages,
    notices,
    comments,
    payments,
    iamportPayments,
    tossPayments,
    userActions,
    products,
    orders,
    registrations,
    nhnkcpPayments,
    registrationFeeDiscountCoupons,
    zandiWebhooks,
  },
})
