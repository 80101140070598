import Vue from 'vue'
import Vuex from 'vuex'
import myPagesApi from '../../../src/api/v1/my-pages'
import isEmpty     from 'lodash/isEmpty'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  myPageData: {},
})

const getters = {
  hasMyPageData (state) {
    return !isEmpty(state.myPageData)
  },
  myPageMySchedules: (state, getters) => {
    // for speakers, moderators
    return getters.hasMyPageData ? state.myPageData.mySchedules : []
  },
  myPageDataProfile: (state, getters) => {
    return getters.hasMyPageData ? state.myPageData.profile : {}
  },
  myPageDataWatchedVideos: (state, getters) => {
    return getters.hasMyPageData ? state.myPageData.watchedVideos : []
  },
  myDataByDay: (state, getters) => {
    let tmp = {}
    getters.myPageDataWatchedVideos.forEach(video => {
      let date = dayjs(video.scheduledStartTime).tz('Asia/Seoul').format('YYYY-MM-DD')
      if (!tmp[date]) {
        tmp[date] = []
      }
      tmp[date].push(video)
    })
    return tmp
  },
  myPageDataWatchedVideosCount:  (state, getters) => {
    return getters.myPageDataWatchedVideos.length
  },
  myPageDataStampedSponsors: (state, getters) => {
    return getters.hasMyPageData ? state.myPageData.stampedSponsors : []
  },
  myPageDataStampedSponsorsCount:  (state, getters) => {
    return getters.myPageDataStampedSponsors.map((level) => level.listOfSponsors).flat().filter((sponsor) => sponsor.stamped).length
  },
  myPageDataQuizSponsors: (state, getters) => {
    return getters.hasMyPageData ? state.myPageData.quizSponsors : []
  },
  myPageDataQuizCompletedSponsorsCount:  (state, getters) => {
    return getters.myPageDataQuizSponsors.map((level) => level.listOfSponsors).flat().filter((sponsor) => sponsor.quizCompleted).length
  },
  myPageDataShowDownloadCertificates: (state, getters) => {
    return getters.hasMyPageData ? state.myPageData.showDownloadCertificates : false
  },
}

const actions = {
  getMyPageData ({ commit, rootGetters }) {
    return new Promise(resolve => {
      myPagesApi.getMyPageData(rootGetters['events/showingEventId']).then(resp => {
        commit('setMyPageData', camelcaseKeys(resp.my_page, {deep: true}))
        resolve()
      })
    })
  },
}

const mutations = {
  setMyPageData (state, fromApi) {
    state.myPageData = fromApi
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
