import base from './base'

const useCouponByCode = (eventId, code) => {
  return base.patch(`/registration_fee_discount_coupons/${code}/use`, {event_id: eventId}).then(resp => resp.data)
}

const getSingleCouponByCode = (eventId, code) => {
  return base.get(`/registration_fee_discount_coupons/code/${code}`, {params: {event_id: eventId}}).then(resp => resp.data)
}

export default {
  useCouponByCode:       useCouponByCode,
  getSingleCouponByCode: getSingleCouponByCode
}
