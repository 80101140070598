import Vue        from 'vue'
import Vuex       from 'vuex'
import speakerApi from '@/api/v1/speakers'
import isEmpty    from 'lodash/isEmpty'


Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  speakers: [],
  selectedSpeakerFilters: [],
  speakersInSessionGroup: [],
  selectedSpeaker: {},
})

const getters = {
  speakersInternational: (state, getters) => {
    return getters.filteredSpeakers.filter(speaker => speaker.country && !speaker.country.toLowerCase().includes('korea'))
  },
  speakersDomestic: (state, getters) => {
    return getters.filteredSpeakers.filter(speaker => !speaker.country || speaker.country.toLowerCase().includes('korea'))
  },
  hasSelectedFilters: (state) => {
    return !isEmpty(state.selectedSpeakerFilters)
  },
  emptySpeakerFilters: (state) => {
    return state.selectedSpeakerFilters.every(filter => filter.list.length === 0)
  },
  selectedFilterNames: (state, getters) => {
    return getters.hasSelectedFilters ? state.selectedSpeakerFilters.map(filter => filter.name) : []
  },
  isMatchedFilters: (state, getters) => (speakerFilterTags) => {
    return getters.selectedFilterNames.every(filterCategoryName => {
      if (speakerFilterTags.length === 0) {
        return false
      }
      let foundCategoryInSpeaker = speakerFilterTags.find(tag => tag.name === filterCategoryName)
      if (!foundCategoryInSpeaker) {
        return false
      }
      let speakerTagValues = foundCategoryInSpeaker.list
      let selectedTagValues = state.selectedSpeakerFilters.find(filter => filter.name === filterCategoryName).list
      return speakerTagValues.some(tag => selectedTagValues.includes(tag))
    })
  },
  filteredSpeakers: (state, getters) => {
    return state.speakers.filter(speaker => getters.isMatchedFilters(speaker.filterTags) || getters.emptySpeakerFilters)
  },
}

const actions = {
  getSpeakers ({commit, rootGetters}) {
    return new Promise(resolve => {
      speakerApi.getSpeakers(rootGetters['events/showingEventId']).then(resp => {
        commit('setSpeakers', camelcaseKeys(resp.speaker_list, {deep: true}))
        resolve()
      })
    })
  },
  getMainSpeakers ({rootGetters}) {
    return new Promise(resolve => {
      speakerApi.getMainSpeakers(rootGetters['events/showingEventId']).then(resp => {
        resolve(camelcaseKeys(resp.speaker_list, {deep: true}))
      })
    })
  },
  getSpeakersInSessionGroups ({commit, rootGetters}) {
    return new Promise(resolve => {
      speakerApi.getSpeakersInSessionGroups(rootGetters['events/showingEventId']).then(resp => {
        commit('setSpeakersInSessionGroup', camelcaseKeys(resp.speaker_list, {deep: true}))
        resolve()
      })
    })
  },
  getSpeakerDetails ({ rootGetters }, speakerId) {
    return new Promise(resolve => {
      speakerApi.getSingleSpeaker(speakerId, rootGetters['events/showingEventId']).then(resp => {
        resolve(camelcaseKeys(resp.speaker, { deep: true}))
      })
    })
  },
}

const mutations = {
  setSpeakersInSessionGroup (state, speakers) {
    state.speakersInSessionGroup = speakers
  },
  setSpeakers (state, speakers) {
    state.speakers = speakers
  },
  setSelectedSpeaker (state, speaker) {
    state.selectedSpeaker = speaker
  },
  updateSpeakerFilter (state, {name, list}) {
    let index = state.selectedSpeakerFilters.findIndex(filter => filter.name === name)
    if (isEmpty(list)) {
      if (index !== -1) {
        state.selectedSpeakerFilters.splice(index, 1)
      }
    } else {
      if (index !== -1) {
        state.selectedSpeakerFilters[index].list = list
      } else {
        state.selectedSpeakerFilters.push({name: name, list: list})
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
