import Vue                               from 'vue'
import VueRouter                         from 'vue-router'

const AbstractSubmissions              = () => import('@/views/demo/AbstractSubmissions.vue')
const AbstractSubmission               = () => import('@/views/demo/AbstractSubmission.vue')
const AbstractSubmissionGuideline      = () => import('@/views/demo/AbstractSubmissionGuideline.vue')
const AbstractMaterialUpload           = () => import('@/views/demo/AbstractMaterialUpload.vue')
const AbstractSubmissionsOfMySessions  = () => import('@/views/demo/AbstractSubmissionsOfMySessions.vue')
const Home                             = () => import('@/views/demo/Home.vue')
const Login                            = () => import('@/views/demo/Login.vue')
const MyPage                           = () => import('@/views/demo/MyPage.vue')
const Notices                          = () => import('@/views/demo/Notices.vue')
const Posters                          = () => import('@/views/demo/Posters.vue')
const Poster                           = () => import('@/views/demo/Poster.vue')
const PosterUpload                     = () => import('@/views/demo/PosterUpload.vue')
const PosterUploadCompleted            = () => import('@/views/demo/PosterUploadCompleted.vue')
const Programs                         = () => import('@/views/demo/Programs.vue')
const ProgramDetails                   = () => import('@/views/demo/ProgramDetails.vue')
const Signup                           = () => import('@/views/demo/Signup.vue')
const Sponsor                          = () => import('@/views/demo/Sponsor.vue')
const Live                             = () => import('@/views/demo/Live.vue')
const PhotoGallery                     = () => import('@/views/demo/PhotoGallery.vue')

const IamportPayment                   = () => import('@/components/iamport-payment/IamportPayment.vue')
const PaymentTest                      = () => import('@/components/payments/PaymentTest.vue')
const PaymentSuccess                   = () => import('@/components/payments/PaymentSuccess.vue')
const PaymentFail                      = () => import('@/components/payments/PaymentFail.vue')
const PaymentFinished                  = () => import('@/components/payments/PaymentFinished.vue')

const IamportRegistrationSuccessMobile = () => import('@/components/iamport-payment/IamportRegistrationSuccessMobile.vue')
const IamportRegistrationFinishedMobile= () => import('@/components/iamport-payment/IamportRegistrationFinishedMobile.vue')
const IamportRegistrationFailMoblie    = () => import('@/components/iamport-payment/IamportRegistrationFailMoblie.vue')

const Registration                     = () => import('@/views/koa2022/Registration.vue')
const Sponsors                         = () => import('@/views/koa2022/Sponsors.vue')
const AboutKorea                       = () => import('@/views/koa2022/AboutKorea.vue')
const EventLocation                    = () => import('@/views/koa2022/EventLocation.vue')
const Main                             = () => import('@/views/koa2022/Main.vue')
const MeetingInformation               = () => import('@/views/koa2022/MeetingInformation.vue')
const Organization                     = () => import('@/views/koa2022/Organization.vue')
const Session                          = () => import('@/views/koa2022/Session.vue')
const UsefulLinks                      = () => import('@/views/koa2022/UsefulLinks.vue')
const Welcome                          = () => import('@/views/koa2022/Welcome.vue')
const Speakers                         = () => import('@/views/koa2022/Speakers.vue')
const Sponsorship                      = () => import('@/views/koa2022/Sponsorship.vue')
const ProgramAtAGlance                 = () => import('@/views/koa2022/ProgramAtAGlance.vue')
const Accommodations                   = () => import('@/views/koa2022/Accommodations.vue')
const RegistrationGuideline            = () => import('@/views/koa2022/RegistrationGuideline.vue')
const PresentationGuideline            = () => import('@/views/koa2022/PresentationGuideline.vue')

const ReceiptDownload                  = () => import('@/views/koa2022/ReceiptDownload.vue')
const ReceiptDownloadFileSingle        = () => import('@/views/koa2022/Koa2022ReceiptSingleFile.vue')
const ReceiptDownloadFileGroup         = () => import('@/views/koa2022/Koa2022ReceiptGroupFile.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Main' 
    }
  },
  {
    path: '/accommodations',
    name: 'Accommodations',
    component: Accommodations
  },
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/program-at-a-glance',
    name: 'ProgramAtAGlance',
    component: ProgramAtAGlance,
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      defaultToToday: true,
      showScheduleFullWidth: true,
    }
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: ProgramDetails
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices
  },
  {
    path: '/poster-upload',
    name: 'PosterUpload',
    component: PosterUpload
  },
  {
    path: '/poster-upload-completed',
    name: 'PosterUploadCompleted',
    component: PosterUploadCompleted
  },
  {
    path: '/abstract-submission',
    name: 'AbstractSubmission',
    component: AbstractSubmission
  },
  {
    path: '/abstract-submissions',
    name: 'AbstractSubmissions',
    component: AbstractSubmissions
  },
  {
    path: '/abstract-submission-guideline',
    name: 'AbstractSubmissionGuideline',
    component: AbstractSubmissionGuideline
  },
  {
    path: '/abstract-material-upload',
    name: 'AbstractMaterialUpload',
    component: AbstractMaterialUpload
  },
  {
    path: '/abstract-submissions-of-my-sessions',
    name: 'AbstractSubmissionsOfMySessions',
    component: AbstractSubmissionsOfMySessions
  },
  {
    path: '/presentation-guideline',
    name: 'PresentationGuideline',
    component: PresentationGuideline
  },
  {
    path: '/receipt-download',
    name: 'ReceiptDownload',
    component: ReceiptDownload
  },
  {
    path: '/receipt-download-file',
    name: 'ReceiptDownloadFile',
    component: ReceiptDownloadFileSingle
  },
  {
    path: '/receipt-download-group-file',
    name: 'ReceiptDownloadFileGroup',
    component: ReceiptDownloadFileGroup
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/registration-guideline',
    name: 'RegistrationGuideline',
    component: RegistrationGuideline
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: Sponsors
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: Sponsor
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage,
    props: {
      includeProfile: false,
      includeCertificates: false,
      includeScore: true,
      includeWatchedTime: false,
      includeStamp: false,
      includeQuiz: false
    }
  },
  {
    path: '/posters',
    name: 'Posters',
    component: Posters
  },
  {
    path: '/poster',
    name: 'Poster',
    component: Poster
  },
  {
    path: '/organization',
    name: 'Organization',
    component: Organization
  },
  {
    path: '/meeting-information',
    name: 'MeetingInformation',
    component: MeetingInformation
  },
  {
    path: '/location',
    name: 'EventLocation',
    component: EventLocation
  },
  {
    path: '/about-korea',
    name: 'AboutKorea',
    component: AboutKorea
  },
  {
    path: '/useful-links',
    name: 'UsefulLinks',
    component: UsefulLinks
  },
  {
    path: '/payment-test',
    name: 'PaymentTest',
    component: PaymentTest
  },
  {
    path: '/iamport-payment',
    name: 'IamportPayment',
    component: IamportPayment
  },
  {
    path: '/payments/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/payments/fail',
    name: 'PaymentFail',
    component: PaymentFail
  },
  {
    path: '/payments/finished',
    name: 'PaymentFinished',
    component: PaymentFinished
  },
  {
    path: '/sponsorship',
    name: 'Sponsorship',
    component: Sponsorship
  },
  {
    path: '/iamport-payment/registration-success-mobile',
    name: 'IamportRegistrationSuccessMobile',
    component: IamportRegistrationSuccessMobile,
  },
  {
    path: '/iamport-payment/registration-finished-mobile',
    name: 'IamportRegistrationFinishedMobile',
    component: IamportRegistrationFinishedMobile,
  },
  {
    path: '/iamport-payment/registration-fail-mobile',
    name: 'IamportRegistrationFailMoblie',
    component: IamportRegistrationFailMoblie,
  },
  {
    path: '/live',
    name: 'Live',
    component: Live,
  },
  {
    path: '/photo-gallery',
    name: 'PhotoGallery',
    component: PhotoGallery,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
