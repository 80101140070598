import base from './base'
import axios  from 'axios'

const getSingleContent = (contentId) => {
  return base.get(`/contents/${contentId}`).then(resp => resp.data)
}

const getLiveContentVideoUrl = (contentSourceId) => {
  return axios.get(`https://api.vimeo.com/me/live_events/${contentSourceId}`,
    {
      headers: {
        Authorization: 'bearer ' + process.env.VUE_APP_VIMEO_API_AUTH_TOKEN
      }
    }).then(resp => resp.data)
}

const getContentHlsUrl = (contentId) => base
  .get(`/contents/${contentId}/hls_url`).then(resp => resp.data)


const getSocialMediaStyleContents = (eventId) => base
  .get(`/contents/social_media_style`, {params: {
    event_id: eventId
  }}).then(resp => resp.data)

const updateSocialMediaStyleContentLikes = (contentId, profileId, likeStatus) => base
  .patch(`/contents/${contentId}/update_likes`, {
    profile_id: profileId,
    update_like_status: likeStatus
  }).then(resp => resp.data)

const updateSocialMediaStyleContentComments = (contentId, comment) => base
  .patch(`/contents/${contentId}/add_comment`, {
    comment: comment,
  }).then(resp => resp.data)

const updateSocialMediaStyleContentImage = (contentId, contentFormData) => base
  .patch(`/contents/${contentId}/update_social_media_style_image`, contentFormData).then(resp => resp.data)

const deleteSocialMediaStyleContent = (contentId) => base
  .delete(`/contents/${contentId}`).then(resp => resp.data)

const postSocialMediaStyleContent = (eventId, contentParams) => base
  .post('/contents', {
    event_id: eventId,
    content_params: contentParams
  }).then(resp => resp.data)


export default {
  getSingleContent:            getSingleContent,
  getLiveContentVideoUrl:      getLiveContentVideoUrl,
  getContentHlsUrl:            getContentHlsUrl,
  getSocialMediaStyleContents: getSocialMediaStyleContents,
  updateSocialMediaStyleContentLikes:    updateSocialMediaStyleContentLikes,
  updateSocialMediaStyleContentComments: updateSocialMediaStyleContentComments,
  updateSocialMediaStyleContentImage:    updateSocialMediaStyleContentImage,
  postSocialMediaStyleContent: postSocialMediaStyleContent,
  deleteSocialMediaStyleContent: deleteSocialMediaStyleContent,
}
