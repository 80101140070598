import Vue       from 'vue'
import Vuex      from 'vuex'
import eventsApi from '@/api/v1/events'
import isEmpty   from 'lodash/isEmpty'
import dayjs     from 'dayjs'
import chroma    from 'chroma-js'

import dynamicConfiguredPagesApi from '@/api/v1/dynamic-configured-pages'

Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')

const standardS3Path = 'https://d3r2ol85dktaw0.cloudfront.net/conferences'

const state = () => ({
  events: [
    {name: 'demo', id: 1},
    {name: 'sicem2021', id: 2},
    {name: 'kns2021', id: 3},
    {name: 'drtunes', id: 6},
    {name: 'gbcc2022', id: 7},
    {name: 'kai-spring2022', id: 8},
    {name: 'koa2022', id: 9},
    {name: 'icorl2022', id: 10},
    {name: 'khrs-virtual', id: 11},
    {name: 'drknow', id: 12},
    {name: 'koa1', id: 13},
    {name: 'absolute', id: 14},
    {name: 'kda2022', id: 15},
    {name: 'koa', id: 16},
    {name: 'samsungfire-live', id: 17},
    {name: 'koa-mm', id: 18},
    {name: 'kiha', id: 19},
    {name: 'jeilpharm', id: 20},
    {name: 'thns2022', id: 21},
    {name: 'jeilpharm220928', id: 22},
    {name: 'kns2022', id: 23},
    {name: 'apsr2022-ondemand', id: 24},
    {name: 'jeilpharm221027', id: 25},
    {name: 'jeilpharm221101', id: 26},
    {name: 'kai2022', id: 27},
    {name: 'jeilpharm221122', id: 28},
    {name: 'cmccme2023', id: 29},
    {name: 'kossoasc', id: 30},
    {name: 'gbcc-onsite', id: 31},
    {name: 'icola2023', id: 32},
    {name: 'jeilpharm230418', id: 33},
    {name: 'jeilpharm230516', id: 34},
    {name: 'jeilpharm230718', id: 36},
    {name: 'jeilpharm230720', id: 37},
    {name: 'jeilpharm230925', id: 38},
    {name: 'onsite', id: 39},
    {name: 'cmccme', id: 40},
    {name: 'jeilpharm231211', id: 41},
    {name: 'jeilpharm231221', id: 42},
    {name: 'demo-staging', id: 1},
    {name: 'sicem2021-staging', id: 2},
    {name: 'kns2021-staging', id: 3},
    {name: 'drtunes-staging', id: 6},
    {name: 'gbcc2022-staging', id: 7},
    {name: 'kai-spring2022-staging', id: 8},
    {name: 'koa2022-staging', id: 9},
    {name: 'icorl2022-staging', id: 10},
    {name: 'khrs-virtual-staging', id: 11},
    {name: 'drknow-staging', id: 12},
    {name: 'koa1-staging', id: 13},
    {name: 'absolute-staging', id: 14},
    {name: 'kda2022-staging', id: 15},
    {name: 'koa-staging', id: 16},
    {name: 'samsungfire-live-staging', id: 17},
    {name: 'koa-mm-staging', id: 18},
    {name: 'kiha-staging', id: 19},
    {name: 'jeilpharm-staging', id: 20},
    {name: 'thns2022-staging', id: 21},
    {name: 'jeilpharm220928-staging', id: 22},
    {name: 'kns2022-staging', id: 23},
    {name: 'apsr2022-ondemand-staging', id: 24},
    {name: 'jeilpharm221027-staging', id: 25},
    {name: 'jeilpharm221101-staging', id: 26},
    {name: 'kai2022-staging', id: 27},
    {name: 'jeilpharm221122-staging', id: 28},
    {name: 'cmccme2023-staging', id: 29},
    {name: 'kossoasc-staging', id: 30},
    {name: 'gbcc-onsite-staging', id: 31},
    {name: 'icola2023-staging', id: 32},
    {name: 'jeilpharm230418-staging', id: 33},
    {name: 'jeilpharm230516-staging', id: 34},
    {name: 'jeilpharm230718-staging', id: 36},
    {name: 'jeilpharm230720-staging', id: 37},
    {name: 'jeilpharm230925-staging', id: 38},
    {name: 'onsite-staging', id: 39},
    {name: 'cmccme-staging', id: 40},
    {name: 'jeilpharm231211-staging', id: 41},
    {name: 'jeilpharm231221-staging', id: 42},
  ],
  event: {},
  sponsorsConfigurations: {
    levels: [],
    sizing: 1.0,
  },
})

const getters = {
  hasEvent: (state) => {
    return !isEmpty(state.event)
  },
  showingEventId: (state) => {
    let subdomain = window.location.host.replace('www.','').split('.')[0]
    let event = state.events.find(event => event.name === subdomain)
    if (event) {
      return event.id
    } else {
      // TODO: Hana (2022.07.27): better handle incorrect subdomain with message or popup
      console.error('unrecognized subdomain', subdomain)
      return 1
    }
  },
  isCmccmeEvent: (state, getters) => {
    return getters.showingEventId === 40 || getters.showingEventId === 29
  },
  showingEventName: (state) => {
    return state.event.name
  },
  eventConfigNavigations: (state, getters) => {
    return getters.hasEvent && state.event.configurations.navigations ? state.event.configurations.navigations : []
  },
  eventConfigDefaultPageTitle: (state, getters) => {
    return getters.hasEvent && state.event.configurations.defaultPageTitle ? state.event.configurations.defaultPageTitle : []
  },
  eventConfigFaviconUrl: (state, getters) => {
    return getters.hasEvent && state.event.configurations.faviconUrl ? state.event.configurations.faviconUrl : []
  },
  eventConfigLogoImageUrl: (state, getters) => {
    return getters.hasEvent && state.event.configurations.logoImageUrl ? state.event.configurations.logoImageUrl : ''
  },
  eventConfigLogoImageCssStyle: (state, getters) => {
    return getters.hasEvent && state.event.configurations.logoImageCssStyle ? state.event.configurations.logoImageCssStyle : ''
  },
  eventPhotoGallery: (state, getters) => {
    return getters.hasEvent && state.event.configurations.photoGalleryData ? state.event.configurations.photoGalleryData : []
  },
  eventConfigPresidentImageUrl: (state, getters) => {
    return getters.hasEvent && state.event.configurations.presidentImageUrl ? state.event.configurations.presidentImageUrl : ''
  },
  eventConfigPresidentName: (state, getters) => {
    return getters.hasEvent && state.event.configurations.presidentName ? state.event.configurations.presidentName : ''
  },
  eventConfigPresidentPosition: (state, getters) => {
    return getters.hasEvent && state.event.configurations.presidentPosition ? state.event.configurations.presidentPosition : ''
  },
  eventConfigPresidentSignatureImageUrl: (state, getters) => {
    return getters.hasEvent && state.event.configurations.presidentSignatureImageUrl ? state.event.configurations.presidentSignatureImageUrl : ''
  },
  eventConfigWelcomeImages: (state, getters) => {
    if (getters.hasEvent && state.event.configurations.welcomeImages) {
      return state.event.configurations.welcomeImages 
    } else if (getters.hasEvent && state.event.configurations.welcomeImageUrls) { // handle cases where we forgot to update config
      return state.event.configurations.welcomeImageUrls.map(imageUrl => {
        return {
          'welcomeImageUrl': imageUrl,
          'siteUrl': ''
        }
      })
    } else {
      return []
    }
  },
  eventConfigWelcomeMessage: (state, getters) => {
    return getters.hasEvent && state.event.configurations.welcomeMessage ? state.event.configurations.welcomeMessage : ''
  },
  eventConfigWelcomeMessageTitle: (state, getters) => {
    return getters.hasEvent && state.event.configurations.welcomeMessageTitle ? state.event.configurations.welcomeMessageTitle : ''
  },
  eventConfigSessionSlots: (state, getters) => {
    return getters.hasEvent && state.event.configurations.sessionSlots ? state.event.configurations.sessionSlots : []
  },
  eventProgramsScheduleConfigurations: (state, getters) => {
    return getters.hasEvent && state.event.programsScheduleConfigurations ? state.event.programsScheduleConfigurations : {}
  },
  eventProgramMobileLayout: (state, getters) => {
    return getters.hasEvent
           && state.event.programsScheduleConfigurations
           && state.event.programsScheduleConfigurations.programMobileLayout 
           ?  state.event.programsScheduleConfigurations.programMobileLayout : 'list'
  },
  eventMainThemeColor: (state, getters) => {
    return getters.hasEvent && state.event.configurations.mainThemeColor ? state.event.configurations.mainThemeColor : 'gray'
  },
  eventSecondaryThemeColor: (state, getters) => {
    return getters.hasEvent && state.event.configurations.secondaryThemeColor ? state.event.configurations.secondaryThemeColor : '#2C8BA9'
  },
  eventColorBaseBackgroundGradient: (state, getters) => {
    return getters.hasEvent && state.event.configurations.colorBaseGradient ? state.event.configurations.colorBaseGradient : `background:linear-gradient(97.87deg, #1B85C3 -13.81%, #744D7D 132.15%);`
  },
  eventTertiaryThemeColor: (state, getters) => {
    return getters.hasEvent && state.event.configurations.tertiaryThemeColor ? state.event.configurations.tertiaryThemeColor : 'white'
  },
  colorsInRange: (state, getters) => {
    var baseColor = getters.eventMainThemeColor
    let colorScale = chroma.scale([chroma(baseColor).brighten(11).desaturate(3).hex(), baseColor, chroma(baseColor).darken(3).saturate(3).hex()])
    return [colorScale(0.02).hex(), colorScale(0.05).hex(), colorScale(0.5).hex(), colorScale(0.9).hex()]
  },
  colorsForGraph: (state, getters) => {
    var baseColor   = getters.eventMainThemeColor
    var secondColor = getters.eventSecondaryThemeColor
    let colorScale = chroma.scale([baseColor, chroma(secondColor).brighten(2).desaturate(0.5).hex(), chroma(baseColor).darken(1.5).desaturate(1.5).hex()])
    return [
      colorScale(0.02).hex(), 
      colorScale(0.5).hex(),
      colorScale(0.9).hex(), 
      colorScale(0.7).hex(), 
      colorScale(0.3).hex(),
      colorScale(0.6).hex(), 
    ]
  },
  eventMainThemeColorBg: (state, getters) => {
    return `background-color: ${getters.eventMainThemeColor};`
  },
  themeTextColorStyle: (state, getters) => {
    return getters.hasEvent && state.event.configurations.mainThemeColor ? `color: ${state.event.configurations.mainThemeColor};` : 'color: #333;'
  },
  eventKakaoSupportUrl: (state, getters) => {
    return getters.hasEvent && state.event.configurations.kakaoSupportUrl ? state.event.configurations.kakaoSupportUrl : ''
  },
  eventChanneltalkPluginKey: (state, getters) => {
    return getters.hasEvent && state.event.configurations.channeltalkPluginKey ? state.event.configurations.channeltalkPluginKey : ''
  },
  eventFileListOnPrograms: (state, getters) => {
    return getters.hasEvent && state.event.configurations.fileList ? state.event.configurations.fileList.filter(file => file.showingPage === 'programs') : []
  },
  eventFileListOnPosters: (state, getters) => {
    return getters.hasEvent && state.event.configurations.fileList ? state.event.configurations.fileList.filter(file => file.showingPage === 'posters') : []
  },
  eventConfigSessionSlotHeight: (state, getters) => {
    return getters.hasEvent && state.event.configurations.sessionSlotHeight ? state.event.configurations.sessionSlotHeight : 20
  },
  eventConfigSessionOfflineMessage: (state, getters) => {
    return getters.hasEvent && state.event.configurations.sessionOfflineMessage ? state.event.configurations.sessionOfflineMessage : 'This session is an offline only session. A recording of the event may be made available later.'
  },
  eventConfigWelcomeCarouselSponsors: (state, getters) => { // TODO 2022.10.12 (Hana): move this to the sponsors side
    return getters.hasEvent && state.event.configurations.welcomeCarouselSponsors ? state.event.configurations.welcomeCarouselSponsors : []
  },
  eventConfigPostersCarouselSponsors: (state, getters) => {
    return getters.hasEvent && state.sponsorsConfigurations.postersCarouselSponsors ? state.sponsorsConfigurations.postersCarouselSponsors : []
  },
  eventConfigProgramsCarouselSponsors: (state, getters) => {
    return getters.hasEvent && state.sponsorsConfigurations.programsCarouselSponsors ? state.sponsorsConfigurations.programsCarouselSponsors : []
  },
  eventConfigGreeters: (state, getters) => {
    return getters.hasEvent && state.event.configurations.welcomeGreeters ? state.event.configurations.welcomeGreeters : []
  },
  eventConfigShowNoticeDate: (state, getters) => {
    return getters.hasEvent && state.event.configurations.showNoticeDate != undefined ? state.event.configurations.showNoticeDate : true
  },
  eventConfigAbstractSubmissionDeadline: (state, getters) => {
    return getters.hasEvent && state.event.abstractSubmissionsConfigurations.abstractSubmissionDeadline ? dayjs(state.event.abstractSubmissionsConfigurations.abstractSubmissionDeadline).format() : ''
  },
  showingEventNameProduction: (state, getters) => {
    let eventName = getters.showingEventName
    if (getters.hasEvent && eventName.includes('-staging')) {
      eventName = eventName.split('-staging')[0]
    }
    return eventName
  },
  eventInitialSessionId: (state, getters) => {
    return getters.hasEvent && state.event.configurations.initialSessionId ? state.event.configurations.initialSessionId : 0
  },
  eventImageS3Path: (state, getters) => {
    return getters.hasEvent ? `${standardS3Path}/${getters.showingEventNameProduction}` : ''
  },
  liveSessionsComponentDemoData: (state, getters) => {
    return getters.hasEvent && state.event.configurations.liveSessionsDemoData ? state.event.configurations.liveSessionsDemoData : null
  },
  dateLocaleString: (state, getters) => {
    return getters.hasEvent ? state.event.configurations.dateLocaleString : ''
  },
  sponsorHallBannerImages: (state, getters) => (side) => {
    return getters.hasEvent ? state.event.configurations.sponsorHallBannerImages[side] : ''
  },
  showingEventLoginPageConfigurations: (state, getters) => {
    return getters.hasEvent ? state.event.loginPageConfigurations : null
  },
  showingEventMyPageConfigurations: (state, getters) => {
    return getters.hasEvent ? state.event.myPageConfigurations : null
  },
  showingEventRegistrationConfigurations: (state, getters) => {
    return getters.hasEvent ? state.event.registrationPageConfigurations : null
  },
  showingEventSignupConfigurations: (state, getters) => {
    return getters.hasEvent ? state.event.signupPageConfigurations : null
  },
  showingEventAbstractSubmissionsConfigurations: (state, getters) => {
    return getters.hasEvent ? state.event.abstractSubmissionsConfigurations : null
  },
  showingEventSessionPageConfigurations: (state, getters) => {
    return getters.hasEvent ? state.event.sessionPageConfigurations : null
  },
  eventConfigHelpEmailLink: (state, getters) => {
    return getters.hasEvent && state.event.configurations.eventConfigHelpEmailLink ? state.event.configurations.eventConfigHelpEmailLink : ''
  },
  eventHasHelpControl: (state, getters) => {
    return getters.hasEvent && state.event.configurations.showHelpControl ? state.event.configurations.showHelpControl : ''
  },
  eventConfigShowCurrentTimeAndSessions: (state, getters) => {
    return getters.hasEvent && state.event.configurations.showCurrentTimeAndSessions ? state.event.configurations.showCurrentTimeAndSessions : ''
  },
  eventMenuLogoLink: (state, getters) => {
    return getters.hasEvent && state.event.configurations.menuLogoLink ?  state.event.configurations.menuLogoLink : ''
  },
  eventProgramsPageMessage: (state, getters) => {
    return getters.hasEvent && state.event.configurations.programsPageMessageHtml ?  state.event.configurations.programsPageMessageHtml : ''
  },
  eventProgramsPageSessionCardText: (state, getters) => {
    return getters.hasEvent && state.event.programsScheduleConfigurations.programsSessionCardText ?  state.event.programsScheduleConfigurations.programsSessionCardText : 'VOD 시청하기'
  },
  eventPromotionalEventBannerUrl: (state, getters) => {
    return getters.hasEvent && state.event.configurations.eventPromotionalEventBannerUrl ?  state.event.configurations.eventPromotionalEventBannerUrl : ''
  },
  eventImportantDates: (state, getters) => {
    return getters.hasEvent && state.event.configurations.importantDates ?  state.event.configurations.importantDates : []
  },
  eventImportantDatesRelativeStartDate: (state, getters) => {
    return getters.hasEvent && state.event.configurations.importantDatesRelativeStartDate ?  state.event.configurations.importantDatesRelativeStartDate : ''
  },
  eventMainPageContent: (state, getters) => {
    return getters.hasEvent && state.event.configurations.mainPageContents ?  state.event.configurations.mainPageContents : []
  },
  eventMainDownloadFiles: (state, getters) => {
    return getters.hasEvent && state.event.configurations.mainPageDownloadFiles ?  state.event.configurations.mainPageDownloadFiles : []
  },
  eventImportantLectures: (state, getters) => {
    return getters.hasEvent && state.event.configurations.importantLectures ?  state.event.configurations.importantLectures : []
  },
  eventMainPageImageBg: (state, getters) => {
    return getters.hasEvent && state.event.configurations.mainPageImageBg ?  state.event.configurations.mainPageImageBg : ''
  },
  showTextInKorean: (state, getters) => {
    return getters.hasEvent && state.event.configurations.showTextInKorean ?  state.event.configurations.showTextInKorean : false
  },
  onLoadModals: (state, getters) => {
    return getters.hasEvent && state.event.configurations.onLoadModals ?  state.event.configurations.onLoadModals : []
  },
  programScheduleFilterAltContent: (state, getters) => {
    return getters.hasEvent
      && state.event.programsScheduleConfigurations
      && state.event.programsScheduleConfigurations.programScheduleFilterAltContent 
      ?  state.event.programsScheduleConfigurations.programScheduleFilterAltContent : ''
  },
  eventSearchMetaDescription: (state, getters) => {
    return getters.hasEvent && state.event.configurations.eventSearchMetaDescription ?  state.event.configurations.eventSearchMetaDescription : 'Webconcert: your online webconference is here!'
  },
  speakerCvLinkUrlTitle: (state, getters) => {
    return getters.hasEvent && state.event.configurations.speakerCvLinkUrlTitle ?  state.event.configurations.speakerCvLinkUrlTitle : 'CV'
  },
}

const actions = {
  getEventMeta ({commit, getters}) {
    return new Promise(resolve => {
      eventsApi.getEventMeta(getters.showingEventId).then(resp => {
        commit('setEvent', camelcaseKeys(resp.event, {deep: true}))
        commit('setSponsorsConfigurations', camelcaseKeys(resp.event.sponsors_configurations, {deep: true}))
        resolve()
      })
    })
  },
  getDyanmicConfiguredPages ({getters}) {
    return new Promise(resolve => {
      dynamicConfiguredPagesApi.getPagesForEvent(getters.showingEventId).then(resp => {
        resolve(camelcaseKeys(resp.pages, {deep: true}))
      })
    }) 
  },
  getDyanmicConfiguredPage ({getters}, pageName) {
    return new Promise(resolve => {
      dynamicConfiguredPagesApi.getPageForEvent(getters.showingEventId, pageName).then(resp => {
        resolve(camelcaseKeys(resp.pages, {deep: true}))
      })
    }) 
  },
}

const mutations = {
  setEvent (state, event) {
    state.event = event
  },
  setSponsorsConfigurations (state, configurations) {
    state.sponsorsConfigurations = configurations
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
