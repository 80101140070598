import base from './base'

const getSessions = (eventId, type) => {
  return base.get(`/sessions`, {
    params: {
      event_id: eventId,
      type: type
    }
  }).then(resp => resp.data)
}

const getSessionsWithSpeakers = (eventId, type) => {
  return base.get(`/sessions/with_speakers`, {
    params: {
      event_id: eventId,
      type: type
    }
  }).then(resp => resp.data)
}

const getSingleSession = (sessionId, eventId) => {
  return base.get(`/sessions/${sessionId}`, {
    params: {
      event_id: eventId
    }
  }).then(resp => resp.data)
}

const getSessionSpeakers = (sessionId, eventId) => {
  return base.get(`/sessions/${sessionId}/speakers`, {params: { event_id: eventId }}).then(resp => resp.data)
}

const getSessionsDetails = (eventId, params) => {
  return base.get(`/sessions/details`, {
    params: {
      event_id: eventId,
      categories: params.categories,
      session_date: params.sessionDate,
      session_room: params.sessionRoom
    }
  }).then(resp => resp.data)
}

const getMyLikedSessions = (eventId) => {
  return base.get(`/sessions/my_liked_sessions`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp.data)
}

const searchByString = (eventId, searchString) => {
  return base.get(`/sessions/search_by_string`, {
    params: {
      event_id:      eventId,
      search_string: searchString,
    }
  }).then(resp => resp.data)
}

const getSingleSessionDetails = (eventId, sessionId) => {
  return base.get(`/sessions/${sessionId}/detail`, {
    params: {
      event_id: eventId
    }
  }).then(resp => resp.data)
}

const postLikeSession = (eventId, sessionId) => {
  const params = {
    user_session: {
      session_id: sessionId,
      event_id: eventId,
    }
  }
  return base.post(`/sessions/like`, params).then(resp => resp.data)
}

const getLiveSessions = (eventId) => {
  return base.get(`/sessions/live`, {
    params: {
      event_id: eventId
    }
  }).then(resp => resp.data)
}

const patchSession = (sessionId, updatedSession) => {
  return base.patch(`/sessions/${sessionId}`, {
      session: updatedSession
    }
  ).then(resp => resp.data)
}

const postSession = (createdSession) => {
  return base.post(`/sessions`, {
    session: createdSession
  }).then(resp => resp.data)
}

const getCurrentSessionId = (sessionId, eventId) => {
  return base.get('/sessions/current', {
    params: {
      event_id: eventId,
      session_id: sessionId
    }
  }).then(resp => resp.data)
}

const patchSessionScore = (sessionId, eventId, score) => {
  return base.patch(`/sessions/${sessionId}/update_score`, {
    event_id: eventId,
    session_score: score,
    }
  ).then(resp => resp.data)
}

export default {
  getSessions:             getSessions,
  getSingleSession:        getSingleSession,
  getSessionSpeakers:      getSessionSpeakers,
  getLiveSessions:         getLiveSessions,
  getCurrentSessionId:     getCurrentSessionId,
  getSessionsDetails:      getSessionsDetails,
  getMyLikedSessions:      getMyLikedSessions,
  getSingleSessionDetails: getSingleSessionDetails,
  searchByString:          searchByString,
  getSessionsWithSpeakers: getSessionsWithSpeakers,
  postLikeSession:   postLikeSession,
  postSession:       postSession,
  patchSession:      patchSession,
  patchSessionScore: patchSessionScore,
}
