import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0].slice(0, 2) : navigator.userLanguage.slice(0, 2) || navigator.language.slice(0, 2) || navigator.browserLanguage.slice(0, 2) || 'en';

require(`dayjs/locale/en`) // english
require(`dayjs/locale/ko`) // korean
require(`dayjs/locale/es`) // spanish
require(`dayjs/locale/zh`) // chinese



class DateTimeStringHelpers {


  static koreanDayOfWeek (dateTime) {
    let day = dayjs(dateTime).day()
    switch (day) {
      case 0:
      return '일'
      case 1:
      return '월'
      case 2:
      return '화'
      case 3:
      return '수'
      case 4:
      return '목'
      case 5:
      return '금'
      case 6:
      return '토'
      default: 
      return '-'
    }
  }


  static toDateTime (dateTime, localeString = '') {
    const language = (localeString) ? localeString : getNavigatorLanguage()
    return dayjs(dateTime).tz('Asia/Seoul').locale(language).format('YYYY.MM.DD HH:mm')
  }

  static toDate (dateTime, localeString = '') {
    const language = (localeString) ? localeString : getNavigatorLanguage()
    return dayjs(dateTime).tz('Asia/Seoul').locale(language).format('YYYY.MM.DD')
  }

  static toDateWithDay (dateTime, localeString = '') {
    const language = (localeString) ? localeString : getNavigatorLanguage()
    return dayjs(dateTime).tz('Asia/Seoul').locale(language).format('YYYY.MM.DD (ddd)')
  }

  // for progrmas page date text and date selector, do not need tz beacuse it is just date
  static dateToDateWithDay (date, localeString = '') {
    const language = (localeString) ? localeString : getNavigatorLanguage()
    return dayjs(date).locale(language).format('YYYY.MM.DD (ddd)')
  }
  
  static toDateWithoutYear (date, localeString = '') {
    const language = (localeString) ? localeString : getNavigatorLanguage()
    return dayjs(date).locale(language).format('MM.DD (ddd)')
  }
  // for progrmas page date text and date selector

  static toTime (dateTime, localeString = '') {
    const language = (localeString) ? localeString : getNavigatorLanguage()
    return dayjs(dateTime).tz('Asia/Seoul').locale(language).format('HH:mm')
  }

  static secondToHourAndMinute (seconds) {
    var hour = Math.floor(seconds / 3600);
    seconds -= hour*3600;
    let hoursString = hour + 'h '
    var minute = Math.floor(seconds / 60);
    let minutesString = minute + 'm '

    return hoursString + minutesString
  }

}

export default DateTimeStringHelpers
