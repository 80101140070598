<template>
  <div>
    <h1 class='text-xl font-semibold text-gray-900 mb-4'>Register Attendee</h1>
    <div class='mb-6'>
      <button class='input-address-button block'
              @click='getMyProfileData'>
        내 정보 가져오기
      </button>
    </div>
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500'>Email</label>
      <input
        v-model='email'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='email'
        placeholder='email address'>
        <div class='text-gray-500 text-xs leading-4 mt-2 w-full lg:w-80'>This email address will be your username to login to the virtual site. It will also be used to communicate with you important details about the conference.</div>
    </div>
    <form-field-medical-profession
      v-bind:medical-profession.sync='medicalProfession'
      class='mb-6' />
    <form-field-title v-if='!isKorean'
      v-bind:title.sync='title'
      class='mb-6' />
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500'>First & Middle Name (English)</label>
      <input
        v-model='firstName'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        @input='blockKoreanInput($event)'
        placeholder='First & Middle Name'>
    </div>
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500'>Last Name (English)</label>
      <input
        v-model='lastName'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        @input='blockKoreanInput($event)'
        placeholder='Last Name'>
    </div>
    <div class='mb-6' v-if='isKorean'>
      <label class='uppercase text-sm text-gray-500 mb-2'>성명 (국문)</label>
      <input
        v-model='editingAttendee.profile.name'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        @input='onlyKoreanNameInput($event)'
        placeholder='성명'>
    </div>
    <div class='mb-6' v-if='isKorean'>
      <label class='uppercase text-sm text-gray-500 mb-2'>License Number</label>
      <input
        v-model='licenseNumber'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        placeholder='License Number'>
    </div>
    <div class='mb-6' v-if='isKorean'>
      <label class='uppercase text-sm text-gray-500 mb-2'
        :style='ageTypeStringStyle'>
        {{ ageTypeString }}
      </label>
      <button class='border py-1 px-2 rounded block w-full lg:w-80 text-white uppercase '
      :style='`background-color: ${eventMainThemeColor};`'
        @click='checkAgeType'>
        만65세 이상회원 또는 수연회원 인증하기
      </button>
      <div class='text-gray-500 text-xs leading-4 mt-2 w-full lg:w-80'>만65세 기준연도: 1957년부터 이전 출생자<br>
        수연회원 기준: 1961년 10월 17일부터 1962년 10월 15일 출생자
      </div>
    </div>
    <form-field-affiliation
      v-bind:hospitalName.sync='hospitalName'
      v-bind:organization.sync='organization'
      :is-korean='isKorean' />
    <form-field-department
      v-bind:department.sync='department'
      class='mb-6' />
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Participation Type (English)</label>
      <select
        v-model='participationType'
        class='border py-1 px-2 rounded block w-full lg:w-80'>
        <option disabled></option>
        <option v-for='participationType in participationTypes'
          :key='`participationType-${participationType.value}`'
          :value='participationType.value'>
          {{participationType.fullDescription}}
        </option>
      </select>
      <div class='text-gray-500 text-xs leading-4 mt-2 w-full lg:w-80'>Online only participation registration is available.</div>
    </div>
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Participant Type (English)</label>
      <select
        v-model='participantType'
        class='border py-1 px-2 rounded block w-full lg:w-80'>
        <option disabled></option>
        <option v-for='participantType in participantTypes'
          :key='`participantType-${participantType.value}`'
          :value='participantType.value'>
          {{participantType.fullDescription}}
        </option>
      </select>
    </div>
    <div class='mb-6' v-if='isKorean && isParticipationTypeOffline'>
      <label class='uppercase text-sm text-gray-500 mb-2'>명찰수령방식 (국문)</label>
      <select
        v-model='nametagPickupLocation'
        class='border py-1 px-2 rounded block w-full lg:w-80'>
        <option disabled></option>
        <option v-for='location in nametagPickupLocations'
          :key='`location-${location.value}`'
          :value='location.value'>
          {{location.fullDescription}}
        </option>
      </select>
    </div>
    <div class='mb-6' v-if='isKorean && isParticipationTypeOffline && isNametagPickupLocationTypeHome'>
      <label class='uppercase text-sm text-gray-500 mb-2'>명찰수령주소 입력 (국문)</label>
      <button class='input-address-button block'
              @click='openAddressMap'>
        {{ addressString }}
      </button>
      <vue-daum-postcode v-if='showAddressMap'
                         @complete='completeAddressMap'
                         class='border rounded-md mt-2 overflow-y-scroll'
                         style='max-height: 400px'/>
    </div>
    <div class='mb-6' v-if='isKorean && isParticipationTypeOffline && isNametagPickupLocationTypeHome'>
      <label class='uppercase text-sm text-gray-500 mb-2'>명찰수령상세주소 입력 (국문)</label>
      <input
        v-model='addressDetail'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        placeholder='명찰수령상세주소 입력 (국문)'>
    </div>
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Mobile Phone</label>
      <vue-phone-number-input
        class='block w-full lg:w-80'
        v-model='phoneNumber'
        :default-country-code='defaultCountryCode'
        :valid-color='eventMainThemeColor'
        @update='formatProfilePhoneNumber' />
      <div class='text-gray-500 text-xs leading-4 mt-2 w-full lg:w-80'>Please ensure you have entered your number correctly. <br>It will be used to communicate with you important details about the conference.</div>
    </div>
    <div class='border rounded-t-md mt-8 bg-gray-50'>
      <h1 class='text-lg font-semibold mb-4 text-gray-900 p-4'>Registration Type & Fee</h1>
      <div class='px-4 pb-8'>
        <select
          v-model='registrationProduct'
          class='px-2 py-2 w-full border rounded-md'>
          <option v-for='product in filteredProducts'
            :key='`registration-product-${product.id}`' 
            :value='product'>
            {{ product.description }}: {{ product.price.priceUnit }} {{ priceString(product.price.amount) }}
          </option>
        </select>
      </div>
    </div>
    <div class='py-4 mt-4'>
      <button 
        class='px-4 py-3 hover:shadow-md uppercase border rounded-md w-full submit-button'
        @click='registerAttendeeAndClose'
        :disabled='disabledRegistrationButton'
        :class='disabledRegistrationButtonClass'>
        Save Attendee Registration
      </button>
      <ul class='list-disc list-inside mt-8 text-red-600 text-xs'>
        <li v-if='emptyEmail'>Please enter attendee`s email</li>
        <li v-if='emptyMedicalProfession'>Please enter attendee`s medical profession</li>
        <li v-if='emptyTitle && !isKorean'>Please enter attendee`s title</li>
        <li v-if='emptyFirstName'>Please enter attendee`s first & middle name</li>
        <li v-if='emptylastName'>Please enter attendee`s last name</li>
        <li v-if='emptyName && isKorean'>성명 (국문)을 입력해주세요</li>
        <li v-if='emptyLicenseNumber && isKorean'>Please enter attendee`s license number</li>
        <li v-if='emptyHospitalName && isKorean'>Affiliation (국문)을 입력해주세요</li>
        <li v-if='emptyOrganization'>Please enter attendee`s affiliation</li>
        <li v-if='emptyDepartment'>Please enter attendee`s department</li>
        <li v-if='emptyParticipationType'>Please enter attendee`s participation type</li>
        <li v-if='emptyParticipantType'>Please enter attendee`s participant type</li>
        <li v-if='emptyNametagPickupLoaction && isKorean && isParticipationTypeOffline'>명찰수령방식을 선택해주세요</li>
        <li v-if='emptyAddress && isKorean && isParticipationTypeOffline && isNametagPickupLocationTypeHome'>명찰수령주소를 입력해주세요</li>
        <li v-if='emptyAddressDetail && isKorean && isParticipationTypeOffline && isNametagPickupLocationTypeHome'>명찰수령상세주소를 입력해주세요</li>
        <li v-if='emptyPostalCode && isKorean && isParticipationTypeOffline && isNametagPickupLocationTypeHome'>Please enter attendee`s postcal code</li>
        <li v-if='emptyPhoneNumber'>Please enter attendee`s mobile phone number with international calling code</li>
        <li v-if='invalidPhoneNumber'>Please check attendee`s mobile phone number</li>
        <li v-if='emptyRegistrationProduct'>Please select attendee`s registration type</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { mapFields }            from 'vuex-map-fields'
import numbro              from 'numbro'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

import FormFieldMedicalProfession from '@/components/FormFieldMedicalProfession.vue'
import FormFieldTitle             from '@/components/FormFieldTitle.vue'
import FormFieldDepartment        from '@/components/FormFieldDepartment.vue'
import FormFieldAffiliation       from '@/components/FormFieldAffiliation.vue'
import UserDataHelpers            from '@/utils/user-data-helpers.js'
import EventBus                   from '@/utils/EventBus'

export default {
  name: 'RegisterNewFormFields',
  components: {
    VuePhoneNumberInput,
    FormFieldMedicalProfession,
    FormFieldTitle,
    FormFieldDepartment,
    FormFieldAffiliation,
  },
  data () {
    return {
      showAddressMap: false,
      invalidPhoneNumber: false,
    }
  },
  watch: {
    'participationType': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let index = this.participationTypes.map((item) => item.value.toLowerCase()).indexOf(newVal.toLowerCase())
          if (index > -1) {
            this.participationType = this.participationTypes[index].value
          } else {
            this.participantType = newVal
          }
        }
      },
      immediate: true 
    },
    'participantType': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let index = this.participantTypes.map((item) => item.value.toLowerCase()).indexOf(newVal.toLowerCase())
          if (index > -1) {
            this.participantType = this.participantTypes[index].value
          } else {
            this.participantType = newVal
          }
        }
      },
      immediate: true 
    },
    'fullAddressString': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.nametagAddress = newVal
        }
      },
      immediate: true 
    },
    'filteredProducts.length': {
      handler: function (newVal) {
        if (newVal && newVal === 1) {
          this.registrationProduct = this.filteredProducts[0]
        } else if (newVal && newVal > 1) {
          let index = this.filteredProducts.findIndex(product => product.id === this.productId)
          if (index > -1) {
            this.registrationProduct = this.filteredProducts[index]
          } else {
            this.registrationProduct = null
          }
        }
      },
      immediate: true 
    },
    'registrationProduct': {
      handler: function (newVal) {
        if (newVal) {
          this.productId = newVal.id
          this.registrationFee = `${newVal.price.priceUnit} ${numbro(newVal.price.amount).format({ thousandSeparated: true })}`
          this.registrationType = newVal.name
          if (newVal.name.includes('over65')) {
            this.isKoaMember = 'is_member'
            this.isKoaMemberOver65 = true
          } else if (newVal.name.includes('trainee')) {
            this.isKoaMember = 'is_member'
            this.isKoaMemberOver65 = false
          } else if (newVal.name === 'member') {
            this.isKoaMemberOver65 = false
            this.isKoaMember = 'is_member'
          } else {
            // nonMember, Resident, Nures, Army Doctor, Researcher
            this.isKoaMemberOver65 = false
            this.isKoaMember = 'not_member'
          }
        } 
      },
      immediate: true 
    },
  },
  computed: {
    ...mapState('users',[
      'profile'
    ]),
    ...mapState('products', [
      'products',
    ]),
    ...mapFields('users', [
      'editingAttendee',
      'editingAttendee.registrationProduct',
      'editingAttendee.email',
      'editingAttendee.profile.firstName',
      'editingAttendee.profile.lastName',
      'editingAttendee.profile.name',
      'editingAttendee.profile.licenseNumber',
      'editingAttendee.profile.phoneNumber',
      'editingAttendee.profile.title',
      'editingAttendee.profile.address',
      'editingAttendee.profile.addressDetail',
      'editingAttendee.profile.postalCode',
      'editingAttendee.profile.country',
      'editingAttendee.profile.department',
      'editingAttendee.profile.hospitalName',
      'editingAttendee.profile.organization',
      'editingAttendee.profile.extraInfo.medicalProfession',
      'editingAttendee.membershipData.isKoaMember',
      'editingAttendee.membershipData.isKoaMemberOver65',
      'editingAttendee.membershipData.ageType',
      'editingAttendee.registrationData.nametagAddress',
      'editingAttendee.registrationData.nametagPickupLocation',
      'editingAttendee.registrationData.productId',
      'editingAttendee.registrationData.participationType',
      'editingAttendee.registrationData.participantType',
      'editingAttendee.registrationData.registrationNumber',
      'editingAttendee.registrationData.registrationFee',
      'editingAttendee.registrationData.registrationType',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
      'showingEventId',
    ]),
    participantTypes () {
      return UserDataHelpers.selectableValues(this.showingEventId, 'participantTypes')
    },
    participationTypes () {
      return UserDataHelpers.selectableValues(this.showingEventId, 'participationTypes')
    },
    nametagPickupLocations () {
      return UserDataHelpers.selectableValues(this.showingEventId, 'nametagPickupLocation')
    },
    isKorean () {
      return !this.editingAttendee.profile.country ||
             this.editingAttendee.profile.country.toLowerCase() === 'kr'
    },
    defaultCountryCode () {
      return this.editingAttendee.profile.country.toUpperCase()
    },
    filteredProducts () {
      if (this.isKorean) {
        if (this.ageType === 'over65') {
          return this.products.filter(product => product.name.toLowerCase().includes('over65'))
        } else if (this.ageType === 'trainee') {
          return this.products.filter(product => product.name.toLowerCase().includes('trainee'))
        } else {
          return this.products.filter(product => !product.name.toLowerCase().includes('international') &&
                                                 !product.name.toLowerCase().includes('over65') && 
                                                 !product.name.toLowerCase().includes('trainee'))
        }
      } else {
        return this.products.filter(product => product.name.toLowerCase().includes('international'))
      }
    },
    emptyEmail () {
      return !this.editingAttendee.email
    },
    emptyMedicalProfession () {
      return !this.editingAttendee.profile.extraInfo.medicalProfession
    },
    emptyTitle () {
      return !this.editingAttendee.profile.title
    },
    emptyFirstName () {
      return !this.editingAttendee.profile.firstName
    },
    emptylastName () {
      return !this.editingAttendee.profile.lastName
    },
    emptyName () {
      return !this.editingAttendee.profile.name
    },
    emptyLicenseNumber () {
      return !this.editingAttendee.profile.licenseNumber
    },
    emptyOrganization () {
      return !this.editingAttendee.profile.organization
    },
    emptyHospitalName () {
      return !this.editingAttendee.profile.hospitalName
    },
    emptyDepartment () {
      return !this.editingAttendee.profile.department
    },
    emptyParticipationType () {
      return !this.editingAttendee.registrationData.participationType
    },
    emptyParticipantType () {
      return !this.editingAttendee.registrationData.participantType
    },
    emptyNametagPickupLoaction () {
      return !this.editingAttendee.registrationData.nametagPickupLocation
    },
    emptyAddress () {
      return !this.editingAttendee.profile.address
    },
    emptyAddressDetail () {
      return !this.editingAttendee.profile.addressDetail
    },
    emptyPostalCode () {
      return !this.editingAttendee.profile.postalCode
    },
    emptyPhoneNumber () {
      return !this.editingAttendee.profile.phoneNumber
    },
    emptyPaymentGateway () {
      return !this.paymentGateway
    },
    emptyRegistrationProduct () {
      return !this.registrationProduct
    },
    disabledRegistrationButton () {
      let emptyDefaultFields = this.emptyEmail ||
        this.emptyMedicalProfession ||
        this.emptyFirstName ||
        this.emptylastName ||
        this.emptyOrganization ||
        this.emptyDepartment ||
        this.emptyParticipationType ||
        this.emptyParticipantType ||
        this.emptyPhoneNumber ||
        this.invalidPhoneNumber ||
        this.emptyRegistrationProduct
      if (this.isKorean) {
        emptyDefaultFields = emptyDefaultFields ||
          this.emptyName ||
          this.emptyLicenseNumber ||
          this.emptyHospitalName
        if (this.isParticipationTypeOffline && this.isNametagPickupLocationTypeHome) {
          emptyDefaultFields = emptyDefaultFields ||
          this.emptyAddress ||
          this.emptyAddressDetail ||
          this.emptyPostalCode
        }
        return emptyDefaultFields
      } else {
        return emptyDefaultFields || this.emptyTitle
      }
    },
    disabledRegistrationButtonClass () {
      return this.disabledRegistrationButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    isParticipationTypeOffline () {
      return this.participationType.toLowerCase() === 'offline'
    },
    isNametagPickupLocationTypeHome () {
      return this.nametagPickupLocation === 'home'
    },
    addressString () {
      return (this.address) ? this.address : '주소 검색'
    },
    ageTypeParams () {
      return {
        name: this.name.replace(/\s/g, ''),
        licenseNumber: this.licenseNumber.replace(/\s/g, '')
      }
    },
    ageTypeString () {
      if (!this.ageType) {
        return ''
      } else if (this.ageType === 'over65') {
        return '만65세 이상회원 인증되었습니다.'
      } else if (this.ageType === 'trainee') {
        return '수연회원 인증되었습니다.'
      } else {
        return '만65세 이상회원 또는 수연회원이 아닙니다.'
      }
    },
    ageTypeStringStyle () {
      return (this.ageType === 'over65' || this.ageType === 'trainee') ? `color: ${this.eventMainThemeColor}; font-weight: 600;`: ''
    },
    fullAddressString () {
      return `${this.address} ${this.addressDetail}`
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
      'sidepanelOpen',
    ]),
    ...mapActions('users', [
      'getRegisteredByMe',
      'registerAttendee',
      'getIsAlreadyRegistered',
      'getAgeType',
    ]),
    registerAttendeeAndClose () {
      this.getIsAlreadyRegistered({email: this.email}).then((isAlreadyRegistered) => {
        if (isAlreadyRegistered) {
          this.$alert("This email is already registered. Please check attendee's email.", {
            type: 'warning',
            confirmButtonText: 'Close'
          })
        } else {
          this.registrationNumber = `G-${this.profile.ssoIdentityId}`
          this.registerAttendee().then(() => {
            // close sidepanel
            this.sidepanelClose()
          })
        }
      })
    },
    openAddressMap () {
      this.showAddressMap = true
    },
    completeAddressMap (event) {
      this.address = event.address
      this.postalCode = event.zonecode
      this.showAddressMap = false
    },
    formatProfilePhoneNumber (data) {
      this.phoneNumber = data.formatInternational
      this.invalidPhoneNumber = !data.isValid
    },
    priceString (price) {
      return numbro(price).format({
        thousandSeparated: true,
        mantissa: 0,
      })
    },
    getMyProfileData () {
      this.editingAttendee.profile.firstName = this.profile.firstName
      this.editingAttendee.profile.lastName = this.profile.lastName
      this.editingAttendee.profile.name = this.profile.name
      this.editingAttendee.profile.licenseNumber = this.profile.licenseNumber
      this.editingAttendee.profile.phoneNumber = this.profile.phoneNumber
      this.editingAttendee.profile.address = this.profile.address
      this.editingAttendee.profile.addressDetail = this.profile.addressDetail
      this.editingAttendee.profile.postalCode = this.profile.postalCode
      this.editingAttendee.profile.department = this.profile.department
      this.editingAttendee.profile.hospitalName = this.profile.hospitalName
      this.editingAttendee.profile.organization = this.profile.organization
      this.editingAttendee.email = this.profile.email
    },
    checkAgeType () {
      this.getAgeType(this.ageTypeParams).then((resp) => {
        this.ageType = resp
      })
    },
    blockKoreanInput(event)  {
      event.target.value = event.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig, '')
    },
    onlyKoreanNameInput(event) {
      this.editingAttendee.profile.name = event.target.value.replace(/[^\u3131-\u3163 ^\uac00-\ud7a3]/g, '')
    },
  },
  mounted () {
    this.country = 'KR'
    EventBus.$on('check-registration-sidepanel-changes', () => {
      this.$confirm('Changes you made may not be saved. Do you want to leave this form?', 'Warning', {
        confirmButtonText: 'Leave',
        cancelButtonText: 'Stay',
        type: 'warning'
      }).then(() => {
        this.getRegisteredByMe().then(() => {
          this.sidepanelClose()
        })
      }).catch(() => {})
    })
  },
  beforeDestroy () {
    EventBus.$off('check-registration-sidepanel-changes')
  },
}
</script>

<style type='scss' scoped>
.input-address-button {
  @apply border rounded-md py-1 w-full lg:w-80;
}

.input-address-button:hover {
  @apply bg-blue-800 text-white;
}

.submit-button {
  @apply text-white;
  background-color: var(--eventMainColor);
}

</style>
