import base from './base'

const getSpeakers = (eventId) => {
  return base.get(`/speakers`, {params: {event_id: eventId}}).then(resp => resp.data)
}

const getMainSpeakers = (eventId) => {
  return base.get(`/speakers/speakers_on_main_page`, {params: {event_id: eventId}}).then(resp => resp.data)
}

const getSpeakersInSessionGroups = (eventId) => {
  return base.get(`/speakers_in_session_groups`, {event_id: eventId}).then(resp => resp.data)
}

const getSingleSpeaker = (speakerId, eventId) => {
  return base.get(`/speakers/${speakerId}`, {params: {event_id: eventId}}).then(resp => resp.data)
}

export default {
  getSpeakers:                getSpeakers,
  getMainSpeakers:            getMainSpeakers,
  getSpeakersInSessionGroups: getSpeakersInSessionGroups,
  getSingleSpeaker:           getSingleSpeaker,
}
