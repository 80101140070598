<template>
  <div 
    class='flex flex-col items-center justify-center'
    :class='propsStyles'
    style='z-index: 2004;'>
    <div v-if='hasMessage' class='text-blue-100'>{{message}}</div>
    <div class='text-5xl'><slot><i class='el-icon-loading text-blue-200' /></slot></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: [
    'message',
    'isFullPage',
  ],
  computed: {
    propsStyles () {
      return `${this.isFullPageClasses} `
    },
    hasMessage () {
      return this.message
    },
    hasMessageStyle () {
      return (this.hasMessage) ? '' : ''
    },
    isFullPageClasses () {
      return (this.isFullPage) ? 'fixed inset-0 opacity-75 bg-black' : ''
    }
  }
}
</script>