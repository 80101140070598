import Vue from 'vue'

class WatchedVideoCacheHelpers {

  static cleanupCache (cacheKey) {
    Vue.$cookies.remove(cacheKey)
  }

  static decodeCacheKey (cacheKey) {
    let values = cacheKey.split('|')
    return {
      event_id: values[0],
      session_id: values[1],
      content_id: values[2],
      start_time: values[3]
    }
  }

  static encodeCacheKey ({eventID, sessionID, contentID, startTime}) {
    if (eventID && sessionID && contentID && startTime) {
      return `${eventID}|${sessionID}|${contentID}|${startTime}`
    }
    return ''
  }

  static queueCache ({cacheKey, playedArray}) {
    if (cacheKey) {
      let stringified = JSON.stringify(playedArray)
      Vue.$cookies.set(cacheKey, stringified)
    } else {
      console.error('error caching video watched')
    }
  }
}

export default WatchedVideoCacheHelpers
