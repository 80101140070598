import axios  from 'axios'
import index from '@/router/index'

const base = axios.create({
  baseURL: `${process.env.VUE_APP_API}/api/v2`,
  headers: {
    Authorization: {
      toString () {
        return localStorage.getItem('auth._token.webconcert')
      }
    },
    'Content-Type': 'application/json; charset=utf-8',
    'Channel': window.location.host.replace('www.','').split('.')[0]
  }
})


base.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401 && index.currentRoute.name !== 'Login' && index.currentRoute.name !== 'PublicSession') {
    if (error.response.config.url === '/users/sso_sessions/check_token') {
      return Promise.reject(error.response)
    } else {
      let queryParams = ''
      if (index.currentRoute.name === 'Poster') {
        queryParams = { redirect_route: 'Posters' }
      } else if (index.currentRoute.name === 'Sponsor') {
        queryParams = { redirect_route: 'Sponsor', sponsor_id: index.currentRoute.query.sponsor_id }
      } else {
        queryParams = { redirect_route: 'Home' }
      }
      index.replace({ name: 'Login', query: queryParams })
    }
  } else {
    return Promise.reject(error)
  }
})

export default base
