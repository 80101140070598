import Vue from 'vue'
import Vuex from 'vuex'
import WatchedVideoCacheHelpers  from '@/utils/watched-video-cache-helpers'
import trackingApi               from '@/api/v1/watched-videos'
import { getField, updateField } from 'vuex-map-fields'

const camelcaseKeys = require('camelcase-keys')

Vue.use(Vuex)
const state = () => ({
  myWatchedVideoSummaries: [],
  playStartTime: null,
  lastPlayed: 0,
})

const getters = {
  watchedStatusTagTitle: state => sessionId => { 
    let index = state.myWatchedVideoSummaries.findIndex(summary => summary.sessionId === sessionId)
    if (index > -1) {
      return state.myWatchedVideoSummaries[index].watchedStatus === 'done' ? '시청완료' : '시청중'
    } else {
      return ''
    }
  },
  getField,
}

const actions = {
  trackWatchedVideo (_ctx, trackWatchedVideoParams) {
    return new Promise((resolve, reject) => {
      trackingApi.postWatchedVideo(trackWatchedVideoParams).then(() => {
        let cacheKey = WatchedVideoCacheHelpers.encodeCacheKey({
          eventID: trackWatchedVideoParams['event_id'],
          sessionID: trackWatchedVideoParams['session_id'],
          contentID: trackWatchedVideoParams['content_id'],
          startTime: trackWatchedVideoParams['start_time']
        })
        if (Vue.$cookies.isKey(cacheKey)) {
          WatchedVideoCacheHelpers.cleanupCache(cacheKey)
        }
        resolve()
      }).catch(() => {
        console.error('error tracking video')
        reject()
      })
    })
  },
  getMyWatchedVideoSummaries ({commit, rootGetters}) {
    return new Promise((resolve, reject) => {
      trackingApi.getMyWatchedVideoSummaries(rootGetters['events/showingEventId']).then((resp) => {
        commit('setMyWatchedVideoSummaries', camelcaseKeys(resp.watched_video_summaries, {deep: true}))
        resolve(camelcaseKeys(resp.watched_video_summaries, {deep: true}))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getMyWatchedVideoSummaryBySession ({rootGetters}, sessionId) {
    return new Promise((resolve, reject) => {
      trackingApi.getMyWatchedVideoSummaryBySession(rootGetters['events/showingEventId'], sessionId).then((resp) => {
        resolve(camelcaseKeys(resp.watched_video_summary, {deep: true}))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // trackWatchedVideo (_ctx, cacheKey) {
  //   return new Promise(resolve => {
  //     if (Vue.$cookies.isKey(cacheKey)) {
  //       let playedTimes = JSON.parse(Vue.$cookies.get(cacheKey))
  //       let params = WatchedVideoCacheHelpers.decodeCacheKey(cacheKey)
  //       params['played_times'] = playedTimes
  //       trackingApi.postWatchedVideo(params).then(() => {
  //         WatchedVideoCacheHelpers.cleanupCache(cacheKey)
  //         resolve()
  //       })
  //     } else {
  //       console.log('error tracking video')
  //       resolve()
  //     }
  //   })
  // },
  // trackWatchedVideos ({dispatch}) {
  //   // tracking for stale that hasnt been tracked yet
  //   let untrackedKeys = Vue.$cookies.keys().filter((cacheKey) => cacheKey.match(/\d+\|\d+\|\d+\|(.*)/))
  //   untrackedKeys.forEach((cacheKey, index) => {
  //     setTimeout(() => {
  //       dispatch('trackWatchedVideo', cacheKey)
  //     }, 3000 * index) // dont ddos ourselves
  //   })
  // },
  getLastWatchedTime (context, params) {
    return new Promise(resolve => {
      trackingApi.getLastWatchedTime(params).then(resp => {
        resolve(camelcaseKeys(resp, {deep:true}))
      })
    })
  },
  getWatchedTime ({ rootGetters }) {
    return new Promise(resolve => {
      trackingApi.getWatchedTime(rootGetters['events/showingEventId']).then(resp => {
        resolve(resp)
      })
    })
  },
  resetPlayStartTime ({commit}) {
    commit('setPlayStartTime', null)
  },
  resetLastPlayed ({commit}) {
    commit('setLastPlayed', 0)
  },
}

const mutations = {
  setMyWatchedVideoSummaries (state, fromApi) {
    state.myWatchedVideoSummaries = fromApi
  },
  setPlayStartTime (state, playStartTime) {
    state.playStartTime = playStartTime
  },
  setLastPlayed (state, lastPlayed) {
    state.lastPlayed = lastPlayed
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
