class ListOfAffiliationsHelpers {

  static listOfAffiliations (eventId) {
    if (eventId === 9) {
      // koa2022
      return [
        {
          'id': 1,
          'korean': '가천의대길병원',
          'english': 'GACHON UNIV.'
        },
        {
          'id': 2,
          'korean': '가톨릭 관동대학교 국제성모병원',
          'english': 'CATHOLIC UNIV.'
        },
        {
          'id': 3,
          'korean': '가톨릭대학교 성빈센트병원',
          'english': 'CATHOLIC UNIV.'
        },
        {
          'id': 4,
          'korean': '가톨릭대학교 은평성모병원',
          'english': 'CATHOLIC UNIV.'
        },
        {
          'id': 5,
          'korean': '가톨릭대학교 의정부성모병원',
          'english': 'CATHOLIC UNIV.'
        },
        {
          'id': 6,
          'korean': '가톨릭의대 대전성모병원',
          'english': 'CATHOLIC UNIV.'
        },
        {
          'id': 7,
          'korean': '가톨릭의대 부천성모병원',
          'english': 'CATHOLIC UNIV.'
        },
        {
          'id': 8,
          'korean': '가톨릭의대 서울성모병원',
          'english': 'CATHOLIC UNIV.'
        },
        {
          'id': 9,
          'korean': '가톨릭의대 여의도성모병원',
          'english': 'CATHOLIC UNIV.'
        },
        {
          'id': 10,
          'korean': '강동경희대학교병원',
          'english': 'KYUNG HEE UNIV.'
        },
        {
          'id': 11,
          'korean': '강릉아산병원',
          'english': 'ULSAN UNIV.'
        },
        {
          'id': 12,
          'korean': '강북삼성병원',
          'english': 'SUNGKYUNKWAN UNIV.'
        },
        {
          'id': 13,
          'korean': '강원대학병원',
          'english': 'KANGWON UNIV.'
        },
        {
          'id': 14,
          'korean': '건국대학교병원',
          'english': 'KONKUK UNIV.'
        },
        {
          'id': 15,
          'korean': '건양대학교병원',
          'english': 'KONYANG UNIV.'
        },
        {
          'id': 16,
          'korean': '경북대학교병원',
          'english': 'KYUNGPOOK NATIONAL UNIV.'
        },
        {
          'id': 17,
          'korean': '경상대학병원',
          'english': 'GYEONGSANG NATIONAL UNIV.'
        },
        {
          'id': 18,
          'korean': '경희대학교병원',
          'english': 'KYUNG HEE UNIV.'
        },
        {
          'id': 19,
          'korean': '계명대동산의료원',
          'english': 'KEIMYUNG UNIV.'
        },
        {
          'id': 20,
          'korean': '고대구로병원',
          'english': 'KOREA UNIV.'
        },
        {
          'id': 21,
          'korean': '고대안산병원',
          'english': 'KOREA UNIV.'
        },
        {
          'id': 22,
          'korean': '고대안암병원',
          'english': 'KOREA UNIV.'
        },
        {
          'id': 23,
          'korean': '고신대복음병원',
          'english': 'KOSIN UNIV.'
        },
        {
          'id': 24,
          'korean': '광명성애병원',
          'english': 'SUNG-AE GENERAL HOSP.'
        },
        {
          'id': 25,
          'korean': '광주기독병원',
          'english': 'KWANGJU CHRISTIAN HOSP.'
        },
        {
          'id': 26,
          'korean': '광주보훈병원',
          'english': 'VETERANS HEALTH SERVICE MEDICAL CENTER HOSP.'
        },
        {
          'id': 27,
          'korean': '국군수도병원',
          'english': 'ARMED FORCES CAPITAL HOSP.'
        },
        {
          'id': 28,
          'korean': '국립경찰병원',
          'english': 'NATIONAL POLICE HOSP.'
        },
        {
          'id': 29,
          'korean': '국립중앙의료원',
          'english': 'NATIONAL MEDICAL CENTER'
        },
        {
          'id': 30,
          'korean': '국민건강보험공단 일산병원',
          'english': 'NHIS ILSAN HOSP.'
        },
        {
          'id': 31,
          'korean': '노원을지병원',
          'english': 'EULJI UNIV.'
        },
        {
          'id': 32,
          'korean': '단국대학병원',
          'english': 'DANKOOK UNIV.'
        },
        {
          'id': 33,
          'korean': '대구가톨릭대학교병원',
          'english': 'DAEGU CATHOLIC UNIV.'
        },
        {
          'id': 34,
          'korean': '대구파티마병원',
          'english': 'DAEGU FATIMA HOSP.'
        },
        {
          'id': 35,
          'korean': '대전선병원',
          'english': 'DAEJEON SUN HOSP.'
        },
        {
          'id': 36,
          'korean': '동국대경주병원',
          'english': 'DONGGUK UNIV.'
        },
        {
          'id': 37,
          'korean': '동국대일산병원',
          'english': 'DONGGUK UNIV.'
        },
        {
          'id': 38,
          'korean': '동아대학병원',
          'english': 'DONG-A UNIV.'
        },
        {
          'id': 39,
          'korean': '명지병원',
          'english': 'MYUNGJI HOSP.'
        },
        {
          'id': 40,
          'korean': '부민병원',
          'english': 'BUMIN HOSP.'
        },
        {
          'id': 41,
          'korean': '부산대학병원',
          'english': 'PUSAN NATIONAL UNIV.'
        },
        {
          'id': 42,
          'korean': '부산백병원',
          'english': 'INJE UNIV.'
        },
        {
          'id': 43,
          'korean': '부산의료원',
          'english': 'BUSAN MEDICAL CENTER.'
        },
        {
          'id': 44,
          'korean': '분당서울대학교병원',
          'english': 'SEOUL NATIONAL UNIV.'
        },
        {
          'id': 45,
          'korean': '분당제생병원',
          'english': 'BUNDANG JESAENG HOSP.'
        },
        {
          'id': 46,
          'korean': '빛고을전남대학병원',
          'english': 'CHONNAM NATIONAL UNIV.'
        },
        {
          'id': 47,
          'korean': '삼성서울병원',
          'english': 'SUNGKYUNKWAN UNIV.'
        },
        {
          'id': 48,
          'korean': '삼육서울병원',
          'english': 'SAHMYOOK MEDICAL CENTER.'
        },
        {
          'id': 49,
          'korean': '상계백병원',
          'english': 'INJE UNIV.'
        },
        {
          'id': 50,
          'korean': '서울대 보라매병원',
          'english': 'SEOUL NATIONAL UNIV.'
        },
        {
          'id': 51,
          'korean': '서울대학병원',
          'english': 'SEOUL NATIONAL UNIV.'
        },
        {
          'id': 52,
          'korean': '서울백병원',
          'english': 'INJE UNIV.'
        },
        {
          'id': 53,
          'korean': '서울성심병원',
          'english': 'SEOUL SACRED HEART GENERAL HOSPITAL'
        },
        {
          'id': 54,
          'korean': '서울아산병원',
          'english': 'ULSAN UNIV.'
        },
        {
          'id': 55,
          'korean': '서울의료원',
          'english': 'SEOUL MEDICAL CENTER'
        },
        {
          'id': 56,
          'korean': '서울적십자병원',
          'english': 'SEOUL REDCROSS HOSP.'
        },
        {
          'id': 57,
          'korean': '성가롤로병원',
          'english': 'ST.CAROLLO HOSP.'
        },
        {
          'id': 58,
          'korean': '성균관대학교 삼성창원병원',
          'english': 'SUNGKYUNKWAN UNIV.'
        },
        {
          'id': 59,
          'korean': '성애병원',
          'english': 'SUNG-AE GENERAL HOSP.'
        },
        {
          'id': 60,
          'korean': '순천향대학 천안병원',
          'english': 'SOONCHUNHYANG UNIV.'
        },
        {
          'id': 61,
          'korean': '순천향대학병원',
          'english': 'SOONCHUNHYANG UNIV.'
        },
        {
          'id': 62,
          'korean': '순천향부천병원',
          'english': 'SOONCHUNHYANG UNIV.'
        },
        {
          'id': 63,
          'korean': '아주대학병원',
          'english': 'AJOU UNIV.'
        },
        {
          'id': 64,
          'korean': '양산부산대학교병원',
          'english': 'PUSAN NATIONAL UNIV.'
        },
        {
          'id': 65,
          'korean': '연세대 강남세브란스병원',
          'english': 'YONSEI UNIV.'
        },
        {
          'id': 66,
          'korean': '연세대 세브란스병원',
          'english': 'YONSEI UNIV.'
        },
        {
          'id': 67,
          'korean': '연세대학교 원주세브란스기독병원',
          'english': 'YONSEI UNIV.'
        },
        {
          'id': 68,
          'korean': '영남대학병원',
          'english': 'YEUNGNAM UNIV.'
        },
        {
          'id': 69,
          'korean': '울산대학병원',
          'english': 'ULSAN UNIV.'
        },
        {
          'id': 70,
          'korean': '원광대학병원 산본병원',
          'english': 'WONKWANG UNIV.'
        },
        {
          'id': 71,
          'korean': '원광대학병원',
          'english': 'WONKWANG UNIV.'
        },
        {
          'id': 72,
          'korean': '원자력병원',
          'english': 'KOREA CANCER CENTER HOSP.'
        },
        {
          'id': 73,
          'korean': '을지대학병원(관절센터)',
          'english': 'EULJI UNIV.'
        },
        {
          'id': 74,
          'korean': '이대목동병원',
          'english': 'EWHA WOMANS UNIV.'
        },
        {
          'id': 75,
          'korean': '이대서울병원',
          'english': 'EWHA WOMANS UNIV.'
        },
        {
          'id': 76,
          'korean': '인제대학교부속 해운대백병원',
          'english': 'INJE UNIV.'
        },
        {
          'id': 77,
          'korean': '인천성모병원',
          'english': 'CATHOLIC UNIV.'
        },
        {
          'id': 78,
          'korean': '인하대학병원',
          'english': 'INHA UNIV.'
        },
        {
          'id': 79,
          'korean': '일산백병원',
          'english': 'INJE UNIV.'
        },
        {
          'id': 80,
          'korean': '전남대학병원',
          'english': 'CHONNAM NATIONAL UNIV.'
        },
        {
          'id': 81,
          'korean': '전북대학교병원',
          'english': 'JEONBUK NATIONAL UNIV.'
        },
        {
          'id': 82,
          'korean': '전주예수병원',
          'english': 'PRESBYTERIAN MEDICAL CENTER'
        },
        {
          'id': 83,
          'korean': '제주대학병원',
          'english': 'JEJU NATIONAL UNIV.'
        },
        {
          'id': 84,
          'korean': '제주한라병원',
          'english': 'JEJU HALLA GENERAL HOSP.'
        },
        {
          'id': 85,
          'korean': '조선대학병원',
          'english': 'CHOSUN UNIV.'
        },
        {
          'id': 86,
          'korean': '좋은삼선병원',
          'english': 'GOOD SAMSUN HOSP.'
        },
        {
          'id': 87,
          'korean': '중앙대학교병원',
          'english': 'CHUNG-ANG UNIV.'
        },
        {
          'id': 88,
          'korean': '중앙보훈병원',
          'english': 'VETERANS HEALTH SERVICE MEDICAL CENTER HOSP.'
        },
        {
          'id': 89,
          'korean': '차의과대학교 구미차병원',
          'english': 'CHA UNIV.'
        },
        {
          'id': 90,
          'korean': '차의과대학교 분당차병원',
          'english': 'CHA UNIV.'
        },
        {
          'id': 91,
          'korean': '창원경상대학교병원',
          'english': 'GYEONGSANG NATIONAL UNIV.'
        },
        {
          'id': 92,
          'korean': '충남대학병원',
          'english': 'CHUNGNAM NATIONAL UNIV.'
        },
        {
          'id': 93,
          'korean': '충북대학병원',
          'english': 'CHUNGBUK NATIONAL UNIV.'
        },
        {
          'id': 94,
          'korean': '포항성모병원',
          'english': "POHANG ST. MARY'S HOSP."
        },
        {
          'id': 95,
          'korean': '한림대성심병원',
          'english': 'HALLYM UNIV.'
        },
        {
          'id': 96,
          'korean': '한림의대 강남성심병원',
          'english': 'HALLYM UNIV.'
        },
        {
          'id': 97,
          'korean': '한림의대 강동성심병원',
          'english': 'HALLYM UNIV.'
        },
        {
          'id': 98,
          'korean': '한림의대 동탄성심병원',
          'english': 'HALLYM UNIV.'
        },
        {
          'id': 99,
          'korean': '한림의대 춘천성심병원',
          'english': 'HALLYM UNIV.'
        },
        {
          'id': 100,
          'korean': '한양대구리병원',
          'english': 'HANYANG UNIV.'
        },
        {
          'id': 101,
          'korean': '한양대학병원',
          'english': 'HANYANG UNIV.'
        },
        {
          'id': 102,
          'korean': '한일병원',
          'english': 'HANIL GENERAL HOSP.'
        },
        {
          'id': 103,
          'korean': '현대병원',
          'english': 'HYUNDAE GENERAL HOSP.'
        },
        {
          'id': 104,
          'korean': '화순전남대학병원',
          'english': 'CHONNAM NATIONAL UNIV.'
        }
      ]
    } else if (eventId === 32) {
      return [
        {
          "korean": "가천대학교 길병원",
          "english": "GACHON UNIV.",
          "id": 1
        },
        {
          "korean": "가톨릭관동대학교 국제성모병원",
          "english": "CATHOLIC KWANDONG UNIV.",
          "id": 2
        },
        {
          "korean": "가톨릭대학교 대전성모병원",
          "english": "CATHOLIC UNIV.",
          "id": 3
        },
        {
          "korean": "가톨릭대학교 부천성모병원",
          "english": "CATHOLIC UNIV.",
          "id": 4
        },
        {
          "korean": "가톨릭대학교 서울성모병원",
          "english": "CATHOLIC UNIV.",
          "id": 5
        },
        {
          "korean": "가톨릭대학교 성빈센트병원",
          "english": "CATHOLIC UNIV.",
          "id": 6
        },
        {
          "korean": "가톨릭대학교 여의도성모병원",
          "english": "CATHOLIC UNIV.",
          "id": 7
        },
        {
          "korean": "가톨릭대학교 은평성모병원",
          "english": "CATHOLIC UNIV.",
          "id": 8
        },
        {
          "korean": "가톨릭대학교 의정부성모병원",
          "english": "CATHOLIC UNIV.",
          "id": 9
        },
        {
          "korean": "가톨릭대학교 인천성모병원",
          "english": "CATHOLIC UNIV.",
          "id": 10
        },
        {
          "korean": "강남 을지대학교병원",
          "english": "EULJI UNIV.",
          "id": 11
        },
        {
          "korean": "강동경희대학교병원",
          "english": "KYUNG HEE UNIV.",
          "id": 12
        },
        {
          "korean": "강릉아산병원",
          "english": "UNIV. OF ULSAN",
          "id": 13
        },
        {
          "korean": "강원대학교병원",
          "english": "KANGWON UNIV.",
          "id": 14
        },
        {
          "korean": "건국대학교병원",
          "english": "KONKUK UNIV.",
          "id": 15
        },
        {
          "korean": "건양대학교병원",
          "english": "KONYANG UNIV.",
          "id": 16
        },
        {
          "korean": "경북대학교병원",
          "english": "KYUNGPOOK NATIONAL UNIV.",
          "id": 17
        },
        {
          "korean": "경상국립대학교병원",
          "english": "GYEONGSANG NATIONAL UNIV.",
          "id": 18
        },
        {
          "korean": "경희대학교병원",
          "english": "KYUNG HEE UNIV.",
          "id": 19
        },
        {
          "korean": "경희의료원",
          "english": "KYUNG HEE UNIV.",
          "id": 20
        },
        {
          "korean": "계명대학교 동산의료원",
          "english": "KEIMYUNG UNIV.",
          "id": 21
        },
        {
          "korean": "고려대학교 구로병원",
          "english": "KOREA UNIV.",
          "id": 22
        },
        {
          "korean": "고려대학교 안산병원",
          "english": "KOREA UNIV.",
          "id": 23
        },
        {
          "korean": "고려대학교 안암병원",
          "english": "KOREA UNIV.",
          "id": 24
        },
        {
          "korean": "고신대학교 복음병원",
          "english": "KOSIN UNIV.",
          "id": 25
        },
        {
          "korean": "광명성애병원",
          "english": "SUNG-AE GENERAL HOSP.",
          "id": 26
        },
        {
          "korean": "광주기독병원",
          "english": "KWANGJU CHRISTIAN HOSP.",
          "id": 27
        },
        {
          "korean": "광주보훈병원",
          "english": "VETERANS HEALTH SERVICE MEDICAL CENTER HOSP.",
          "id": 28
        },
        {
          "korean": "국군수도병원",
          "english": "ARMED FORCES CAPITAL HOSP.",
          "id": 29
        },
        {
          "korean": "국립경찰병원",
          "english": "NATIONAL POLICE HOSP.",
          "id": 30
        },
        {
          "korean": "국립중앙의료원",
          "english": "NATIONAL MEDICAL CENTER",
          "id": 31
        },
        {
          "korean": "국민건강보험공단 일산병원",
          "english": "NHIS ILSAN HOSP.",
          "id": 32
        },
        {
          "korean": "노원 을지대학교병원",
          "english": "EULJI UNIV.",
          "id": 33
        },
        {
          "korean": "단국대학교병원",
          "english": "DANKOOK UNIV.",
          "id": 34
        },
        {
          "korean": "대구가톨릭대학교병원",
          "english": "DAEGU CATHOLIC UNIV.",
          "id": 35
        },
        {
          "korean": "대구파티마병원",
          "english": "DAEGU FATIMA HOSP.",
          "id": 36
        },
        {
          "korean": "대전 을지대학교병원",
          "english": "EULJI UNIV.",
          "id": 37
        },
        {
          "korean": "대전선병원",
          "english": "DAEJEON SUN HOSP.",
          "id": 38
        },
        {
          "korean": "동국대학교 경주병원",
          "english": "DONGGUK UNIV.",
          "id": 39
        },
        {
          "korean": "동국대학교 일산병원",
          "english": "DONGGUK UNIV.",
          "id": 40
        },
        {
          "korean": "동아대학교병원",
          "english": "DONG-A UNIV.",
          "id": 41
        },
        {
          "korean": "명지병원",
          "english": "HANYANG UNIV.",
          "id": 42
        },
        {
          "korean": "부민병원",
          "english": "BUMIN HOSP.",
          "id": 43
        },
        {
          "korean": "부산대학교병원",
          "english": "PUSAN NATIONAL UNIV.",
          "id": 44
        },
        {
          "korean": "부산의료원",
          "english": "BUSAN MEDICAL CENTER.",
          "id": 45
        },
        {
          "korean": "분당서울대학교병원",
          "english": "SEOUL NATIONAL UNIV.",
          "id": 46
        },
        {
          "korean": "분당제생병원",
          "english": "BUNDANG JESAENG HOSP.",
          "id": 47
        },
        {
          "korean": "빛고을 전남대학교병원",
          "english": "CHONNAM NATIONAL UNIV.",
          "id": 48
        },
        {
          "korean": "삼육서울병원",
          "english": "SAHMYOOK MEDICAL CENTER.",
          "id": 49
        },
        {
          "korean": "서울대학교 보라매병원",
          "english": "SEOUL NATIONAL UNIV.",
          "id": 50
        },
        {
          "korean": "서울대학교병원",
          "english": "SEOUL NATIONAL UNIV.",
          "id": 51
        },
        {
          "korean": "서울성심병원",
          "english": "SEOUL SACRED HEART GENERAL HOSPITAL",
          "id": 52
        },
        {
          "korean": "서울아산병원",
          "english": "UNIV. OF ULSAN",
          "id": 53
        },
        {
          "korean": "서울의료원",
          "english": "SEOUL MEDICAL CENTER",
          "id": 54
        },
        {
          "korean": "서울적십자병원",
          "english": "SEOUL REDCROSS HOSP.",
          "id": 55
        },
        {
          "korean": "성가롤로병원",
          "english": "ST.CAROLLO HOSP.",
          "id": 56
        },
        {
          "korean": "성균관대학교 강북삼성병원",
          "english": "SUNGKYUNKWAN UNIV.",
          "id": 57
        },
        {
          "korean": "성균관대학교 삼성서울병원",
          "english": "SUNGKYUNKWAN UNIV.",
          "id": 58
        },
        {
          "korean": "성균관대학교 삼성창원병원",
          "english": "SUNGKYUNKWAN UNIV.",
          "id": 59
        },
        {
          "korean": "성애병원",
          "english": "SUNG-AE GENERAL HOSP.",
          "id": 60
        },
        {
          "korean": "순천향대학교 부천병원",
          "english": "SOONCHUNHYANG UNIV.",
          "id": 61
        },
        {
          "korean": "순천향대학교 천안병원",
          "english": "SOONCHUNHYANG UNIV.",
          "id": 62
        },
        {
          "korean": "순천향대학교병원",
          "english": "SOONCHUNHYANG UNIV.",
          "id": 63
        },
        {
          "korean": "아주대학교병원",
          "english": "AJOU UNIV.",
          "id": 64
        },
        {
          "korean": "양산부산대학교병원",
          "english": "PUSAN NATIONAL UNIV.",
          "id": 65
        },
        {
          "korean": "연세대학교 강남세브란스병원",
          "english": "YONSEI UNIV.",
          "id": 66
        },
        {
          "korean": "연세대학교 세브란스병원",
          "english": "YONSEI UNIV.",
          "id": 67
        },
        {
          "korean": "연세대학교 원주세브란스기독병원",
          "english": "YONSEI UNIV. WONJU COLLEGE OF MEDICINE",
          "id": 68
        },
        {
          "korean": "영남대학교병원",
          "english": "YEUNGNAM UNIV.",
          "id": 69
        },
        {
          "korean": "울산대학교병원",
          "english": "UNIV. OF ULSAN",
          "id": 70
        },
        {
          "korean": "원광대학교 산본병원",
          "english": "WONKWANG UNIV.",
          "id": 71
        },
        {
          "korean": "원광대학교병원",
          "english": "WONKWANG UNIV.",
          "id": 72
        },
        {
          "korean": "원자력병원",
          "english": "KOREA CANCER CENTER HOSP.",
          "id": 73
        },
        {
          "korean": "의정부 을지대학교병원",
          "english": "EULJI UNIV.",
          "id": 74
        },
        {
          "korean": "이화여자대학교 목동병원",
          "english": "EWHA WOMANS UNIV.",
          "id": 75
        },
        {
          "korean": "이화여자대학교 서울병원",
          "english": "EWHA WOMANS UNIV.",
          "id": 76
        },
        {
          "korean": "인제대학교 부산백병원",
          "english": "INJE UNIV.",
          "id": 77
        },
        {
          "korean": "인제대학교 상계백병원",
          "english": "INJE UNIV.",
          "id": 78
        },
        {
          "korean": "인제대학교 서울백병원",
          "english": "INJE UNIV.",
          "id": 79
        },
        {
          "korean": "인제대학교 일산백병원",
          "english": "INJE UNIV.",
          "id": 80
        },
        {
          "korean": "인제대학교 해운대백병원",
          "english": "INJE UNIV.",
          "id": 81
        },
        {
          "korean": "인하대학교병원",
          "english": "INHA UNIV.",
          "id": 82
        },
        {
          "korean": "전남대학교병원",
          "english": "CHONNAM NATIONAL UNIV.",
          "id": 83
        },
        {
          "korean": "전북대학교병원",
          "english": "JEONBUK NATIONAL UNIV.",
          "id": 84
        },
        {
          "korean": "전주예수병원",
          "english": "PRESBYTERIAN MEDICAL CENTER",
          "id": 85
        },
        {
          "korean": "제주대학교병원",
          "english": "JEJU NATIONAL UNIV.",
          "id": 86
        },
        {
          "korean": "제주한라병원",
          "english": "JEJU HALLA GENERAL HOSP.",
          "id": 87
        },
        {
          "korean": "조선대학교병원",
          "english": "CHOSUN UNIV.",
          "id": 88
        },
        {
          "korean": "좋은삼선병원",
          "english": "GOOD SAMSUN HOSP.",
          "id": 89
        },
        {
          "korean": "중앙대학교 광명병원",
          "english": "CHUNG-ANG UNIV.",
          "id": 90
        },
        {
          "korean": "중앙대학교병원",
          "english": "CHUNG-ANG UNIV.",
          "id": 91
        },
        {
          "korean": "중앙보훈병원",
          "english": "VETERANS HEALTH SERVICE MEDICAL CENTER HOSP.",
          "id": 92
        },
        {
          "korean": "차의과대학교 구미차병원",
          "english": "CHA UNIV.",
          "id": 93
        },
        {
          "korean": "차의과대학교 분당차병원",
          "english": "CHA UNIV.",
          "id": 94
        },
        {
          "korean": "창원경상국립대학교병원",
          "english": "GYEONGSANG NATIONAL UNIV.",
          "id": 95
        },
        {
          "korean": "충남대학교병원",
          "english": "CHUNGNAM NATIONAL UNIV.",
          "id": 96
        },
        {
          "korean": "충북대학교병원",
          "english": "CHUNGBUK NATIONAL UNIV.",
          "id": 97
        },
        {
          "korean": "포항성모병원",
          "english": "POHANG ST. MARY'S HOSP.",
          "id": 98
        },
        {
          "korean": "한림대학교 강남성심병원",
          "english": "HALLYM UNIV.",
          "id": 99
        },
        {
          "korean": "한림대학교 동탄성심병원",
          "english": "HALLYM UNIV.",
          "id": 100
        },
        {
          "korean": "한림대학교 성심병원",
          "english": "HALLYM UNIV.",
          "id": 101
        },
        {
          "korean": "한림대학교 춘천성심병원",
          "english": "HALLYM UNIV.",
          "id": 102
        },
        {
          "korean": "한림대학교 한강성심병원",
          "english": "HALLYM UNIV.",
          "id": 103
        },
        {
          "korean": "한림대학교의료원",
          "english": "HALLYM UNIV.",
          "id": 104
        },
        {
          "korean": "한양대학교 구리병원",
          "english": "HANYANG UNIV.",
          "id": 105
        },
        {
          "korean": "한양대학교병원",
          "english": "HANYANG UNIV.",
          "id": 106
        },
        {
          "korean": "한일병원",
          "english": "HANIL GENERAL HOSP.",
          "id": 107
        },
        {
          "korean": "현대병원",
          "english": "HYUNDAE GENERAL HOSP.",
          "id": 108
        },
        {
          "korean": "화순전남대학교병원",
          "english": "CHONNAM NATIONAL UNIV.",
          "id": 109
        }
      ]
    } else {
      return []
    }
  }
}

export default ListOfAffiliationsHelpers
