import base from '../base'

const postProfile = (params) => {
  return base.post('/users/me/profile', params).then(resp => resp.data)
}

const getProfile = (params) => {
  return base.get('/users/me/profile', {'params': params}).then(resp => resp.data)
}

const patchProfile = (params) => {
  return base.patch('/users/me/profile', params).then(resp => resp.data)
}

const getMembershipData = (params) => {
  return base.get('/users/me/membership_data', {'params': params}).then(resp => resp.data)
}

const patchMembershipData = (params) => {
  return base.patch('/users/me/membership_data', params).then(resp => resp.data)
}

const getRegistrationData = (params) => {
  return base.get('/users/me/registration_data', {'params': params}).then(resp => resp.data)
}

const patchRegistrationData = (params) => {
  return base.patch('/users/me/registration_data', params).then(resp => resp.data)
}

const getTerms = (params) => {
  return base.get('/users/me/terms', {'params': params}).then(resp => resp.data)
}

const patchTerms = (params) => {
  return base.patch('/users/me/terms', params).then(resp => resp.data)
}

const getAgeType = (params) => {
  return base.get('/age_type', {'params': params}).then(resp => resp.data)
}

export default {
  getProfile:          getProfile,
  getMembershipData:   getMembershipData,
  postProfile:         postProfile,
  patchProfile:        patchProfile,
  patchMembershipData: patchMembershipData,
  getRegistrationData: getRegistrationData,
  patchRegistrationData: patchRegistrationData,
  getTerms: getTerms,
  patchTerms: patchTerms,
  getAgeType: getAgeType,
}
