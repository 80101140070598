import Vue from 'vue'
import Vuex from 'vuex'
import sessionQuizzesApi from '@/api/v1/session-quizzes'

Vue.use(Vuex)

const state = () => ({
  sessionQuizzes: [],
})

const actions = {
  getSessionQuizzes ({ commit }, sessionId) {
    return new Promise(resolve => {
      let params = {
        session_id: sessionId
      }
      sessionQuizzesApi.getSessionQuizzes(params).then(resp => {
        commit('setSessionQuizzes', resp.session_quizzes)
        resolve()
      })
    })
  },
  subscribeToQuizzes ({ commit, dispatch }, sessionId) {
    let channel = `session:${sessionId};quizzes`
    dispatch('realtimeSubscriptions/subscribe',
      {channel: channel, event: 'quizCreated', callback: (message) => {
        commit('addUpdateCreatedQuizToSessionQuizzes', message.data.session_quiz)
      }},
      { root: true })
    dispatch('realtimeSubscriptions/subscribe',
      {channel: channel, event: 'quizUpdated', callback: (message) => {
        commit('addUpdateCreatedQuizToSessionQuizzes', message.data.session_quiz)
      }},
      { root: true })
    dispatch('realtimeSubscriptions/subscribe',
      {channel: channel, event: 'quizDeleted', callback: (message) => {
        commit('removeSessionQuiz', message.data.session_quiz)
      }},
      { root: true })
  },
  unsubscribeFromQuizzes ({ dispatch, rootGetters }) {
    let subs = rootGetters['realtimeSubscriptions/currentSubscriptions'].filter(sub => sub.channel.includes(';quizzes'))
    subs.forEach(sub => dispatch('realtimeSubscriptions/unsubscribe', {channel: sub.channel}, { root: true }))
  },
}

const mutations = {
  addUpdateCreatedQuizToSessionQuizzes (state, sessionQuiz) {
    let index = state.sessionQuizzes.findIndex(quiz => quiz.id === sessionQuiz.id)
    if (index >= 0) {
      state.sessionQuizzes.splice(index, 1, sessionQuiz)
    } else {
      state.sessionQuizzes.push(sessionQuiz)
    }
  },
  setSessionQuizzes (state, sessionQuizzes) {
    state.sessionQuizzes = sessionQuizzes
  },
  removeSessionQuiz (state, sessionQuiz) {
    let index = state.sessionQuizzes.findIndex(quiz => quiz.id === sessionQuiz.id)
    if (index >= 0) {
      state.sessionQuizzes.splice(index, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
