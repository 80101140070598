<template>
  <div>
    <div class='mb-6' v-if='isKorean'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Affiliation (국문)</label>
      <select
        v-model='selectedAffiliationKorean'
        @change='updateInput'
        class='border py-1 px-2 rounded block w-full lg:w-80'>
        <option disabled></option>
        <option v-for='affiliation in affiliations'
          :key='`affiliation-${affiliation.id}`'
          :value='affiliation.korean'>
          {{affiliation.korean}}
        </option>
        <option value='other'>Other</option>
      </select>
      <input
        v-if='notListedAffiliationKorean'
        v-model='componentAffiliationVal'
        class='border py-1 px-2 rounded block w-full lg:w-80 mt-2'
        type='text'
        placeholder='Other Affiliation (국문)'>
    </div>
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Affiliation (English)</label>
      <input
        v-model='componentOrganizationVal'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        @input='blockKoreanInput($event)'
        placeholder='Affiliation (English)'>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListOfAffiliationsHelpers  from '@/utils/list-of-affiliations-helpers.js'

export default {
  name: 'FormFieldAffiliation',
  props: [
    'hospitalName',
    'organization',
    'isKorean',
  ],
  data () {
    return {
      selectedAffiliationKorean: '',
      componentAffiliationVal: '',
      componentOrganizationVal: '',
    }
  },
  watch: {
    hospitalName: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let index = this.affiliations.map((item) => item.korean).indexOf(newVal)
          if (index > -1) {
            this.selectedAffiliationKorean = this.affiliations[index].korean
            this.componentOrganizationVal = this.affiliations[index].english
            this.componentAffiliationVal   = this.affiliations[index].korean
          } else {
            this.selectedAffiliationKorean = 'other'
            this.componentAffiliationVal   = this.hospitalName
          }
        } else if (newVal === '') {
          this.selectedAffiliationKorean = ''
          this.componentAffiliationVal   = ''
          this.componentOrganizationVal  = ''
        }
      },
      immediate: true 
    },
    componentAffiliationVal: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.$emit('update:hospitalName', newVal)
        }
      }, 
      immediate: true
    },
    organization: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.componentOrganizationVal = newVal
        }
      },
      immediate: true 
    },
    componentOrganizationVal: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.$emit('update:organization', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId',
    ]),
    affiliations () {
      return ListOfAffiliationsHelpers.listOfAffiliations(this.showingEventId)
    },
    notListedAffiliationKorean () {
      return this.selectedAffiliationKorean.toLowerCase() === 'other'
    },
  },
  methods: {
    blockKoreanInput (event) {
      event.target.value = event.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig, '')
    },
    updateInput () {
      if (this.selectedAffiliationKorean.toLowerCase() !== 'other') {
        this.componentAffiliationVal = this.selectedAffiliationKorean
      } else {
        this.componentAffiliationVal = ''
      }
    },
  },
}
</script>