import base from './base'

const getMyPageData = (eventId) => {
  return base.get(`/users/my_page`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp.data)
}

export default {
  getMyPageData: getMyPageData
}
