import Vue from 'vue'
import Vuex from 'vuex'
import userActionsApi from '../../../src/api/v1/user-actions'
import router         from '@/router/index'

Vue.use(Vuex)
// const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

const state = () => ({
})

const getters = {

}

const actions = {
  createUserAction ({ rootGetters }, { userActionName, userActionData }) {
    return new Promise(resolve => {
      let userActionParams = {
        eventId: rootGetters['events/showingEventId'],
        platformName: 'webinar_client',
        platformPage: router.history.current.name,
        actionName: userActionName,
        data: userActionData
      }
      userActionsApi.postUserAction(snakecaseKeys(userActionParams, {deep: true})).then(resp => {
        resolve(resp)
      })
    })
  },
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
