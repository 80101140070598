import Vue                               from 'vue'
import VueRouter                         from 'vue-router'

const Main                          = () => import('@/views/kai2022/Main.vue')
const Session                       = () => import('@/views/demo/Session.vue')

const Login                         = () => import('@/views/demo/Login.vue')
const Home                          = () => import('@/views/demo/Home.vue')
const MyPage                        = () => import('@/views/demo/MyPage.vue')
const Notices                       = () => import('@/views/demo/Notices.vue')
const Poster                        = () => import('@/views/demo/Poster.vue')
const Posters                       = () => import('@/views/demo/Posters.vue')
const PosterUpload                  = () => import('@/views/demo/PosterUpload.vue')
const PosterUploadCompleted         = () => import('@/views/demo/PosterUploadCompleted.vue')
const Programs                      = () => import('@/views/demo/Programs.vue')
const ProgramDetails                = () => import('@/views/demo/ProgramDetails.vue')
const Sponsors                      = () => import('@/views/demo/Sponsors.vue')
const Sponsor                       = () => import('@/views/demo/Sponsor.vue')
const Live                          = () => import('@/views/demo/Live.vue')
const WaitPage                      = () => import('@/views/demo/WaitPage.vue')
const Welcome                       = () => import('@/views/demo/Welcome.vue')
const Qna                           = () => import('@/views/demo/Qna.vue')
const Speakers                      = () => import('@/views/koa2022/Speakers.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Main' 
    }
  },
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      defaultToToday: true,
    }
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: ProgramDetails
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices
  },
  {
    path: '/poster-upload',
    name: 'PosterUpload',
    component: PosterUpload
  },
  {
    path: '/poster-upload-completed',
    name: 'PosterUploadCompleted',
    component: PosterUploadCompleted
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: Sponsors
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: Sponsor
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage,
  },
  {
    path: '/posters',
    name: 'Posters',
    component: Posters
  },
  {
    path: '/poster',
    name: 'Poster',
    component: Poster
  },
  {
    path: '/live',
    name: 'Live',
    component: Live
  },
  {
    path: '/wait-page',
    name: 'WaitPage',
    component: WaitPage,
    props: {
      waitingMessage: 'E-POSTER는 4/14(목) 오픈 예정입니다.'
    }
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers
  },
  {
    path: '/qna',
    name: 'Qna',
    component: Qna
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
