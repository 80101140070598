import Vue               from 'vue'
import VueRouter         from 'vue-router'

const Home                = () => import('@/views/demo/Home.vue')
const Notices             = () => import('@/views/demo/Notices.vue')
const Poster              = () => import('@/views/demo/Poster.vue'  )
const Posters             = () => import('@/views/demo/Posters.vue')
const Programs            = () => import('@/views/demo/Programs.vue')
const Sponsor             = () => import('@/views/demo/Sponsor.vue')
const Sponsors            = () => import('@/views/demo/Sponsors.vue')
const CertificateDownload = () => import('@/views/demo/CertificateDownload.vue')
const Live                = () => import('@/views/demo/Live.vue')
const ProgramDetails      = () => import('@/views/demo/ProgramDetails.vue')


const Login             = () => import('@/views/khrs-virtual/Login.vue')
const Session           = () => import('@/views/khrs-virtual/Session.vue')
const Welcome           = () => import('@/views/khrs-virtual/Welcome.vue')
const Overview          = () => import('@/views/khrs-virtual/Overview.vue')
const ContactUs         = () => import('@/views/khrs-virtual/ContactUs.vue')
const KhrsSpecial       = () => import('@/views/khrs-virtual/KhrsSpecial.vue')
const Speakers          = () => import('@/views/khrs-virtual/Speakers.vue')
const MyPage            = () => import('@/views/khrs-virtual/MyPage.vue')
const ExhibitionHall    = () => import('@/views/khrs-virtual/ExhibitionHall.vue')
const ParticipantImages = () => import('@/views/khrs-virtual/ParticipantImages.vue')
const PromotionalEvents = () => import('@/views/khrs-virtual/PromotionalEvents.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'MyPage' 
    }
  },
  {
    path: '/khrs-special',
    name: 'KhrsSpecial',
    component: KhrsSpecial
  },
  {
    path: '/exhibition-hall',
    name: 'ExhibitionHall',
    component: ExhibitionHall
  },
  {
    path: '/posters',
    name: 'Posters',
    component: Posters,
    props: {
      showSponsorsCarousel: true,
    },
  },
  {
    path: '/poster-view',
    name: 'Poster',
    component: Poster
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      defaultToToday: true,
      showScheduleFullWidth: true,
      programSlotSize: '8rem',
    }
  },
  {
    path: '/session',
    name: 'Session',
    component: Session,
    props: {
      needToLogin: false,
    }
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: Sponsor,
    props: {
      bannerMaxHeight: '150px',
      isNarrow: true,
    }
  },
  {
    path: '/exhibition',
    name: 'Sponsors',
    component: Sponsors
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage,
    props: {
      includeProfile: true,
      profileHelperMessage: '',
      hideTotalScore: true,
      includeCertificates: false,
      includeScore: true,
      includeStamp: true,
      includeQuiz: false,
    }
  },
  {
    path: '/certificate-download',
    name: 'CertificateDownload',
    component: CertificateDownload,
  },
  {
    path: '/live',
    name: 'Live',
    component: Live,
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: ProgramDetails,
  },
  {
    path: '/participant-images',
    name: 'ParticipantImages',
    component: ParticipantImages,
  },
  {
    path: '/promotional-events',
    name: 'PromotionalEvents',
    component: PromotionalEvents,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
