<template>
  <modal
    name='details-modal'
    :adaptive='true'
    :height='height'
    :width='width'
    :max-width='maxWidth'
    :shiftX='shiftXValue'
    :shiftY='shiftYValue'>
    <div class='relative flex flex-col justify-center items-center'>
      <button class='z-30 absolute top-3 right-3' @click="$modal.hide('details-modal')">
        <x-icon class='text-black' />
      </button>
      <component 
        :is='detailsComponent'
        :details-item-id='detailsItemId'
        class='bg-white w-full h-full overflow-y-scroll' style='max-height: calc(100vh - 0.5rem);' />
    </div>
  </modal>
</template>

<script>
import { XIcon } from 'vue-tabler-icons'
import { mapState } from 'vuex'

export default {
  name: 'DetailsModal',
  components: {
    XIcon,
    'program-session-details': () => import('@/components/programs/ProgramSessionDetails.vue'),
    'speaker-details': () => import('@/components/speakers/SpeakerDetails.vue'),
  },
  computed: {
    ...mapState([
      'detailsModal',
    ]),
    dialogFullscreenStatus () {
      return this.isMobile
    },
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    detailsComponent () { 
      return this.detailsModal.detailsComponent ? this.detailsModal.detailsComponent : 'div'
    },
    detailsItemId () {
      return this.detailsModal.detailsItemId ? this.detailsModal.detailsItemId : 0
    },
    maxWidth () {
      return this.detailsModal.maxWidthPixels ? this.detailsModal.maxWidthPixels : 560
    },
    height () {
      return this.detailsModal.height ? this.detailsModal.height : 'auto'
    },
    width () {
      return this.detailsModal.width ? this.detailsModal.width : '100%'
    },
    shiftXValue () {
      return !this.dialogFullscreenStatus && this.detailsModal.shiftX ? this.detailsModal.shiftX : 0.5
    },
    shiftYValue () {
      return !this.dialogFullscreenStatus && this.detailsModal.shiftY ? this.detailsModal.shiftY : 0.5
    },
  },
}
</script>
