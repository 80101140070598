<template>
  <div>
    <default-layout-header v-if='showHeader' />
    <main
      :class='layoutBasedClasses'
      :style='layoutBasedStyles'> 
      <slot></slot>
    </main>
    <component v-if='showFooter' :is='footerComponent' />
    <sidepanel><portal-target name='sidepanel'></portal-target></sidepanel>
    <loading-indicator v-if='showingFullpageLoadingIndicator' 
      :is-full-page='true' />
    <current-time-and-sessions v-if='showCurrentTime' />
    <search-modal />
    <details-modal />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DefaultLayoutHeader    from '@/layouts/DefaultLayoutHeader.vue'
import LoadingIndicator       from '@/components/LoadingIndicator.vue'
import Sidepanel              from '@/components/Sidepanel.vue'
import CurrentTimeAndSessions from '@/components/CurrentTimeAndSessions.vue'
import SearchModal            from '@/components/SearchModal.vue'
import DetailsModal           from '@/components/DetailsModal.vue'

export default {
  name: 'DefaultLayout',
  props: {
    layout: {
      type: String,
      default: 'default-layout',
    },
  },
  components: {
    DefaultLayoutHeader,
    LoadingIndicator,
    Sidepanel,
    CurrentTimeAndSessions,
    SearchModal,
    DetailsModal,
    'default-layout-footer': () => import('@/layouts/footers/DefaultLayoutFooter'),
    'kossoasc-layout-footer': () => import('@/layouts/footers/KossoascLayoutFooter'),
    'gbcc-onsite-layout-footer': () => import('@/layouts/footers/GbccOnsiteLayoutFooter'),
    'gbcc2022-layout-footer': () => import('@/layouts/footers/Gbcc2022LayoutFooter'),
    'kai-spring2022-layout-footer': () => import('@/layouts/footers/KaiSpring2022LayoutFooter'),
    'icorl2022-layout-footer': () => import('@/layouts/footers/Icorl2022LayoutFooter'),
    'koa2022-layout-footer': () => import('@/layouts/footers/Koa2022LayoutFooter'),
    'koa1-layout-footer': () => import('@/layouts/footers/Koa1LayoutFooter'),
    'khrs-virtual-layout-footer': () => import('@/layouts/footers/KhrsVirtualLayoutFooter'),
    'sicem2021-layout-footer': () => import('@/layouts/footers/Sicem2021LayoutFooter'),
    'absolute-layout-footer': () => import('@/layouts/footers/AbsoluteLayoutFooter'),
    'kda2022-layout-footer': () => import('@/layouts/footers/Kda2022LayoutFooter'),
    'koa-layout-footer': () => import('@/layouts/footers/KoaLayoutFooter'),
    'samsungfire-layout-footer': () => import('@/layouts/footers/SamsungfireLayoutFooter'),
    'kiha-layout-footer': () => import('@/layouts/footers/KihaLayoutFooter'),
    'jeil-pharm-layout-footer': () => import('@/layouts/footers/JeilPharmLayoutFooter'),
    'thns2022-layout-footer': () => import('@/layouts/footers/Thns2022LayoutFooter'),
    'kns2022-layout-footer': () => import('@/layouts/footers/Kns2022LayoutFooter'),
    'apsr2022-layout-footer': () => import('@/layouts/footers/Apsr2022LayoutFooter'),
    'kai2022-layout-footer': () => import('@/layouts/footers/Kai2022LayoutFooter'),
    'cmccme-layout-footer': () => import('@/layouts/footers/CmccmeLayoutFooter'),
    'cmccme2023-layout-footer': () => import('@/layouts/footers/Cmccme2023LayoutFooter'),
    'icola2023-layout-footer': () => import('@/layouts/footers/Icola2023LayoutFooter'),
    'gbcc2024-layout-footer': () => import('@/layouts/footers/Gbcc2024LayoutFooter'),
  },
  computed: {
    ...mapState([
      'showingFullpageLoadingIndicator',
      'isFullscreenVideo',
      'detailsModalName',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'eventConfigShowCurrentTimeAndSessions',
      'showingEventLoginPageConfigurations',
    ]),
    isDefaultLayout () {
      return this.layout === 'default-layout'
    },
    layoutBasedClasses () {
      return (this.isDefaultLayout) ? 'layout-container relative' : ''
    },
    layoutBasedStyles () {
      return (this.isDefaultLayout) ? 'min-height: calc(100vh - 20rem);' : ''
    },
    showFooter () {
      return this.isDefaultLayout
    },
    showHeader () {
      return (this.isDefaultLayout && !this.isFullscreenVideo && this.$route.name !== 'PDFRedirect' ) || 
             (this.showHeaderOnLoginPage && this.$route.name === 'Login')
    },
    showHeaderOnLoginPage () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.showHeaderOnLoginPage : false
    },
    showCurrentTime () {
      return this.eventConfigShowCurrentTimeAndSessions && this.layout === 'default-layout'
    },
    footerComponent () {
      if (this.showingEventId === 7) {
        return 'gbcc2022-layout-footer'
      } else if (this.showingEventId === 8) {
        return 'kai-spring2022-layout-footer'
      } else if (this.showingEventId === 10) {
        return 'icorl2022-layout-footer'
      } else if (this.showingEventId === 9) {
        return 'koa2022-layout-footer'
      } else if (this.showingEventId === 13) {
        return 'koa1-layout-footer'
      } else if (this.showingEventId === 11) {
        return 'khrs-virtual-layout-footer'
      } else if (this.showingEventId === 2) {
        return 'sicem2021-layout-footer'
      } else if (this.showingEventId === 14) {
        return 'absolute-layout-footer'
      } else if (this.showingEventId === 15) {
        return 'kda2022-layout-footer'
      } else if (this.showingEventId === 16 || this.showingEventId === 18) {
        return 'koa-layout-footer'
      } else if (this.showingEventId === 17) {
        return 'samsungfire-layout-footer'
      } else if (this.showingEventId === 19) {
        return 'kiha-layout-footer'
      } else if (this.showingEventId === 20 || this.showingEventId === 22 || this.showingEventId === 25 || this.showingEventId === 26 || this.showingEventId === 28 || this.showingEventId === 33 || this.showingEventId === 34 || this.showingEventId === 36 || this.showingEventId === 37 || this.showingEventId === 38 || this.showingEventId === 41 || this.showingEventId === 42) {
        return 'jeil-pharm-layout-footer'
      } else if (this.showingEventId === 21) {
        return 'thns2022-layout-footer'
      } else if (this.showingEventId === 23) {
        return 'kns2022-layout-footer'
      } else if (this.showingEventId === 24) {
        return 'apsr2022-layout-footer'
      } else if (this.showingEventId === 27) {
        return 'kai2022-layout-footer'
      } else if (this.showingEventId === 29) {
        return 'cmccme2023-layout-footer'
      } else if (this.showingEventId === 30) {
        return 'kossoasc-layout-footer'
      } else if (this.showingEventId === 31) {
        return 'gbcc-onsite-layout-footer'
      } else if (this.showingEventId === 32) {
        return 'icola2023-layout-footer'
      } else if (this.showingEventId === 39) {
        return 'gbcc2024-layout-footer'
      } else if (this.showingEventId === 40) {
        return 'cmccme-layout-footer'
      } else {
        return 'default-layout-footer'
      }
    }
  },
}
</script>

