import base from './base'

const getComments = (instanceType, instanceId) => {
  return base.get(`/comments`, { params: {
    instance_type: instanceType,
    instance_id: instanceId,
  }}).then(resp => resp.data)
}

const postComment = (commentParams) => {
  return base.post(`/comments`, commentParams).then(resp => resp.data)
}

const patchComment = (commentId, commentParams) => {
  return base.patch(`/comments/${commentId}`, commentParams).then(resp => resp.data)
}

const deactiveComment = (commentId) => {
  return base.patch(`/comments/${commentId}/deactive`).then(resp => resp.data)
}

export default {
  getComments:     getComments,
  postComment:     postComment,
  patchComment:    patchComment,
  deactiveComment: deactiveComment
}
