<template>
  <div>
    <label class='uppercase text-sm text-gray-500 mb-2'>Title (English)</label>
    <select
      v-model='selectedTitle'
      @change='updateInput'
      class='border py-1 px-2 rounded block w-full lg:w-80'>
      <option disabled></option>
      <option v-for='titleString in titles' :key='`title-${titleString}`' :value='titleString'>
        {{titleString}}
      </option>
      <option value='other'>Other</option>
    </select>
    <input
      v-if='notListedTitle'
      v-model='componentTitleVal'
      class='border py-1 px-2 rounded block w-full lg:w-80 mt-2'
      type='text'
      @input='blockKoreanInput($event)'
      placeholder='Other Title'>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserDataHelpers from '@/utils/user-data-helpers.js'

export default {
  name: 'FormFieldTitle',
  props: [
    'title',
  ],
  data () {
    return {
      selectedTitle: '',
      componentTitleVal: '',
    }
  },
  watch: {
    title: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let index = this.titles.map((item) => item.toLowerCase()).indexOf(newVal.toLowerCase())
          if (index >= 0) {
            this.selectedTitle = this.titles[index]
          } else {
            this.selectedTitle = 'other'
            this.componentTitleVal = newVal
          }
        } else if (newVal === '') { // reset since value is reset
          this.selectedTitle     = ''
          this.componentTitleVal = ''
        }
      },
      immediate: true
    },
    componentTitleVal: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.$emit('update:title', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId',
    ]),
    titles () {
      return UserDataHelpers.selectableValues(this.showingEventId, 'titles')
    },
    notListedTitle () {
      return this.selectedTitle.toLowerCase() === 'other'
    },
  },
  methods: {
    blockKoreanInput (event)  {
      event.target.value = event.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig, '')
    },
    updateInput () {
      if (this.selectedTitle.toLowerCase() !== 'other') {
        this.componentTitleVal = this.selectedTitle
      } else {
        this.componentTitleVal = ''
      }
    },
  },
}
</script>