import Vue        from 'vue'
import Vuex       from 'vuex'
import registrationFeeDiscountCouponsApi  from '@/api/v1/registration-fee-discount-coupons'
import { getField, updateField } from 'vuex-map-fields'
import isEmpty    from 'lodash/isEmpty'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  currentCoupon: {}
})

const getters = {
  hasCurrentCoupon: state => {
    return !isEmpty(state.currentCoupon)
  },
  getField,
}

const actions = {
  getSingleCouponByCode ({ rootGetters, commit }, code) {
    return new Promise((resolve, reject) => {
      registrationFeeDiscountCouponsApi.getSingleCouponByCode(rootGetters['events/showingEventId'], code).then(resp => {
        commit('setCurrentCoupon', camelcaseKeys(resp.coupon, { deep: true }))
        resolve(resp.coupon)
      }).catch(error => {
        reject(error)
      })
    })
  },
  useCouponByCode ({ state, rootGetters, dispatch }) {
    return new Promise((resolve, reject) => {
      registrationFeeDiscountCouponsApi.useCouponByCode(rootGetters['events/showingEventId'], state.currentCoupon.code).then(resp => {
        dispatch('resetCurrentCoupon')
        resolve(resp.coupon)
      }).catch(error => {
        reject(error)
      })
    })
  },
  resetCurrentCoupon ({ commit }) {
    commit('setCurrentCoupon', {})
  },
}

const mutations = {
  setCurrentCoupon (state, coupon) {
    state.currentCoupon = coupon
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
