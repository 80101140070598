import Vue from 'vue'
import Vuex from 'vuex'
import filtersApi from '@/api/v1/filters'
import isEmpty     from 'lodash/isEmpty'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  filters: [],
})

const getters = {
  hasFilters: (state) => {
    return !isEmpty(state.filters)
  },
  showingContentsFilter: (state, getters) => (showingPage) => {
    return getters.hasFilters ? state.filters.find(filter => filter.contentType === showingPage) : {}
  },
  showingContentsFilterByFilterName: (state, getters) => (showingPage, filterName) => {
    return getters.hasFilters && !isEmpty(getters.showingContentsFilter(showingPage)) 
               ? getters.showingContentsFilter(showingPage).keywords.find(filter => filter.name === filterName) 
               : {}
  },
  showingContentsFilterListByFilterName: (state, getters) => (showingPage, filterName) => {
    return getters.hasFilters && !isEmpty(getters.showingContentsFilter(showingPage)) && getters.showingContentsFilterByFilterName(showingPage,filterName) 
           ? getters.showingContentsFilterByFilterName(showingPage,filterName).list
           : []
  },
  speakerContentsFilters: (state, getters) => {
    return getters.hasFilters ? getters.showingContentsFilter('speaker').keywords : []
  },
}

const actions = {
  getFilters ({ commit, rootGetters }) {
    return new Promise(resolve => {
      let params = {
        event_id: rootGetters['events/showingEventId']
      }
      filtersApi.getFilters(params).then(resp => {
        commit('setFilters', camelcaseKeys(resp.filters, {deep: true}))
        resolve()
      })
    })
  },
}

const mutations = {
  setFilters (state, fromApi) {
    state.filters = fromApi
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
