<template>
  <div class='lg:px-2 flex flex-col lg:flex-row lg:items-center justify-between lg:gap-x-8 gap-y-2'>
    <div class='w-full lg:w-1/2 px-2 lg:px-0 font-medium mt-4 lg:my-2 text-normal lg:text-lg text-gray-900'>
      <div v-html='highlightedText(subSession.topic)'></div>
      <button v-if='presentationMaterialUrl' 
        @click='handlePresentaitonMaterialClick'
        :style='themeTextColorStyle'
        class='text-sm font-normal border px-2 py-1 rounded flex flex-row justify-center items-center uppercase w-36'>
        <cloud-download-icon class='h-4'/> 
        Presentation
      </button>
    </div>
    <div class='w-full lg:w-1/2 flex lg:flex-row flex-wrap gap-y-1 lg:gap-x-1 lg:my-1'>
      <div v-for='(speaker, index) in subSession.speakers' 
        :key='`speaker-${index}`'
        class='session-topic-speaker-card bg-gray-50 lg:bg-gray-100 flex flex-row gap-x-4 lg:gap-x-2 p-2'
        :class='speakerDetailsClickable(speaker)'
        @click.stop='seeSpeakerDetails(speaker)'>
        <img 
          :class='hideSpeakeImageIfNotAvailable(speaker)'
          :src='speaker.imageUrl' 
          class='lg:mx-0 object-contain flex-shrink-0'>
        <div class='text-left lg:ml-2 lg:mr-0 my-auto text-gray-700'>
          <div class='text-xs lg:text-sm uppercase'>
            {{speaker.role}}
          </div>
          <div class='text-sm lg:text-base font-medium' v-html='highlightedText(speaker.name)'></div>
          <div class='text-xs whitespace-pre-line'>
            {{speaker.affiliation}}{{speakerCountryString(speaker)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CloudDownloadIcon } from 'vue-tabler-icons'
import StringHelpers from '@/utils/string-helpers'

export default {
  name: 'SessionDetailsByTopic',
  components: {
    CloudDownloadIcon,
  },
  props: {
    subSession: {
      type: Object,
      required: true,
    },
    highlightString: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
    presentationMaterialUrl () {
      let found = this.subSession.speakers.find(speaker => speaker.presentationMaterialUrl !== '')
      return (found) ? found.presentationMaterialUrl : ''
    },
  },
  methods: {
    ...mapActions([
      'openSpeakerDetailsModal',
    ]),
    hideSpeakeImageIfNotAvailable (speaker) {
      return (speaker.imageUrl) ? 'w-24 h-28' : 'hidden'
    },
    highlightedText (text) {
      if (this.highlightString) {
        return StringHelpers.highlightString(text, this.highlightString)
      } else {
        return text
      }
    },
    speakerCountryString (speaker) {
      return speaker.country ? `, ${speaker.country}` : ''
    },
    speakerContentSpeakerConfigurations (speaker) {
      return speaker.contentSpeakerConfigurations ? speaker.contentSpeakerConfigurations : {}
    },
    seeSpeakerDetails (speaker) {
      if (this.speakerContentSpeakerConfigurations(speaker).hideSpeakerDetails) return
      this.openSpeakerDetailsModal(speaker.id)
    },
    speakerDetailsClickable (speaker) {
      return (this.speakerContentSpeakerConfigurations(speaker).hideSpeakerDetails) ? '' : 'hover:shadow-md hover:rounded-xl standard-transition cursor-pointer'
    },
    isAndroidWebview () {
      return window.navigator.userAgent.includes('wv')
    },
    handlePresentaitonMaterialClick() {
      /* eslint-disable */
      try { downloadAbstract.postMessage(this.presentationMaterialUrl) } catch (err) { 
      }
      /* eslint-enable */
      if (!this.isAndroidWebview()) {
        window.open(this.presentationMaterialUrl, '_blank')
      }
    },
  },
}
</script>
<style type='scss' scoped>
  .session-topic-speaker-card {
    width: 100%;
  }
  @media (min-width: 1024px) { 
    .session-topic-speaker-card {
      width: calc(50% - 0.25rem);
    }
  }
</style>