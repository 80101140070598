import base from './base'

const getSponsors = (queryString) => {
  const query = (queryString) ? queryString : ''
  return base.get(`/sponsors${query}`).then(resp => resp.data)
}

const getStampedSponsors = (eventId) => {
  return base.get(`/sponsors/stamped_sponsors`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp.data)
}

const getSponsorsNoAuth = (queryString) => {
  const query = (queryString) ? queryString : ''
  return base.get(`/sponsors/index_no_auth${query}`).then(resp => resp.data)
}

const getSingleSponsor = (sponsorId, eventId) => {
  return base.get(`/sponsors/${sponsorId}?event_id=${eventId}`).then(resp => resp.data)
}

const getSingleSponsorNoAuth = (sponsorId, eventId) => {
  return base.get(`/sponsors/${sponsorId}/show_no_auth?event_id=${eventId}`).then(resp => resp.data)
}

const postBoothVisitor = (sponsorId, action, quizAnswer, eventId) => {
  const boothVisitorParams = {
    event_id: eventId,
    booth_visitor: {
      'action': action,
      'sponsor_id': sponsorId,
      'quiz_answer': quizAnswer
    }
  }
  return base.post('/booth_visitors', boothVisitorParams).then(resp => resp.data)
}

export default {
  getSponsors:            getSponsors,
  getSponsorsNoAuth:      getSponsorsNoAuth,
  getStampedSponsors:     getStampedSponsors,
  getSingleSponsor:       getSingleSponsor,
  getSingleSponsorNoAuth: getSingleSponsorNoAuth,
  postBoothVisitor: postBoothVisitor
}
