import Vue from 'vue'
import Vuex from 'vuex'
import vodsApi from '@/api/v1/vods'
import isEmpty     from 'lodash/isEmpty'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  vods: [],
  selectedVodFilters: []
})


const getters = {
  hasVods (state) {
    return !isEmpty(state.vods)
  },
  hasSelectedFilters: (state) => {
    return !isEmpty(state.selectedVodFilters)
  },
  hasCheckedFilters: (state) => {
    return state.selectedVodFilters.every(filter => filter.list.length === 0)
  },
  selectedFilterNames: (state, getters) => {
    return getters.hasSelectedFilters ? state.selectedVodFilters.map(filter => filter.name) : []
  },
  isMatchedFilters: (state, getters) => (vodConferenceTags) => {
    return getters.selectedFilterNames.some(type => {
      let vodTagValues = vodConferenceTags.find(tag => tag.name === type).list
      let selectedTagValues = state.selectedVodFilters.find(filter => filter.name === type).list
      return vodTagValues.some(tag => selectedTagValues.includes(tag))
    })
  },
  filteredVods: (state, getters) => {
    return state.vods.filter(vod => getters.isMatchedFilters(vod.conferenceTags) || getters.hasCheckedFilters )
  },
  getField
}

const actions = {
  getVods ({ commit, rootGetters }, search) {
    return new Promise(resolve => {
      let queryString = `?event_id=${rootGetters['events/showingEventId']}`
      if (search) {
        queryString = queryString + `&search=${search}`
      }
      vodsApi.getVods(queryString).then(resp => {
        commit('setVods', camelcaseKeys(resp.vod_list, {deep: true}))
        resolve()
      })
    })
  },
}

const mutations = {
  setVods (state, fromApi) {
    state.vods = fromApi
  },
  checkVodFilter (state, {name, list}) {
    let selectedFilter = state.selectedVodFilters.find(filter => filter.name === name)
    selectedFilter.list = list
  },
  setInitialSelectedVodFilters (state, initialSelectedVodFilters) {
    state.selectedVodFilters  = initialSelectedVodFilters.map(filter => {
      return { name: filter.name, list: []}
    })
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
