import Vue               from 'vue'
import VueRouter         from 'vue-router'

const Login                 = () => import('@/views/demo/Login.vue')
const Logout                = () => import('@/views/demo/Logout.vue')
const Home                  = () => import('@/views/demo/Home.vue')
const SingleComponentPage   = () => import('@/views/demo/SingleComponentPage.vue')
const Notices               = () => import('@/views/demo/Notices.vue')
const MyPage                = () => import('@/views/demo/MyPage.vue')
const Programs              = () => import('@/views/demo/Programs.vue') // update to be able to see whole table on mobile
const ProgramDetails        = () => import('@/views/demo/ProgramDetails.vue')
const Session               = () => import('@/views/demo/Session.vue')
const Speakers              = () => import('@/views/demo/Speakers.vue')
const Sponsors              = () => import('@/views/demo/Sponsors.vue')
const Sponsor               = () => import('@/views/demo/Sponsor.vue')
const Posters               = () => import('@/views/demo/Posters.vue')
const Poster                = () => import('@/views/demo/Poster.vue')
const Qna                   = () => import('@/views/demo/Qna.vue')

const SessionVotings        = () => import('@/views/demo/SessionVotings.vue')
const SessionVoting         = () => import('@/views/demo/SessionVoting.vue')
const SessionVotingResults  = () => import('@/views/demo/SessionVotingResults.vue')
const SessionVotingResult   = () => import('@/views/demo/SessionVotingResult.vue')

const Abstracts                = () => import('@/views/demo/Abstracts.vue')
const Abstract                 = () => import('@/views/demo/Abstract.vue')

const HtmlPageTest          = () => import('@/views/demo/HtmlPageTest.vue')


const Main                  = () => import('@/views/gbcc2023/Main.vue') 
const StampEvent            = () => import('@/views/gbcc2023/StampEvent.vue') 
const PublicSession         = () => import('@/views/gbcc2023/PublicSession.vue')
const AbstractBook           = () => import('@/views/gbcc2023/AbstractBook.vue') 

// venue stuff will use single page component
// arrival assistance stuff will use single page component
// tour program stuff will use single page component


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Main'
    }
  },
  {
    path: '/main',
    name: 'Main',
    component: Main // 1. Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login // Unlisted
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices // 2. Notices
  },
  {
    path: '/stamp-event-info',
    name: 'StampEventInfo',
    component: SingleComponentPage, // 3.1 Stamp Event Info
  },
  {
    path: '/stamp-event',
    name: 'StampEvent',
    component: StampEvent, // 3.2 Stamp Event
  },
  {
    path: '/gbcc2023-overview',
    name: 'Gbcc2023Overview',
    component: SingleComponentPage, // 4.1 GBCC Info
  },
  {
    path: '/gbcc2023-greetings',
    name: 'Gbcc2023Greetings',
    component: SingleComponentPage, // 4.2 Greetings
  },
  {
    path: '/gbcc2023-host-info',
    name: 'Gbcc2023HostInfo',
    component: SingleComponentPage, // 4.3 Host Info
  },
  {
    path: '/gbcc2023-secretariat',
    name: 'Gbcc2023Secretariat',
    component: SingleComponentPage, // 4.4 Secretariat
  },
  {
    path: '/gbcc2023-scoring',
    name: 'Gbcc2023Scoring',
    component: SingleComponentPage, // 4.5 평점안내 (Only for Korean)
  },
  {
    path: '/floor-plan',
    name: 'Gbcc2023FloorPlan',
    component: SingleComponentPage, 
  },
  {
    path: '/venue-map',
    name: 'Gbcc2023VenueMap',
    component: SingleComponentPage, 
  },
  {
    path: '/things-to-do-at-walkerhill',
    name: 'Gbcc2023ThingsToDoAtWalkerhill',
    component: SingleComponentPage,
  },
  {
    path: '/exhibition',
    name: 'Exhibition',
    component: SingleComponentPage
  },
  {
    path: '/satellite-symposium',
    name: 'SatelliteSymposium',
    component: SingleComponentPage
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage, // 5. MyPage
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs, // 6. Program at a Glance
    props: {
      defaultToToday: true,
    }
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: ProgramDetails, // 7. Sessions & Sesions Details
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers, // 8. Invited Speakers
  },
  {
    path: '/abstracts',
    name: 'Abstracts',
    component: Abstracts, // 9. Abstracts
  },
  {
    path: '/abstract',
    name: 'Abstract',
    component: Abstract, // 9. Abstract
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: Sponsors // 10.1 Exhibitors
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: Sponsor, // 10.2 Exhibition
    props: {
      loopVideo: true,
      isNarrow: true,
    }
  },
  {
    path: '/posters',
    name: 'Posters',
    component: Posters,
    props: {
      showSponsorsCarousel: true,
    },
  },
  {
    path: '/poster',
    name: 'Poster',
    component: Poster
  },
  {
    path: '/qna',
    name: 'Qna',
    component: Qna
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/session-votings',
    name: 'SessionVotings',
    component: SessionVotings, // 11. Page to start voting
  },
  {
    path: '/session-voting',
    name: 'SessionVoting',
    component: SessionVoting, // 11. Page to vote for a single session
  },
  {
    path: '/session-voting-results',
    name: 'SessionVotingResults',
    component: SessionVotingResults // 11. See results of voting
  },
  {
    path: '/session-voting-result',
    name: 'SessionVotingResult',
    component: SessionVotingResult // 11. See results of voting
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/venue-naver',
    name: 'VenueNaver',
    component: SingleComponentPage, // 12.1 Venue Naver
  },
  {
    path: '/venue-google',
    name: 'VenueGoogle',
    component: SingleComponentPage, // 12.1 Venue Google
  },
  {
    path: '/arrival-info',
    name: 'ArrivalInfo',
    component: SingleComponentPage, // 13 Arrival Info
  },
  {
    path: '/tour-program',
    name: 'TourProgram',
    component: SingleComponentPage, // 14. Tour Program
  },
  {
    path: '/html-page-test',
    name: 'HtmlPageTest',
    component: HtmlPageTest, // test page to develop dynamic html pages
  },
  {
    path: '/public-session',
    name: 'PublicSession',
    component: PublicSession
  },
  {
    path: '/abstract-book',
    name: 'AbstractBook',
    component: AbstractBook
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
