<template>
  <div class='fixed right-2 bottom-2 lg:right-4 lg:bottom-4 text-center text-xs text-gray-800 z-30'
    :class='currentTimeAndSessionsCursorClass'
    @click='goToCurrentLive'>
    <div class='help-control py-4 px-3 rounded-md shadow-md text-center bg-gray-50 hover:bg-gray-100 text-gray-900 uppercase flex flex-col justify-center items-center gap-y-0 border border-gray-100'>
      <div class='text-gray-500 leading-4'>KST Time</div>
      <div class='text-lg leading-4 mt-0.5' >{{ currentTime }}</div>
      <div class='mt-2 text-xs text-gray-700'>{{liveSessionsCount}} Live Sessions</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'CurrentTimeAndSessions',
  data () {
    return {
      currentTimeChecker: null,
      currentTime: '',
    }
  },
  computed: {
    ...mapState('sessions', [
      'liveSessions',
    ]),
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
    liveSessionsCount () {
      return this.liveSessions.filter(liveSession => liveSession.currentSession).length ? this.liveSessions.filter(liveSession => liveSession.currentSession).length : 'no'
    },
    currentTimeAndSessionsCursorClass () {
      return this.liveSessions.filter(liveSession => liveSession.currentSession).length ? 'cursor-pointer' : 'cursor-default'
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getLiveSessions',
    ]),
    goToCurrentLive () {
      if (this.liveSessions.filter(liveSession => liveSession.currentSession).length) {
        this.$router.push({ name: 'Live' })
      }
    },
  },
  mounted () {
    this.currentTime = dayjs().tz('Asia/Seoul').format('HH:mm')
    this.getLiveSessions()
    this.currentTimeChecker = setInterval(() => {
      let updatedTime = new Date()
      this.currentTime = dayjs(updatedTime).tz('Asia/Seoul').format('HH:mm')
      if (parseInt(dayjs(updatedTime).tz('Asia/Seoul').format('mm')) % 10 === 0) {
        this.getLiveSessions()
      }
    }, 30000) // per 30 seconds
  },
  beforeDestroy () {
    clearInterval(this.currentTimeChecker)
  },
}
</script>

<style lang='scss'>
.help-control {
  transition: all 0.2s ease-in-out;
}

</style>
