import base from './base'

const postWatchedVideo = (videoParams) => {
  return base.post(`/watched_videos/`, videoParams, { baseURL: `${process.env.VUE_APP_TRACKING_API}/api/v1` }).then(resp => resp.data)
}

const getLastWatchedTime = (videoParams) => {
  return base.get(`/watched_videos/last_watched_time`, {
    params: videoParams
  }).then(resp => resp.data)
}

const getWatchedTime = (eventId) => {
  return base.get(`/watched_videos/daily_played_time`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp.data)
}

const getMyWatchedVideoSummaries = (eventId) => {
  return base.get(`/watched_video_summaries/mine`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp.data)
}

const getMyWatchedVideoSummaryBySession = (eventId, sessionId) => {
  return base.get(`/watched_video_summaries/mine_by_session`, {
    params: {
      event_id: eventId,
      session_id: sessionId
    }
  }).then(resp => resp.data)
}

export default {
  postWatchedVideo: postWatchedVideo,
  getLastWatchedTime: getLastWatchedTime,
  getWatchedTime: getWatchedTime,
  getMyWatchedVideoSummaries: getMyWatchedVideoSummaries,
  getMyWatchedVideoSummaryBySession: getMyWatchedVideoSummaryBySession,
}
