import Vue        from 'vue'
import Vuex       from 'vuex'
import RegistrationApi from '@/api/v1/users/registrations'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)
// const camelcaseKeys = require('camelcase-keys')
// const snakecaseKeys = require('snakecase-keys')

const state = () => ({
})

const getters = {
  getField,
}

const actions = {
  sendRegistrationEmails ({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.sendRegistrationEmails(rootGetters['events/showingEventId'], params).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  sendRegistrationEmailsByUserEmail ({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      RegistrationApi.sendRegistrationEmailsByUserEmail(rootGetters['events/showingEventId'], params).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
}

const mutations = {
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
