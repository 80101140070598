<template>
  <div>
    <label class='uppercase text-sm text-gray-500 mb-2'>Medical Profession (English)</label>
    <select
      v-model='medicalProfessionSelect'
      class='border py-1 px-2 rounded block w-full lg:w-80'
      @change='updateInput'>
      <option disabled></option>
      <option v-for='profession in medicalProfessionals' 
        :key='`profession-${profession.value}`'
        :value='profession.value'>
        {{profession.fullDescription}}
      </option>
      <option value='other'>Other</option>
    </select>
    <input
      v-if='notListedMedicalProfessional'
      v-model='componentMedicalProfessionVal'
      class='border py-1 px-2 rounded block w-full lg:w-80 mt-2'
      type='text'
      placeholder='Other Profession'
      @input='blockKoreanInput($event)'>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserDataHelpers from '@/utils/user-data-helpers.js'

export default {
  name: 'FormFieldMedicalProfession',
  props: [
    'medicalProfession',
  ],
  data () {
    return {
      medicalProfessionSelect: '',
      componentMedicalProfessionVal: '',
    }
  },
  watch: {
    'medicalProfession': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let index = this.medicalProfessionals.map((item) => item.value.toLowerCase()).indexOf(newVal.toLowerCase())
          if (index >= 0 ) {
            this.medicalProfessionSelect = this.medicalProfessionals[index].value
          } else {
            this.medicalProfessionSelect = 'other'
            this.componentMedicalProfessionVal = newVal
          }
        } else if (newVal === '') {
          this.medicalProfessionSelect = ''
          this.componentMedicalProfessionVal = ''
        }
      },
      immediate: true 
    },
    'componentMedicalProfessionVal': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.$emit('update:medicalProfession', newVal)
        }
      }, immediate: true
    },
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId',
    ]),
    medicalProfessionals () {
      return UserDataHelpers.selectableValues(this.showingEventId, 'medicalProfessionals')
    },
    notListedMedicalProfessional () {
      return this.medicalProfessionSelect.toLowerCase() === 'other'
    },
  },
   methods: {
    blockKoreanInput (event)  {
      event.target.value = event.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig, '')
    },
    updateInput () {
      if (this.medicalProfessionSelect.toLowerCase() !== 'other') {
        this.componentMedicalProfessionVal = this.medicalProfessionSelect
      } else {
        this.componentMedicalProfessionVal = ''
      }
    },
  },
}
</script>
