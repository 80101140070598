import Vue                               from 'vue'
import VueRouter                         from 'vue-router'

const Home                     = () => import('@/views/demo/Home.vue')
const MyPage                   = () => import('@/views/demo/MyPage.vue')
const Live                     = () => import('@/views/demo/Live.vue')
const Login                    = () => import('@/views/demo/Login.vue')

const Programs                 = () => import('@/components/absolute/AbsoluteCustomProgram.vue')

const Session                  = () => import('@/views/absolute/Session.vue')
const Signup                   = () => import('@/views/absolute/Signup.vue')
const SignupConfirmation       = () => import('@/views/absolute/SignupConfirmation.vue')
const Welcome                  = () => import('@/views/absolute/Welcome.vue')
const Main                     = () => import('@/views/absolute/Main.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/main',
    name: 'Main',
    component: Main,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Main' 
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    props: {
      customBackgroundStyle: 'background: linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%);'
    }
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      defaultToToday: true,
    }
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/live',
    name: 'Live',
    component: Live
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/signup-confirmation',
    name: 'SignupConfirmation',
    component: SignupConfirmation
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
