import Vue                               from 'vue'
import VueRouter                         from 'vue-router'

const Home               = () => import('@/views/demo/Home.vue')
const MyPage             = () => import('@/views/demo/MyPage.vue')
const Live               = () => import('@/views/demo/Live.vue')
const Login              = () => import('@/views/demo/Login.vue')
const CurrentSession     = () => import('@/views/demo/CurrentSession.vue')
const Notices            = () => import('@/views/demo/Notices.vue')

const Programs           = () => import('@/components/koa/KoaCustomProgram.vue')

const Session            = () => import('@/views/koa1/Session.vue')
const Signup             = () => import('@/views/koa1/Signup.vue')
const SignupConfirmation = () => import('@/views/koa1/SignupConfirmation.vue')
const Welcome            = () => import('@/views/koa1/Welcome.vue')




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Welcome' 
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    props: {
      customBackgroundStyle: 'background: linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%);'
    }
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      defaultToToday: true,
    }
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/live',
    name: 'Live',
    component: Live
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/signup-confirmation',
    name: 'SignupConfirmation',
    component: SignupConfirmation
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage,
  },
  {
    path: '/current-session',
    name: 'CurrentSession',
    component: CurrentSession,
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
