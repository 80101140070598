import base from './base'

const getSessionQuestions = (apiParams) => {
  return base.get(`/questions`, { params: apiParams }).then(resp => resp.data)
}
const postSessionQuestion = (questionParams) => {
  return base.post(`/questions/`, questionParams).then(resp => resp.data)
}
const postSessionQuestionLike = (questionId) => {
  return base.post(`/questions/${questionId}/like`).then(resp => resp.data)
}

const patchSessionQuestion = (questionId, questionParams) => {
  return base.patch(`/questions/${questionId}`, questionParams).then(resp => resp.data)
}

const patchSessionQuestionStatus = (questionId, questionStatusParams) => {
  return base.patch(`/questions/${questionId}/status_update`, questionStatusParams).then(resp => resp.data)
}

export default {
  getSessionQuestions: getSessionQuestions,
  postSessionQuestion: postSessionQuestion,
  postSessionQuestionLike: postSessionQuestionLike,
  patchSessionQuestion: patchSessionQuestion,
  patchSessionQuestionStatus: patchSessionQuestionStatus
}
