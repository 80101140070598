import Vue                               from 'vue'
import VueRouter                         from 'vue-router'

const Home                             = () => import('@/views/demo/Home.vue')
const Login                            = () => import('@/views/demo/Login.vue')
const MyPage                           = () => import('@/views/demo/MyPage.vue')
const Notices                          = () => import('@/views/demo/Notices.vue')
const Posters                          = () => import('@/views/demo/Posters.vue')
const Poster                           = () => import('@/views/demo/Poster.vue')
const Programs                         = () => import('@/views/demo/Programs.vue')
const ProgramDetails                   = () => import('@/views/demo/ProgramDetails.vue')
const Session                          = () => import('@/views/demo/Session.vue')
const Live                             = () => import('@/views/demo/Live.vue')

const Welcome                          = () => import('@/views/thns2022/Welcome.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Welcome' 
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      programSlotSize: '8rem',
      defaultToToday: true,
      needToBeLoggedIn: false
    }
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: ProgramDetails,
    props: {
      needToBeLoggedIn: false 
    }
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/live',
    name: 'Live',
    component: Live
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage,
  },
  {
    path: '/posters',
    name: 'Posters',
    component: Posters,
    props: {
      showSponsorsCarousel: false,
    },
  },
  {
    path: '/poster',
    name: 'Poster',
    component: Poster
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
