import Vue        from 'vue'
import Vuex       from 'vuex'
import postersApi from '@/api/v1/posters'
import isEmpty    from 'lodash/isEmpty'
import shuffle    from 'lodash/shuffle'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  posters: [],
  selectedPosterFilters: [],
  selectedPoster: {},
  myPosters: [],
  shufflePostersRandomly: false,
})

const getters = {
  hasPosters: (state) => {
    return !isEmpty(state.posters)
  },
  hasSelectedFilters: (state) => {
    return !isEmpty(state.selectedPosterFilters)
  },
  hasSelectedPoster: (state) => {
    return !isEmpty(state.selectedPoster)
  },
  selectedPosterComments: (state, getters) => {
    return getters.hasSelectedPoster && !isEmpty(state.selectedPoster.comments) ? state.selectedPoster.comments.filter(poster => !poster.parentCommentId): []
  },
  selectedPosterCommentReplies: (state, getters) => {
    return getters.hasSelectedPoster && !isEmpty(state.selectedPoster.comments) ? state.selectedPoster.comments.filter(poster => poster.parentCommentId): []
  },
  hasMyPosters: (state) => {
    return !isEmpty(state.myPosters)
  },
  hasMyPostersFile: (state, getters) => {
    return getters.hasMyPosters ? state.myPosters.every((poster) => {
      if (poster.posterType === 'pdf') {
        return !isEmpty(poster.posterFile)
      } else if (poster.posterType === 'video') {
        return poster.vimeoVideoId
      } else {
        return false
      }
    }) : false
  },
  emptyCheckedFilters: (state) => {
    return state.selectedPosterFilters.every(filter => filter.list.length === 0)
  },
  selectedFilterNames: (state, getters) => {
    return getters.hasSelectedFilters ? state.selectedPosterFilters.map(filter => filter.name) : []
  },
  isMatchedFilters: (state, getters) => (posterConferenceTags) => {
    return getters.selectedFilterNames.every(type => {
      let posterTagValues = posterConferenceTags.find(tag => tag.name === type).list
      let selectedTagValues = state.selectedPosterFilters.find(filter => filter.name === type).list
      return posterTagValues.some(tag => selectedTagValues.includes(tag))
    })
  },
  filteredPosters: (state, getters) => {
    return getters.hasPosters ? state.posters.filter(poster => getters.isMatchedFilters(poster.conferenceTags) || getters.emptyCheckedFilters) : []
  },
  getField
}

const actions = {
  getPosters ({ state, commit, rootGetters }, search) {
    return new Promise(resolve => {
      postersApi.getPosters(rootGetters['events/showingEventId'], search).then(resp => {
        let posters = camelcaseKeys(resp.poster_list, {deep: true})
        if (state.shufflePostersRandomly) {
          commit('setPosters', shuffle(posters))
        } else {
          commit('setPosters', posters)
        }
        resolve()
      })
    })
  },
  getSinglePoster ({ commit, rootGetters }, posterId) {
    return new Promise(resolve => {
      postersApi.getSinglePoster(posterId, rootGetters['events/showingEventId']).then(resp => {
        commit('setSelectedPoster', camelcaseKeys(resp.poster, {deep: true}))
      })
      resolve()
    })
  },
  getMyPosters ({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      postersApi.getMyPosters(rootGetters['events/showingEventId']).then(resp => {
        commit('setMyPosters', camelcaseKeys(resp.data.posters, {deep: true}))
        resolve()
      }).catch((error) => {
        // only handling 404 error
        if (error.response.status === 404) {
          reject(error)
        }
      })
    })
  },
  resetSelectedPoster ({ commit }) {
     commit('setSelectedPoster', { })
  },
  updateMyPoster ({ commit }, {posterId, posterParams}) {
    return new Promise(resolve => {
      postersApi.patchMyPoster(posterId, posterParams).then(resp => {
        commit('updateMyPosters', camelcaseKeys(resp.poster, {deep: true}))
      })
      resolve()
    })  
  },
  updatePosterScore ({ state }, score) {
    return new Promise(resolve => {
      postersApi.patchPosterScore(state.selectedPoster.id, score).then(resp => {
        console.log('resp is: ', resp)
        resolve()
      })
    })  
  },
}

const mutations = {
  setShufflePostersRandomly (state, boolean) {
    state.shufflePostersRandomly = boolean
  },
  setPosters (state, fromApi) {
    state.posters = fromApi
  },
  setSelectedPoster (state, fromApi) {
    state.selectedPoster = fromApi
  },
  updateSelectedPosterComment (state, updatedComment) {
    let index = state.selectedPoster.comments.findIndex(comment => parseInt(comment.id) === updatedComment.id)
    if (index !== -1) {
      state.selectedPoster.comments.splice(index, 1, updatedComment)
    } else {
      state.selectedPoster.comments.push(updatedComment)
    }
  },
  removeSelectedPosterComment (state, removedComment) {
    let index = state.selectedPoster.comments.findIndex(comment => parseInt(comment.id) === removedComment.id)
    if (index !== -1) {
      state.selectedPoster.comments.splice(index, 1)
    }
  },
  setMyPosters (state, fromApi) {
    state.myPosters = fromApi
  },
  updateMyPosters (state, updatedPoster) {
    let index = state.myPosters.findIndex(poster => parseInt(poster.id) === updatedPoster.id)
    if (index !== -1) {
      state.myPosters.splice(index, 1, updatedPoster)
    }
  },
  checkPosterFilter (state, {name, list}) {
    let index = state.selectedPosterFilters.findIndex(filter => filter.name === name)
    if (isEmpty(list)) {
      if (index !== -1) {
        state.selectedPosterFilters.splice(index, 1)
      }
    } else {
      if (index !== -1) {
        state.selectedPosterFilters[index].list = list
      } else {
        state.selectedPosterFilters.push({name: name, list: list})
      }
    }
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
