import Vue                               from 'vue'
import VueRouter                         from 'vue-router'

const SingleComponentPage             = () => import('@/views/demo/SingleComponentPage.vue')

const EventLocation                   = () => import('@/views/ksso2022/EventLocation.vue')
const ExhibitionHall                  = () => import('@/views/ksso2022/ExhibitionHall.vue')
const LobbyHall                       = () => import('@/views/ksso2022/LobbyHall.vue')
const Session                         = () => import('@/views/ksso2022/Session.vue')
const Welcome                         = () => import('@/views/ksso2022/Welcome.vue')


const Registration                    = () => import('@/views/kossoasc/Registration.vue')
const Signup                          = () => import('@/views/kossoasc/Signup.vue')
const AbstractSubmissions             = () => import('@/views/kossoasc/AbstractSubmissions.vue')
const AbstractSubmission              = () => import('@/views/kossoasc/AbstractSubmission.vue')
const FindLoginId                     = () => import('@/views/kossoasc/FindLoginId.vue')
const ChangeLoginPassword             = () => import('@/views/kossoasc/ChangeLoginPassword.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/demo/Home.vue'),
    props: {
      redirectPage: 'Main' 
    }
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import('@/views/kossoasc/Main.vue')
  },
  {
    path: '/receipt-download-file',
    name: 'ReceiptDownloadFile',
    component: () => import('@/components/kossoasc/ReceiptDownloadFile.vue'),
  },
  {
    path: '/welcome-message-president',
    name: 'WelcomeMessagePresident',
    component: SingleComponentPage,
  },
  {
    path: '/welcome-message-chairman',
    name: 'WelcomeMessageChairman',
    component: Welcome,
  },
  {
    path: '/abstract-registration-information',
    name: 'AbstractRegistrationInformation',
    component: SingleComponentPage,
  },
  {
    path: '/oral-presentation-guidelines',
    name: 'OralPresentationGuidelines',
    component: SingleComponentPage,
  },
  {
    path: '/poster-presentation-guidelines',
    name: 'PosterPresentationGuidelines',
    component: SingleComponentPage,
  },
  {
    path: '/floor-plan-booths',
    name: 'FloorPlanBooths',
    component: SingleComponentPage,
  },
  {
    path: '/floor-plan-halls',
    name: 'FloorPlanHalls',
    component: SingleComponentPage,
  },
  {
    path: '/preregistration-information',
    name: 'PreregistrationInformation',
    component: SingleComponentPage
  },
  {
    path: '/programs',
    name: 'Programs',
    component: () => import('@/views/demo/Programs.vue'),
    props: {
      defaultToToday: true,
    }
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: SingleComponentPage,
  },
  {
    path: '/location',
    name: 'EventLocation',
    component: EventLocation
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/demo/Login.vue')
  },
  {
    path: '/notices',
    name: 'Notices',
    component: () => import('@/views/demo/Notices.vue')
  },
  {
    path: '/poster-upload',
    name: 'PosterUpload',
    component: () => import('@/views/demo/PosterUpload.vue')
  },
  {
    path: '/poster-upload-completed',
    name: 'PosterUploadCompleted',
    component: () => import('@/views/demo/PosterUploadCompleted.vue')
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: SingleComponentPage,
    props: {
      requireLogin: false,
    }
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: () => import('@/views/demo/Sponsor.vue')
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: () => import('@/views/kossoasc/MyPage.vue')
  },
  {
    path: '/exhibition-hall',
    name: 'ExhibitionHall',
    component: ExhibitionHall
  },
  {
    path: '/posters',
    name: 'Posters',
    component: () => import('@/views/demo/Posters.vue')
  },
  {
    path: '/poster',
    name: 'Poster',
    component: () => import('@/views/demo/Poster.vue')
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import('@/views/demo/Live.vue')
  },
  {
    path: '/lobby-hall',
    name: 'LobbyHall',
    component: LobbyHall,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
  },
  {
    path: '/scoring-credits',
    name: 'ScoringCredits',
    component: SingleComponentPage,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/abstract-submissions',
    name: 'AbstractSubmissions',
    component: AbstractSubmissions
  },
  {
    path: '/abstract-submission',
    name: 'AbstractSubmission',
    component: AbstractSubmission
  },
  {
    path: '/find-login-id',
    name: 'FindLoginId',
    component: FindLoginId
  },
  {
    path: '/change-login-password',
    name: 'ChangeLoginPassword',
    component: ChangeLoginPassword
  },
  {
    path: '/certificate-download',
    name: 'CertificateDownload',
    component: () => import('@/views/kossoasc/CertificateDownload.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
