import Vue               from 'vue'
import VueRouter         from 'vue-router'

const CertificateDownload   = () => import('@/views/demo/CertificateDownload.vue')
const Home                  = () => import('@/views/demo/Home.vue')
const Live                  = () => import('@/views/demo/Live.vue')
const Login                 = () => import('@/views/demo/Login.vue')
const MyPage                = () => import('@/views/demo/MyPage.vue')
const Notices               = () => import('@/views/demo/Notices.vue')
const Poster                = () => import('@/views/demo/Poster.vue')
const Posters               = () => import('@/views/demo/Posters.vue')
const PosterUpload          = () => import('@/views/demo/PosterUpload.vue')
const PosterUploadCompleted = () => import('@/views/demo/PosterUploadCompleted.vue')
const ProgramDetails        = () => import('@/views/demo/ProgramDetails.vue')
const Programs              = () => import('@/views/demo/Programs.vue')
const Sponsor               = () => import('@/views/demo/Sponsor.vue')
const Sponsors              = () => import('@/views/demo/Sponsors.vue')

const Main              = () => import('@/views/gbcc2022/Main.vue')
const PromotionalEvents = () => import('@/views/gbcc2022/PromotionalEvents.vue')
const Session           = () => import('@/views/gbcc2022/Session.vue')
const PublicSession     = () => import('@/views/gbcc2022/PublicSession.vue')
const Welcome           = () => import('@/views/gbcc2022/Welcome.vue')
const KoreanTour        = () => import('@/views/gbcc2022/KoreanTour.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Main'
    }
  },
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/posters',
    name: 'Posters',
    component: Posters,
    props: {
      shufflePosters: true,
    }
  },
  {
    path: '/poster-view',
    name: 'Poster',
    component: Poster
  },
  {
    path: '/poster-upload',
    name: 'PosterUpload',
    component: PosterUpload
  },
  {
    path: '/poster-upload-completed',
    name: 'PosterUploadCompleted',
    component: PosterUploadCompleted
  },
  {
    path: '/promotional-events',
    name: 'PromotionalEvents',
    component: PromotionalEvents
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      defaultToToday: true,
    }
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: Sponsor,
    props: {
      loopVideo: true,
      isNarrow: true,
    }
  },
  {
    path: '/exhibition',
    name: 'Sponsors',
    component: Sponsors
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/korean-tour',
    name: 'KoreanTour',
    component: KoreanTour
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage,
  },
  {
    path: '/certificate-download',
    name: 'CertificateDownload',
    component: CertificateDownload,
  },
  {
    path: '/live',
    name: 'Live',
    component: Live,
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: ProgramDetails,
  },
  {
    path: '/public-session',
    name: 'PublicSession',
    component: PublicSession
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
