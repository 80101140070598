import base from './base'

const getSingleSession = (sessionId, eventId) => {
  return base.get(`/sessions/${sessionId}`, {
    params: {
      event_id: eventId
    }
  }).then(resp => resp.data)
}

const getSingleSessionNoLoginRequired = (sessionId, eventId) => {
  return base.get(`/sessions/${sessionId}/details_no_login`, {
    params: {
      event_id: eventId
    }
  }).then(resp => resp.data)
}

export default {
  getSingleSession:             getSingleSession,
  getSingleSessionNoLoginRequired: getSingleSessionNoLoginRequired,
}
