import base from './base'

const paymentStatusCheck = (orderId) => {
  return base.get(`/payments/status_check`, {
    params: {
      order_id: orderId
    }
  }).then(resp => resp.data)
}


export default {
  paymentStatusCheck: paymentStatusCheck
}
