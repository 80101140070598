import base from './base'

const getSessionVotingQuestions = (eventId) => {
  return base.get(`/session_votings`, {
    params: {
      event_id: eventId,
    }}).then(resp => resp.data)
}

const getSingleSessionVotingQuestions = (eventId, sessionId) => {
  return base.get(`/session_votings/single_session_votings`, {
    params: {
      event_id: eventId,
      session_id: sessionId,
    }}).then(resp => resp.data)
}

const getSessionVotingQuestionData = (eventId, questionId) => {
  return base.get(`/session_votings/${questionId}`, {
    params: {
      event_id: eventId,
    }}).then(resp => resp.data)
}

const postSessionVotingResult = (sessionVotingResultParams) => {
  return base.post(`/session_voting_results`, sessionVotingResultParams).then(resp => resp.data)
}

const patchSessionVotingResult = (sessionVotingResultId, sessionVotingResultParams) => {
  return base.patch(`/session_voting_results/${sessionVotingResultId}`, sessionVotingResultParams).then(resp => resp.data)
}


export default {
  getSessionVotingQuestions:       getSessionVotingQuestions,
  getSessionVotingQuestionData:    getSessionVotingQuestionData,
  getSingleSessionVotingQuestions: getSingleSessionVotingQuestions,
  postSessionVotingResult: postSessionVotingResult,
  patchSessionVotingResult: patchSessionVotingResult,
}
