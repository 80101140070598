<template>
  <component :is='conferenceBasedHeader' />
</template>

<script>
export default {
  name: 'DefaultLayoutHeader',
  components: {
    'demo-layout-header': () => import('@/layouts/DemoLayoutHeader.vue'),
    'demo-layout-public-header': () => import('@/layouts/DemoLayoutPublicHeader.vue'),
  },
  computed: {
    conferenceBasedHeader () {
      if (this.$route.name === 'PublicSession') {
        return 'demo-layout-public-header'
      } else {
        return 'demo-layout-header'
      }
    }
  },
}
</script>
