import base from '../base'

const login = (loginParams) => {
  return base.post(`/users/sso_sessions`, loginParams).then(resp => resp)
}

const logout = () => {
  return base.delete(`/users/sso_sessions/logout`).then(resp => resp)
}

const checkTokenStatus = () => {
  return base.get(`/users/sso_sessions/check_token`).then(resp => resp.data)
}

const checkBlacklist = (loginParams) => {
  return base.post(`/users/sso_sessions/check_blacklist`, loginParams).then(resp => resp)
}

const verifyPhoneNumber = (verifyParams) => {
  return base.post(`/users/sso_sessions/verify_phone_number`, verifyParams).then(resp => resp)
}

const checkDuplicatedPhoneNumber = (checkPhoneNumberParams) => {
  return base.get(`/users/sso_sessions/check_duplicated_phone_number`, { params: checkPhoneNumberParams }).then(resp => resp)
}

const getMyPaidStatus = (getMyPaidStatusParams) => {
  return base.get(`/users/me/paid_status`, { params: getMyPaidStatusParams }).then(resp => resp.data)
}

const getEventLoginId = (getLoginIdParams) => {
  return base.get(`/users/sso_sessions/login_id`, { params: getLoginIdParams }).then(resp => resp.data)
}

const patchEventLoginPassword = (patchLoginPasswordParams) => {
  return base.patch(`/users/sso_sessions/login_password`, patchLoginPasswordParams).then(resp => resp.data)
}

const getKsolaLogin = (ksolaLoginParams) => {
  return base.get(`/users/sso_sessions/ksola_login`, { params: ksolaLoginParams }).then(resp => resp.data)
}


export default {
  login:                      login,
  logout:                     logout,
  checkTokenStatus:           checkTokenStatus,
  checkBlacklist:             checkBlacklist,
  verifyPhoneNumber:          verifyPhoneNumber,
  checkDuplicatedPhoneNumber: checkDuplicatedPhoneNumber,
  getMyPaidStatus:            getMyPaidStatus,
  getEventLoginId:            getEventLoginId,
  patchEventLoginPassword:    patchEventLoginPassword,
  getKsolaLogin:              getKsolaLogin
}
