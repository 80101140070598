import base from './base'

const getAbstracts = (eventId, searchString='') => {
  return base.get(`/abstract_submissions/`, {
    params: {
      event_id: eventId,
      search_string: searchString
    }
  }).then(resp => resp.data)
}

const getSingleAbstract = (abstractId, eventId) => {
  return base.get(`/abstract_submissions/${abstractId}`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp.data)
}

const patchAbstractScore = (abstractId, eventId, abstractScore) => {
  return base.patch(`/abstract_submissions/${abstractId}/update_score?event_id=${eventId}`, {
    'abstract_score': abstractScore,
  }).then(resp => resp.data)
}

const getMyAbstracts = (eventId) => {
  return base.get(`/abstract_submissions/mine`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp.data)
}

const getMyAcceptedAbstracts = (eventId) => {
  return base.get(`/abstract_submissions/mine`, {
    params: {
      event_id: eventId,
      accept_status: 'accepted'
    }
  }).then(resp => resp.data)
}

const getAbstractsOfMySessions = (eventId) => {
  return base.get(`/abstract_submissions/my_sessions`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp.data)
}

const createUpdateAbstract = (params) => {
  return base.post(`/abstract_submissions/`, params).then(resp => resp.data)
}

const patchAbstractFile = (eventId, abstractId, params) => {
  return base.patch(`/abstract_submissions/${abstractId}/patch_abstract_file?event_id=${eventId}`, params).then(resp => resp.data)
}

const patchPosterFile = (eventId, abstractId, params) => {
  return base.patch(`/abstract_submissions/${abstractId}/patch_poster_file?event_id=${eventId}`, params).then(resp => resp.data)
}

const patchOralExtendedAbstractFile = (eventId, abstractId, params) => {
  return base.patch(`/abstract_submissions/${abstractId}/patch_oral_extended_abstract_file?event_id=${eventId}`, params).then(resp => resp.data)
}

const patchOralPresentationFile = (eventId, abstractId, params) => {
  return base.patch(`/abstract_submissions/${abstractId}/patch_oral_presentation_file?event_id=${eventId}`, params).then(resp => resp.data)
}

const postVideoUpload = (abstractId, videoParams) => {
  return base.post(`/abstract_submissions/${abstractId}/upload_video`, videoParams).then(resp => resp.data)
}

const deleteAbstract = (eventId, abstractId) => {
  return base.delete(`/abstract_submissions/${abstractId}?event_id=${eventId}`).then(resp => resp.data)
}




export default {
  createUpdateAbstract:          createUpdateAbstract,
  getAbstracts:                  getAbstracts,
  getSingleAbstract:             getSingleAbstract,
  patchAbstractScore:            patchAbstractScore,
  getMyAbstracts:                getMyAbstracts,
  getMyAcceptedAbstracts:        getMyAcceptedAbstracts,
  patchAbstractFile:             patchAbstractFile,
  patchPosterFile:               patchPosterFile,
  patchOralExtendedAbstractFile: patchOralExtendedAbstractFile,
  patchOralPresentationFile:     patchOralPresentationFile,
  postVideoUpload:               postVideoUpload,
  getAbstractsOfMySessions:      getAbstractsOfMySessions,
  deleteAbstract:                deleteAbstract,
}
