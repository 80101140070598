<template>
  <div :style='setupCssVariables'>
    <default-layout id='app' :layout='layout'>
      <router-view :layout.sync='layout' />
    </default-layout>
    <help-control v-if='showingHelpControl' />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import DefaultLayout          from '@/layouts/DefaultLayout'
import HelpControl            from '@/components/HelpControl'

export default {
  name: `App`,
  components: {
    DefaultLayout,
    HelpControl,
  },
  data () {
    return {
      layout: 'default-layout'
    }
  },
  watch: {
    '$route.query.on_mobile_app': {
      handler: function (newVal) {
        if (newVal) {
          this.layout = 'empty-page'
        } else {
          this.layout = 'default-layout'
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState([
      'isFullscreenVideo',
    ]),
    ...mapGetters('events', [
      'colorsInRange',
      'eventSecondaryThemeColor',
      'eventTertiaryThemeColor',
      'eventHasHelpControl',
      'eventConfigDefaultPageTitle',
      'eventConfigFaviconUrl',
      'eventSearchMetaDescription',
    ]),
    setupCssVariables () {
      let scrollbarWidthHalf = ((window.innerWidth / 2) - (document.body.clientWidth / 2)) + 'px'

      return {
        '--lightestColor': this.colorsInRange[0],
        '--lightColor': this.colorsInRange[1],
        '--inputColor': this.colorsInRange[2],
        '--eventMainColor': this.colorsInRange[2],
        '--darkColor': this.colorsInRange[3],
        '--scrollbarWidthHalf': scrollbarWidthHalf,
        '--secondaryColor': this.eventSecondaryThemeColor, 
        '--tertiaryColor': this.eventTertiaryThemeColor,
      }
    },
    isPaymentPage () {
      const pageName = location.href.split('/').slice(-1)[0] || ''
      return pageName.toLowerCase().includes('payment')
    },
    showingHelpControl () {
      return !(this.isPaymentPage) && 
             !this.isFullscreenVideo &&
             this.eventHasHelpControl
    },
    isGbccOnsitePublicSession () {
      return window.location.pathname.includes('public-session')
    },
  },
  methods: {
    ...mapActions('events', [
      'getEventMeta',
    ]),
    ...mapActions('users', [
      'setupRolesFromLocalStorage',
      'getMyPaidStatus',
      'checkTokenStatus',
    ]),
    specialCaseForDrTunes () {
      const subdomainName = new URL(location.href).host.replace('www.','').split('.')[0]
      const favicon = document.getElementById('favicon')
      if (subdomainName === 'drtunes' || subdomainName === 'drtunes-staging') {
        document.title = 'drtunes'
        favicon.href = ''
      }
    }
  },
  created () {
    this.setupRolesFromLocalStorage()
      this.specialCaseForDrTunes()
    if (!this.isGbccOnsitePublicSession) {
      this.getMyPaidStatus()
      this.checkTokenStatus()
    }
  },
  mounted () {
    if (!this.isPaymentPage) {
      this.getEventMeta().then(() => {
        const favicon = document.getElementById('favicon')
        favicon.href = this.eventConfigFaviconUrl
        document.title = this.eventConfigDefaultPageTitle
        const descEl = document.querySelector('head meta[name="description"]')
        descEl.setAttribute('content', this.eventSearchMetaDescription)
      })
    }
  }
}

</script>

<!-- need to config -->
<style lang='scss'>

#app, 
.el-dialog__body {
  font-family: Pretendard, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  word-break: keep-all;
}

:checked + span {
  @apply text-blue-900 font-medium;
}

.layout-container {
  @apply md:max-w-5xl xl:max-w-7xl md:mx-auto;
}

.full-width-inside-layout-container {
  margin-left:  calc(50% - (50vw - var(--scrollbarWidthHalf))); 
  margin-right: calc(50% - (50vw - var(--scrollbarWidthHalf)));
}

.standard-transition {
  transition: all 0.2s ease-in-out;
}

.row-centered {
  @apply flex flex-row items-center;
}

button,
input,
select {
  transition: all 0.2s ease-in-out;
}

label.style-default {
  @apply block font-semibold uppercase text-gray-600;
}

input.style-default,
textarea.style-default {
  @apply border rounded p-1;
}

select.style-default {
  @apply p-3 border border-gray-200 rounded cursor-pointer;
}

button.style-primary {
  @apply py-4 bg-gray-800 rounded-md text-white;
}

button.style-default {
  @apply py-2 bg-white rounded-md text-gray-800 border border-gray-200;
}
button.style-default:hover {
  @apply shadow-md border-gray-400;
}

button.style-warning {
  @apply border-red-400 rounded-md text-red-600;
}


.highlight-matching-string {
  display: inline-block;
  border-radius: 4px;
  padding: 2px;
  background-color: var(--eventMainColor);
  color: #FFFFFF;
}

.el-message-box {
  @apply w-4/5 lg:w-2/5;
}

</style>
