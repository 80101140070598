<template>
  <div>
    <label class='uppercase text-sm text-gray-500 mb-2'>Department (English)</label>
    <select
      v-model='selectedDepartment'
      @change='updateInput'
      class='border py-1 px-2 rounded block w-full lg:w-80'>
      <option disabled></option>
      <option v-for='department in departments'
        :key='`department-${department.value}`'
        :value='department.value'>
        {{department.fullDescription}}
      </option>
      <option value='other'>Other</option>
    </select>
    <input
      v-if='notListedDepartment'
      v-model='componentDepartmentVal'
      class='border py-1 px-2 rounded block w-full lg:w-80 mt-2'
      type='text'
      @input='blockKoreanInput($event)'
      placeholder='Other Department'>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserDataHelpers from '@/utils/user-data-helpers.js'

export default {
  name: 'FormFieldDepartment',
  props: [
    'department',
  ],
  data () {
    return {
      selectedDepartment: '',
      componentDepartmentVal: '',
    }
  },
  watch: {
    department: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let index = this.departments.map((item) => item.value.toLowerCase()).indexOf(newVal.toLowerCase())
          if (index >= 0) {
            this.selectedDepartment = this.departments[index].value
          } else {
            this.selectedDepartment = 'other'
            this.componentDepartmentVal = newVal
          }
        } else if (newVal === '') {
          this.selectedDepartment = ''
          this.componentDepartmentVal = ''
        }
      },
      immediate: true 
    },
    componentDepartmentVal: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.$emit('update:department', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId',
    ]),
    departments () {
      return UserDataHelpers.selectableValues(this.showingEventId, 'departments')
    },
    notListedDepartment () {
      return this.selectedDepartment.toLowerCase() === 'other'
    },
  },
  methods: {
    blockKoreanInput (event) {
      event.target.value = event.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig, '')
    },
    updateInput () {
      if (this.selectedDepartment.toLowerCase() !== 'other') {
        this.componentDepartmentVal = this.selectedDepartment
      } else {
        this.componentDepartmentVal = ''
      }
    },
  },
}
</script>
