import base from './base'

const getVods = (queryString) => {
  const query = (queryString) ? queryString : ''
  return base.get(`/vods${query}`).then(resp => resp.data)
}

export default {
  getVods: getVods
}
