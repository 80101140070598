import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty   from 'lodash/isEmpty'
import abstractsApi from '@/api/v1/abstracts'


Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')
const emptyAffiliation = {
  affiliationIndex: -1,
  affiliation: '',
  department: '',
  country: ''
}
const emptyAuthor = {
  authorIndex: -1,
  firstName: '',
  lastName: '',
  department: '',
  affiliation: '',
  country: '',
  email: '',
  isPresenter: false,
  isCorrespondant: false,
  affiliations: [],
  phoneNumber: '',
}
const disclosureQuestions = [
  {
    key: 'question-1',
    question: '1. Questions about Presentations on Domestic / Overseas Academic Journals',
    answerType: 'multi-select',
    choices: [
      'The paper submitted has been presented on domestic / overseas academic journals.',
      'The paper submitted has been e-published on domestic / overseas academic journals.',
      'The paper submitted has been accepted by domestic / overseas academic journals.',
      'The paper submitted has been submitted/revised to domestic / overseas academic journals.',
      'None' // none options have special behaviour
    ],
    answers: [],
  },
  {
    key: 'question-2',
    question: '2. The paper submitted',
    answerType: 'multi-select',
    choices: [
      'was presented in a domestic academic symposium before.',
      'was presented in an International academic symposium before.',
      'None' // none options have special behaviour
    ],
    answers: [],
  },
  {
    key: 'question-3',
    question: '3. The study on the paper submitted received research funding from schools or public institutions.',
    answerType: 'radio',
    choices: [
      'Yes',
      'None'
    ],
    collectDetails: [
      {
        key: 'question-3-detail-1',
        onChoice: 'Yes',
        label: 'Institution (or Schools):',
        answerType: 'text',
        answer: '',
      },
      {
        key: 'question-3-detail-2',
        onChoice: 'Yes',
        label: 'Name of Research Fund:',
        answerType: 'text',
        answer: '',
      },
    ]
  },
  {
    key: 'question-4',
    question: '4. The study on the paper submitted received research funding from pharmacists or medical equipment companies.',
    answerType: 'radio',
    choices: [
      'Yes',
      'None'
    ],
    answer: '',
  },
  {
    key: 'question-5',
    question: '5. Some authors of paper submitted received regular consultation and consultancy fee from pharmacists or medical equipment companies.',
    answerType: 'radio',
    choices: [
      'Yes',
      'None'
    ],
    answer: '',
    requiredDetails: true,
    collectDetails: [
      {
        key: 'question-4-detail-1',
        onChoice: 'Yes',
        label: 'Pharmacists name:',
        answerType: 'text',
        answer: '',
      },
      {
        key: 'question-4-detail-2',
        onChoice: 'Yes',
        label: 'Medical equipment company name:',
        answerType: 'text',
        answer: '',
      },
    ]
  },
]

const state = () => ({
  allAbstracts: [],
  selectedAbstract: {},
  selectedAbstractFilters: [],
  abstracts: [],
  myAbstracts: [],
  mySessionsWithAbstracts: [],
  showingMySessionWithAbstracts: {
    locations: [],
    listOfAbstractSubmissions: [],
  },
  newAbstractSubmission: {
    authors: [Object.assign(cloneDeep(emptyAuthor), {key: Math.random()})],
    affiliations: [Object.assign(cloneDeep(emptyAffiliation), {key: Math.random()})],
    disclosures: cloneDeep(disclosureQuestions),
    preferredPresentationType: '',
    language: 'English',
    category: '',
    field: '',
    title: '',
    introduction: '',
    methodsAndMaterials: '',
    results: '',
    conclusions: '',
    casePresentation: '',
    technicalNote: '',
    acknowledgements: 'None',
    keywords: [],
    oralPresentationFileUrl: '',
    oralExtendedAbstractFileUrl: '',
    posterPresentationFileUrl: '',
    vimeoVideoId: '',
    videoUploadId: '',
    subtopic: '',
  },
  editingAbstractSubmission: {
    authors: [Object.assign(cloneDeep(emptyAuthor), {key: Math.random()})],
    affiliations: [Object.assign(cloneDeep(emptyAffiliation), {key: Math.random()})],
    disclosures: cloneDeep(disclosureQuestions),
    preferredPresentationType: '',
    language: '',
    category: '',
    field: '',
    title: '',
    introduction: '',
    methodsAndMaterials: '',
    results: '',
    conclusions: '',
    casePresentation: '',
    technicalNote: '',
    acknowledgements: '',
    keywords: [],
    oralPresentationFileUrl: '',
    oralExtendedAbstractFileUrl: '',
    posterPresentationFileUrl: '',
    vimeoVideoId: '',
    videoUploadId: '',
    subtopic: '',
  },
  wordLimit: 450,
  editingAuthor: cloneDeep(emptyAuthor),
  editingAffiliation: cloneDeep(emptyAffiliation),
})

const getters = {
  hasAbstracts: (state) => {
    return !isEmpty(state.allAbstracts)
  },
  hasSelectedFilters: (state) => {
    return !isEmpty(state.selectedAbstractFilters)
  },
  selectedFilterNames: (state, getters) => {
    return getters.hasSelectedFilters ? state.selectedAbstractFilters.map(filter => filter.name) : []
  },
  emptyCheckedFilters: (state) => {
    return state.selectedAbstractFilters.every(filter => filter.list.length === 0)
  },
  isMatchedFilters: (state, getters) => (abstractConferenceTags) => {
    return getters.selectedFilterNames.every(type => {
      let abstractTagObject = abstractConferenceTags.find(tag => tag.name === type)
      let abstractTagValues = abstractTagObject ? abstractTagObject.list : []
      let selectedTagObject = state.selectedAbstractFilters.find(filter => filter.name === type)
      let selectedTagValues = selectedTagObject ? selectedTagObject.list : []
      return abstractTagValues.some(tag => selectedTagValues.includes(tag))
    })
  },
  filteredAbstracts: (state, getters) => {
    return getters.hasAbstracts ? state.allAbstracts.filter(abstract => getters.isMatchedFilters(abstract.conferenceTags) || getters.emptyCheckedFilters) : []
  },
  getField
}

const actions = {
  getEditingAbstract ({commit, dispatch, rootGetters, state}, abstractId) {
    return new Promise((resolve, reject) => {
      if (state.myAbstracts.length > 0) {
        let found = state.myAbstracts.find(abstract => abstract.id === parseInt(abstractId))
        if (found) {
          commit('setEditingAbstract', found)
        }
        resolve()
      } else {
        dispatch('getMyAbstracts', rootGetters['events/showingEventId']).then(() => {
          let found = state.myAbstracts.find(abstract => abstract.id === parseInt(abstractId))
          if (found) {
            commit('setEditingAbstract', found)
          }
          resolve()
        }).catch((error) => {
          reject(error)
        })
      }
    })
  },
  getAllAbstracts ({commit, rootGetters}, searchString='') {
    return new Promise((resolve, reject) => {
      abstractsApi.getAbstracts(rootGetters['events/showingEventId'], searchString).then((resp) => {
        let abstracts = camelcaseKeys(resp.abstract_submissions, {deep: true})
        commit('updateAllAbstracts', abstracts)
        resolve(abstracts)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getSingleAbstract ({ commit, rootGetters }, abstractId) {
    return new Promise(resolve => {
      abstractsApi.getSingleAbstract(abstractId, rootGetters['events/showingEventId']).then(resp => {
        commit('setSelectedAbstract', camelcaseKeys(resp.abstract_submission, {deep: true}))
      })
      resolve()
    })
  },
  updateAbstractScore ({ state, rootGetters }, score) {
    return new Promise(resolve => {
      abstractsApi.patchAbstractScore(state.selectedAbstract.id, rootGetters['events/showingEventId'], score).then(resp => {
        console.log('resp is: ', resp)
        resolve()
      })
    })  
  },
  resetSelectedAbstract ({commit}) {
    commit('setSelectedAbstract', {})
  },
  getMyAbstracts ({commit}, eventId) {
    return new Promise((resolve, reject) => {
      abstractsApi.getMyAbstracts(eventId).then((resp) => {
        let abstracts = camelcaseKeys(resp.abstract_submissions, {deep: true})
        abstracts.forEach(abstract => {
          // clear disclosures if changed, TODO make this better
          let exist = disclosureQuestions.every(disclosure => {
            let index = abstract.disclosures.findIndex(disclosureResp => disclosureResp.question === disclosure.question)
            return index > -1
          })
          if (!exist) {
            abstract.disclosures = cloneDeep(disclosureQuestions)
          }
        })
        commit('updateMyAbstracts', abstracts)
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getMyAcceptedAbstracts ({commit}, eventId) {
    return new Promise((resolve, reject) => {
      abstractsApi.getMyAcceptedAbstracts(eventId).then((resp) => {
        let abstracts = camelcaseKeys(resp.abstract_submissions, {deep: true})
        abstracts.forEach(abstract => {
          // clear disclosures if changed, TODO make this better
          let exist = disclosureQuestions.every(disclosure => {
            let index = abstract.disclosures.findIndex(disclosureResp => disclosureResp.question === disclosure.question)
            return index > -1
          })
          if (!exist) {
            abstract.disclosures = cloneDeep(disclosureQuestions)
          }
        })
        commit('updateMyAbstracts', abstracts)
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getMySessionsWithAbstracts ({commit}, eventId) {
    return new Promise((resolve, reject) => {
      abstractsApi.getAbstractsOfMySessions(eventId).then((resp) => {
        commit('setMySessionsWithAbstracts', camelcaseKeys(resp.sessions, {deep: true}))
        resolve(camelcaseKeys(resp.sessions, {deep: true}))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  selectShowingMySessionWithAbstracts ({ commit }, session) {
    commit('setShowingMySessionWithAbstracts', session)
  },
  saveMyAbstract ({ commit, state, rootGetters }) {
    return new Promise((resolve, reject) => {
      let params = cloneDeep(state.editingAbstractSubmission)
      params['event_id'] = rootGetters['events/showingEventId']
      abstractsApi.createUpdateAbstract(snakecaseKeys(params)).then(resp => {
        let abstract = camelcaseKeys(resp.abstract_submission, {deep: true})
        commit('setEditingAbstract', abstract)
        commit('updateMyAbstracts', abstract)
        resolve(abstract)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  resetEditingAuthor ({ commit }) {
    commit('setEditingAuthor', cloneDeep(emptyAuthor))
  },
  resetEditingAffiliation ({ commit }) {
    commit('setEditingAffiliation', cloneDeep(emptyAffiliation))
  },
  updateAbstractFile ({ commit, rootGetters }, params) {
    return new Promise(resolve => {
      abstractsApi.patchAbstractFile(rootGetters['events/showingEventId'], params.abstractId, params.formData).then(resp => {
        let updatedAbstractSubmission = camelcaseKeys(resp.abstract_submission, {deep: true})
        commit('updateMyAbstracts', updatedAbstractSubmission)
        resolve(updatedAbstractSubmission)
      })
    })  
  },
  updatePosterFile ({ commit, rootGetters }, params) {
    return new Promise(resolve => {
      abstractsApi.patchPosterFile(rootGetters['events/showingEventId'], params.abstractId, params.formData).then(resp => {
        let updatedAbstractSubmission = camelcaseKeys(resp.abstract_submission, {deep: true})
        commit('updateMyAbstracts', updatedAbstractSubmission)
        resolve(updatedAbstractSubmission)
      })
    })  
  },
  updateOralExtendedAbstractFile ({ commit, rootGetters }, params) {
    return new Promise(resolve => {
      abstractsApi.patchOralExtendedAbstractFile(rootGetters['events/showingEventId'], params.abstractId, params.formData).then(resp => {
        let updatedAbstractSubmission = camelcaseKeys(resp.abstract_submission, {deep: true})
        commit('updateMyAbstracts', updatedAbstractSubmission)
        resolve(updatedAbstractSubmission)
      })
    })
  },
  updateOralPresentationFile  ({ commit, rootGetters }, params) {
    return new Promise(resolve => {
      abstractsApi.patchOralPresentationFile(rootGetters['events/showingEventId'], params.abstractId, params.formData).then(resp => {
        let updatedAbstractSubmission = camelcaseKeys(resp.abstract_submission, {deep: true})
        commit('updateMyAbstracts', updatedAbstractSubmission)
        resolve(updatedAbstractSubmission)
      })
    })
  },
  deleteAbstract({ commit, rootGetters }, abstractId) {
    return new Promise(resolve => {
      abstractsApi.deleteAbstract(rootGetters['events/showingEventId'], abstractId).then(resp => {
        commit('removeFromMyAbstracts', abstractId)
        resolve(resp)
      })
    })
  },
}

const mutations = {
  addNewAuthor (state, newAuthor) {
    newAuthor.authorIndex = state.editingAbstractSubmission.authors.length + 1
    state.editingAbstractSubmission.authors.push(newAuthor)
  },
  updateAuthors (state, newAuthors) {
    newAuthors.forEach((author, index) => author.authorIndex = index + 1)
    state.editingAbstractSubmission.authors = newAuthors
  },
  updateAuthor (state, updatedAuthor) {
    let index = state.editingAbstractSubmission.authors.findIndex(sAuthor => sAuthor.authorIndex === updatedAuthor.authorIndex)
    if (index > -1) {
      state.editingAbstractSubmission.authors.splice(index, 1, updatedAuthor)
    }
  },
  removeAuthor (state, author) {
    let index = state.editingAbstractSubmission.authors.findIndex(sAuthor => sAuthor.authorIndex === author.authorIndex)
    if (index > -1) {
      state.editingAbstractSubmission.authors.splice(index, 1)
      state.editingAbstractSubmission.authors.forEach((author, index) => author.authorIndex = index + 1)
    }
  },

  addNewAffiliation (state, newAffiliation) {
    newAffiliation.affiliationIndex = state.editingAbstractSubmission.affiliations.length + 1
    state.editingAbstractSubmission.affiliations.push(newAffiliation)
  },
  updateAffiliations (state, newAffiliations) {
    newAffiliations.forEach((affiliation, index) => affiliation.affiliationIndex = index + 1)
    state.editingAbstractSubmission.affiliations = newAffiliations
  },
  updateAffiliation (state, updatedAffiliation) {
    let index = state.editingAbstractSubmission.affiliations.findIndex(sAffiliation => sAffiliation.affiliationIndex === updatedAffiliation.affiliationIndex)
    if (index > -1) {
      state.editingAbstractSubmission.affiliations.splice(index, 1, updatedAffiliation)
    }
  },
  removeAffiliation (state, affiliation) {
    let index = state.editingAbstractSubmission.affiliations.findIndex(sAffiliation => sAffiliation.affiliationIndex === affiliation.affiliationIndex)
    if (index > -1) {
      state.editingAbstractSubmission.affiliations.splice(index, 1)
      state.editingAbstractSubmission.affiliations.forEach((affiliation, index) => affiliation.affiliationIndex = index + 1)
    }
  },

  updateAllAbstracts (state, fromApi) {
    state.allAbstracts = fromApi
  },
  updateMyAbstracts (state, fromApi) {
    if (typeof fromApi  === 'object' && fromApi instanceof Array) {
      state.myAbstracts = fromApi
    } else if (typeof fromApi  === 'object') {
      
      let index = state.myAbstracts.findIndex(abstract => abstract.id === fromApi.id)
      if (index < 0) {
        state.myAbstracts.push(fromApi)
      } else {
        state.myAbstracts.splice(index, 1, fromApi)
      }
    }
  },
  setMySessionsWithAbstracts (state, sessions) {
    state.mySessionsWithAbstracts = sessions
  },
  setShowingMySessionWithAbstracts (state, session) {
    state.showingMySessionWithAbstracts = session
  },
  setEditingAuthor (state, author) {
    state.editingAuthor = author
  },
  setEditingAffiliation (state, author) {
    state.editingAffiliation = author
  },
  setEditingAbstract (state, abstract) {
    state.editingAbstractSubmission = abstract
  },
  setSelectedAbstract (state, abstract) {
    state.selectedAbstract = abstract
  },
  setNewAbstract (state) {
    state.editingAbstractSubmission = cloneDeep(state.newAbstractSubmission)
  },
  checkAbstractFilter (state, {name, list}) {
    let index = state.selectedAbstractFilters.findIndex(filter => filter.name === name)
    if (isEmpty(list)) {
      if (index !== -1) {
        state.selectedAbstractFilters.splice(index, 1)
      }
    } else {
      if (index !== -1) {
        state.selectedAbstractFilters[index].list = list
      } else {
        state.selectedAbstractFilters.push({name: name, list: list})
      }
    }
  },
  removeFromMyAbstracts (state, abstractId) {
    let index = state.myAbstracts.findIndex(abstract => abstract.id === abstractId)
    if (index > -1) {
      state.myAbstracts.splice(index, 1)
    }
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
