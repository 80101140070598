import base from './base'

const getPagesForEvent = (eventId) => base
  .get(`/dynamic_configured_pages`, { params: {
    event_id: eventId
  }}).then(resp => resp.data)

const getPageForEvent = (eventId, pageName) => base
  .get(`/dynamic_configured_pages/page_configurations`, { params: {
    event_id: eventId,
    page_name: pageName,
  }}).then(resp => resp.data)


export default {
  getPagesForEvent: getPagesForEvent,
  getPageForEvent:  getPageForEvent,
}
