<template>
  <div>
    <div class='flex flex-row justify-start gap-x-4 items-center mb-6'>
      <h2 class='text-2xl text-gray-900 uppercase font-semibold'>Filters</h2>
      <button v-if='hasSessionCategoryFilters'
        @click='resetCategoryFilters'
        class='block border rounded text-xs py-1 px-2 hover:shadow-md text-gray-900 uppercase'>
        show all
      </button>
    </div>

    <label v-for="category in showingContentsFilterListByFilterName('programs', 'category')"
      :key='`${category}`'
      class='py-1 text-sm text-gray-500 flex flex-row items-center cursor-pointer hover:bg-blue-50'>
      <input 
        type='checkbox' class='mr-2'
        :value='category'
        v-model='sessionCategoryFilters'>
      <span>{{ category }}</span>
    </label>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'SessionFilters',
  computed: {
    ...mapGetters('sessions', [
      'hasSessionCategoryFilters'
    ]),
    ...mapGetters('filters', [
      'showingContentsFilterListByFilterName',
    ]),
    ...mapFields('sessions', [
      'sessionCategoryFilters',
    ]),
  },
  methods: {
    ...mapActions('sessions', [
      'resetCategoryFilters',
    ]),
    ...mapActions('filters', [
      'getFilters',
    ]),
  },
  mounted () {
    this.getFilters()
  }
}
</script>

<style lang='scss' scoped>
.filters {
  @apply overflow-y-auto;
  transition: all 0.15s ease-in-out;
  position: fixed;
  left: 0;
  height: 60vh;
  top: 100vh;
}

 @media (min-width: 1024px) { 
  .filters {
    position: static;
    height: auto;
  }
 }
 </style>
