import base from './base'

const getPosters = (eventId, search) => {
  return base.get(`/posters`, {
    params: {
      event_id: eventId,
      search: search
    }
  }).then(resp => resp.data)
}

const getSinglePoster = (posterId, eventId) => {
  return base.get(`/posters/${posterId}`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp.data)
}

const getMyPosters = (eventId) => {
  return base.get(`/posters/mine`, {
    params: {
      event_id: eventId,
    }
  }).then(resp => resp)
}

const patchMyPoster = (posterId, posterParams) => {
  return base.patch(`/posters/${posterId}`, posterParams).then(resp => resp.data)
}

const patchPosterScore = (posterId, posterScore) => {
  return base.patch(`/posters/${posterId}/update_score`, {
    'poster_score': posterScore,
  }).then(resp => resp.data)
}

export default {
  getPosters:      getPosters,
  getSinglePoster: getSinglePoster,
  getMyPosters:    getMyPosters,
  patchMyPoster:    patchMyPoster,
  patchPosterScore: patchPosterScore,
}
