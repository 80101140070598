import Vue                          from 'vue'
import VueRouter                    from 'vue-router'

const Home                         = () => import('@/views/demo/Home.vue')
const Programs                     = () => import('@/views/demo/Programs.vue')
const Session                      = () => import('@/views/demo/Session.vue')
const Posters                      = () => import('@/views/demo/Posters.vue')
const Poster                       = () => import('@/views/demo/Poster.vue')
const Vods                         = () => import('@/views/demo/Vods.vue')
const Vod                          = () => import('@/views/demo/Vod.vue')
const Sponsors                     = () => import('@/views/demo/Sponsors.vue')
const Sponsor                      = () => import('@/views/demo/Sponsor.vue')
const Speakers                     = () => import('@/views/demo/Speakers.vue')
const Live                         = () => import('@/views/demo/Live.vue')
const Login                        = () => import('@/views/demo/Login.vue')
const MyPage                       = () => import('@/views/demo/MyPage.vue')
const PosterUpload                 = () => import('@/views/demo/PosterUpload.vue')
const PosterUploadCompleted        = () => import('@/views/demo/PosterUploadCompleted.vue')
const Signup                       = () => import('@/views/demo/Signup.vue')
const Notices                      = () => import('@/views/demo/Notices.vue')
const Qna                          = () => import('@/views/demo/Qna.vue')
const ProgramDetails               = () => import('@/views/demo/ProgramDetails.vue')
const SessionVotings               = () => import('@/views/demo/SessionVotings.vue')
const SessionVoting                = () => import('@/views/demo/SessionVoting.vue')
const PhotoGallery                 = () => import('@/views/demo/PhotoGallery.vue')
const MobileAppLoginRedirectPage   = () => import('@/views/demo/MobileAppLoginRedirectPage.vue')

// custom page
const Main                         = () => import('@/views/apsr2022/Main.vue')
const Voting                       = () => import('@/views/apsr2022/Voting.vue')
const Events                       = () => import('@/views/apsr2022/Events.vue')
const FloorPlan                    = () => import('@/views/apsr2022/FloorPlan.vue')
const CovidNotice                  = () => import('@/views/apsr2022/CovidNotice.vue')
// const Abstracts                    = () => import('@/views/apsr2022/Abstracts.vue')
const CommitteeCcc                 = () => import('@/views/apsr2022/CommitteeCcc.vue')
const CommitteeLcc                 = () => import('@/views/apsr2022/CommitteeLcc.vue')
const Welcome                      = () => import('@/views/apsr2022/Welcome.vue')
const Overview                     = () => import('@/views/apsr2022/Overview.vue')
const CongressLogo                 = () => import('@/views/apsr2022/CongressLogo.vue')
const Hotels                       = () => import('@/views/apsr2022/Hotels.vue')
const AboutUs                      = () => import('@/views/apsr2022/AboutUs.vue')
const Tour                         = () => import('@/views/apsr2022/Tour.vue')
const ContactUs                    = () => import('@/views/apsr2022/ContactUs.vue')
const ProgramBook                  = () => import('@/views/apsr2022/ProgramBook.vue')
const ComingSoonPage               = () => import('@/views/apsr2022/ComingSoonPage.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'Main'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/live',
    name: 'Live',
    component: Live
  },
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    props: {
      defaultToToday: true,
      showScheduleFullWidth: true,
    }
  },
  {
    path: '/session',
    name: 'Session',
    component: Session
  },
  {
    path: '/posters',
    name: 'Posters',
    component: Posters
  },
  {
    path: '/poster',
    name: 'Poster',
    component: Poster
  },
  {
    path: '/vods',
    name: 'Vods',
    component: Vods
  },
  {
    path: '/vod',
    name: 'Vod',
    component: Vod
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: Sponsors,
    props: {
      requireLogin: false,
    }
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: Sponsor,
    props: {
      requireLogin: false,
    }
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage,
  },
  {
    path: '/poster-upload',
    name: 'PosterUpload',
    component: PosterUpload
  },
  {
    path: '/poster-upload-completed',
    name: 'PosterUploadCompleted',
    component: PosterUploadCompleted
  },
  {
    path: '/qna',
    name: 'Qna',
    component: Qna
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/coming-soon',
    name: 'ComingSoon',
    component: ComingSoonPage
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices
  },
  {
    path: '/program-details',
    name: 'ProgramDetails',
    component: ProgramDetails
  },
  {
    path: '/mobile-app-login-redirect-page',
    name: 'MobileAppLoginRedirectPage',
    component: MobileAppLoginRedirectPage
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview
  },
  {
    path: '/committee-ccc',
    name: 'CommitteeCcc',
    component: CommitteeCcc
  },
  {
    path: '/committee-lcc',
    name: 'CommitteeLcc',
    component: CommitteeLcc
  },
  {
    path: '/congress-logo',
    name: 'CongressLogo',
    component: CongressLogo
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/hotels',
    name: 'Hotels',
    component: Hotels
  },
  {
    path: '/tour',
    name: 'Tour',
    component: Tour
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/voting',
    name: 'Voting',
    component: Voting
  },
  {
    path: '/abstracts',
    name: 'Abstracts',
    component: ComingSoonPage
  },
  {
    path: '/program-book',
    name: 'ProgramBook',
    component: ProgramBook
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
  },
  {
    path: '/floor-plan',
    name: 'FloorPlan',
    component: FloorPlan
  },
  {
    path: '/covid-notice',
    name: 'CovidNotice',
    component: CovidNotice
  },
  {
    path: '/session-votings',
    name: 'SessionVotings',
    component: SessionVotings
  },
  {
    path: '/session-voting',
    name: 'SessionVoting',
    component: SessionVoting
  },
  {
    path: '/photo-gallery',
    name: 'PhotoGallery',
    component: PhotoGallery
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
