import Vue        from 'vue'
import Vuex       from 'vuex'
import ProductsApi  from '@/api/v1/products'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  products: [],
  registrationProduct: null
})

const getters = {
  getField,
}

const actions = {
  getAllProducts ({ commit }) {
    return new Promise((resolve, reject) => {
      ProductsApi.getAllProducts().then(resp => {
        commit('setProducts', camelcaseKeys(resp.product_list, { deep: true }))
        resolve(resp.product_list)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getProductsIcolaWelcomeReception ({ commit }) {
    return new Promise((resolve, reject) => {
      ProductsApi.getProductsIcolaWelcomeReception().then(resp => {
        commit('setProducts', camelcaseKeys(resp.product_list, { deep: true }))
        resolve(resp.product_list)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

const mutations = {
  setProducts (state, products) {
    state.products = products
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
