<template>
  <div>
    <div
      @click='toggleShowHelpOptions'
      class='fixed right-2 lg:right-4 text-center text-xs text-gray-800 z-30 cursor-pointer'
      :class='helpControlOptions'>
      <div
        class='help-control py-2 px-3 rounded-full shadow-md text-center bg-gray-50 hover:bg-gray-100 text-gray-900 uppercase flex flex-col justify-center items-center gap-y-0 border border-gray-100'>
        <help-icon size='18'/>
        help
      </div>
    </div>
    <button
      v-if='eventKakaoSupportUrl'
      @click='kakaoChannelChat()'
      class='help-control fixed inline-block py-1 px-2 rounded-full shadow-xl text-center bg-yellow-200 hover:bg-yellow-300 text-yellow-900 flex flex-col justify-center items-center'
      :class='kakaoHelpStyle'>
      <img src='https://doctorknow.co.kr/img/kakaotalk.ce857aeb.svg' class='w-8'>
    </button>
    <button
      v-if='eventChanneltalkPluginKey'
      class='help-control fixed inline-block py-1 px-2 rounded-full shadow-xl text-center bg-blue-50 hover:bg-blue-100 flex flex-col justify-center items-center open-channeltalk-button'
      :class='channelTalkHelpStyle'>
      <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/channeltalk_icon.png' class='w-8'>
    </button>
    <a v-if='eventConfigHelpEmailLink' 
      :href='mailToLink'
      class='help-control fixed inline-block py-2 px-3 rounded-full shadow-xl text-center bg-gray-200 hover:bg-gray-300 text-gray-900 flex flex-col justify-center items-center'
      :class='mailHelpStyle'>
      <mail-icon size='22'/>
    </a>
  </div>
</template>

<script>
import { HelpIcon, MailIcon } from 'vue-tabler-icons'
import { mapGetters }         from 'vuex'
import ChanneltalkHelpers from '@/utils/channeltalk-helpers'

export default {
  name: 'HelpControl',
  components: {
    MailIcon,
    HelpIcon,
  },
  data () {
    return {
      showHelpOptions: false,
    }
  },
  watch: {
    'eventChanneltalkPluginKey': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          ChanneltalkHelpers.boot({
            'pluginKey': newVal,
            'customLauncherSelector': '.open-channeltalk-button',
            'hideChannelButtonOnBoot': true
          })
        }
      },
      immediate: true 
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventKakaoSupportUrl',
      'eventConfigHelpEmailLink',
      'eventChanneltalkPluginKey',
      'eventConfigShowCurrentTimeAndSessions',
    ]),
    kakaoHelpStyle () {
      let opacityClasses = (this.showHelpOptions)? 'opacity-100' : 'opacity-0'
      let locationClassses = (this.showHelpOptions) ? 'right-4 bottom-20' : 'right-4 bottom-4'
      if (this.eventConfigShowCurrentTimeAndSessions) {
        locationClassses = (this.showHelpOptions) ? 'right-4 bottom-48' : 'right-4 bottom-32'
      }
      return `${opacityClasses} ${locationClassses}`
    },
    mailHelpStyle () {
      let opacityClasses = (this.showHelpOptions)? 'opacity-100' : 'opacity-0'
      let locationClassses = (this.showHelpOptions) ? 'right-16 bottom-16' : 'right-4 bottom-4'
      if (this.eventConfigShowCurrentTimeAndSessions) {
        locationClassses = (this.showHelpOptions) ? 'right-16 bottom-40' : 'right-4 bottom-32'
      }
      return `${opacityClasses} ${locationClassses}`
    },
    channelTalkHelpStyle () {
      let opacityClasses = (this.showHelpOptions)? 'opacity-100' : 'opacity-0'
      let locationClassses = (this.showHelpOptions) ? 'right-20 bottom-4' : 'right-4 bottom-4'
      if (this.eventConfigShowCurrentTimeAndSessions) {
        locationClassses = (this.showHelpOptions) ? 'right-20 bottom-32' : 'right-4 bottom-32'
      }
      return `${opacityClasses} ${locationClassses}`
    },
    helpControlOptions () {
      let opacityClasses = (this.showHelpOptions)? 'opacity-50' : 'opacity-100'
      let locationClassses = (this.eventConfigShowCurrentTimeAndSessions)? 'bottom-32 lg:bottom-32' : 'bottom-2 lg:bottom-4'
      return `${opacityClasses} ${locationClassses}`
    },
    mailToLink () {
      return (this.eventConfigHelpEmailLink)
        ? `mailto:${this.eventConfigHelpEmailLink}`
        : ''
    },
  },
  methods: {
    kakaoChannelChat () {
      window.open(this.eventKakaoSupportUrl, '_blank', 'width=350,height=550')
    },
    toggleShowHelpOptions () {
      this.showHelpOptions = !this.showHelpOptions
    },
  },
}
</script>

<style lang='scss'>
.help-control {
  transition: all 0.2s ease-in-out;
}

</style>
