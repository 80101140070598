class StringHelpers {

  static highlightString (source, match, tagWrapper = 'span', tagClass = 'highlight-matching-string') {
    var regEx = new RegExp(match, "ig")
    return source.replace(regEx, `<${tagWrapper} class='${tagClass}'>${match}</${tagWrapper}>`)
  }

}

export default StringHelpers
