import Vue                          from 'vue'
import VueRouter                    from 'vue-router'

const Home           = () => import('@/views/demo/Home.vue')
const SignupOrLogin  = () => import('@/views/demo/SignupOrLogin.vue')
const Logout         = () => import('@/views/demo/Logout.vue')
const Session         = () => import('@/views/jeilpharm/Session.vue')
const CurrentSession  = () => import('@/views/demo/CurrentSession.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      redirectPage: 'CurrentSession'
    }
  },
  {
    path: '/current-session',
    name: 'CurrentSession',
    component: CurrentSession,
    props: {
      isSinglePageEvent: true,
    }
  },
  {
    path: '/session',
    name: 'Session',
    component: Session,
  },
  {
    path: '/login',
    name: 'Login',
    component: SignupOrLogin,
    props: {
      showToggleFormType: true,
      initialFormType: 'login',
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})


export default router
